import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import KuvikProLayout from '../layouts/KuvikProLayout/KuvikProLayout'
import ArtworksView from '../views/Pro/ArtworksView/ArtworksView'
import HomeView from '../views/Pro/HomeView/HomeView'

const DashboardRouter = props => {
    return (
        <Switch>
            <Route exact path='/' component={HomeView} />
            <Route path='/artworks' component={ArtworksView} />
            {/*<Route path='/confirmation' component={HomeView} />*/}
            <Redirect to='/' />
        </Switch>
    )
}

export default DashboardRouter

