import exhibition1 from '../../../assets/galleries/exhibition/exhibition1.png'
import exhibition2 from '../../../assets/galleries/exhibition/exhibition2.png'
import exhibition3 from '../../../assets/galleries/exhibition/exhibition3.png'

import noImage from '../../../assets/artist/noImage.png'
import arrowIcon from '../../../assets/icons/arrow_down.svg'

import shareIcon from '../../../assets/icons/share.svg'
import likeIcon from '../../../assets/icons/heart.svg'
import fullHeart from '../../../assets/icons/full_heart.svg'

import magazineBanner from '../../../assets/banners/magazine_banner.png'
import featuredIcon from '../../../assets/icons/featured.svg'


export const translations = {
    coming_soon: {
        top_title: 'Coming soon',
        title: 'February 15th, 2022',
        caption: 'We commit to something bigger than ourselves'
    },
    main_info_section: {
        title: 'Magazines',
        caption: 'PUSBLISHING REVOLUTIONARY ART'
    },
    new_releases_section: {
        title: 'NEW RELEASES',
        noImage: noImage,
        noContent: 'Sorry! There are not artists yet.',
        open: 'Open',
        featuredIcon: featuredIcon,
        buttons: {
            share: {
                label: 'Share',
                icon: shareIcon,
            },
            save: {
                label: 'Save',
                icon: likeIcon,
                alt_icon: fullHeart
            },
        }
    },
    individuals_section: {
        title: 'INDIVIDUALS',
        open: 'Open',
        noImage: noImage,
        featuredIcon: featuredIcon,
        noContent: 'Sorry! There is no content here yet.',
    },
    banner_section: {
        title: 'Interested in a magazine?',
        description: 'Join our Art community and become a Kuvik member to show your art in a global scale.',
        image: magazineBanner,
        buttons: {
            getin_touch: 'Get in touch',
            know_more: 'Know more',
        }
    },
    previous_editions_section: {
        title: 'PREVIOUS EDITIONS',
        featured: 'Featured',
        date: 'Select a date',
        open: 'Open',
        noImage: noImage,
        noContent: 'There are not coincidences with the filters selected.',
    },
}