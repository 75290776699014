import React from "react"
import { Button, Divider, Grid, Icon, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from "@material-ui/styles"
import { grey } from "@material-ui/core/colors"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"

const styles = makeStyles(theme => ({
    root: {
        margin: '100px 0px',
        //paddingLeft: 100,
        //paddingRight: 100,
        [theme.breakpoints.down('sm')]: {
            //padding: 32
        },
        boxSizing: 'border-box',
        position: 'relative',
        //width: '100%',
        //height: '100%',
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em',
    },
    contentTitle: {
        fontSize: 32,
        fontWeight: 600,
        '& span': {
            color: theme.palette.secondary.main,
            textTransform: 'uppercase'
        }
    },
    divider: {
        backgroundColor: grey[600]
    },
    description: {
        color: '#A5A5A5',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 2,
        '& span': {
            fontWeight: 600,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main
        }
    },
    data: {
        minHeight: '60vh',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'inherit'
        }
    },
    imageContainer: {
        position: 'relative',
        top: 0,
        width: '100%',
        height: '100%',
        maxHeight: 600,
        boxSizing: 'border-box',
        //paddingTop: 150,
        //paddingLeft: 32,
        right: 0,
        [theme.breakpoints.down('sm')]: {
            //position: 'inherit',
            //transform: 'none',
            //width: '100%',
            //padding: 0,
            //height: 500
        },
    },
    image: {
        position: 'absolute',
        top: 0, left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 12
    },
    tech: {
        //width: 120,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 16
    },
    icon: {
        width: 64,
        height: 64,
        margin: 'auto',
        backgroundColor: 'rgba(21, 21, 21, 0.94)',
        borderRadius: '100%',
        position: 'relative'
    },
    techLabel: {
        paddingTop: 15,
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.secondary.main,
        width: 120
    },
    icon_container: {
        maxWidth: 500,
        width: '100%',
        margin: 'auto',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }
}))

const icon_styles = { margin: 0, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }

const WhyUs = ({ history, content }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title}>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.data}>
                            <Grid container justifyContent='center' spacing={4}>
                                <Grid item xs={12}>
                                    <Typography className={classes.contentTitle}>
                                        <span>{content.art}</span> {content.titleS}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align='justify' className={classes.description}>
                                        {content.description[0][0]} <span>{content.art}</span> {content.description[0][1]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {content.description[1].map(text => (
                                        <Typography className={classes.description}>
                                            {text}
                                        </Typography>
                                    ))}
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.icon_container}>
                                        <Grid container justifyContent='center' spacing={1}>
                                            {content.tech.map(iconData => (
                                                <Grid item xs={6}>
                                                    <div className={classes.tech}>
                                                        <div className={classes.icon}>
                                                            <SVGIcon src={iconData.icon} color={iconData.color} style={icon_styles} />
                                                        </div>
                                                        <Typography className={classes.techLabel} align='center'>
                                                            {iconData.label}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.imageContainer}>
                            <img src={content.image} className={classes.image} />
                        </div>
                    </Grid>
                </Grid>
            </MainContainer>

        </div >
    )
}

export default WhyUs