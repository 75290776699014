import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import KuvikProLayout from '../layouts/KuvikProLayout/KuvikProLayout';
import WebsiteLayout from '../layouts/WebsiteLayout/WebsiteLayout';
import { onGetProModeFromMemory } from '../shared/utility';
import { actionTypes } from '../store/actions';

const MainRouter = ({ history, is_auth, pro_mode, onUpdateProMode }) => {

    useEffect(() => {
        let memory = onGetProModeFromMemory()
        onUpdateProMode(memory)
    }, [])

    return (
        <Router history={history}>
            <Switch>
                <Route path={'/confirmation'} component={WebsiteLayout} />
                {is_auth && pro_mode ?
                    <Route path={'/'} component={KuvikProLayout} />
                    :
                    <Route path={'/'} component={WebsiteLayout} />
                }
                <Redirect to='/' />
            </Switch>
        </Router>
    )
}

const mapStateToProps = state => {
    return {
        is_auth: state.is_auth,
        pro_mode: state.pro_mode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateProMode: (pro_mode) => dispatch({ type: actionTypes.SYS_PRO_MODE, pro_mode }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainRouter)