import { useState, useEffect } from "react"
import { isFormValid, onGetErrorMessage, onGetFileExtension, onGetFormData, onInitFiles, onInitForm, onSetErrorsToForm, onSetTokenSession, updateAuthorizationHeader } from "../../shared/utility";
import { CONFIG_SETTINGS, private_server, public_server } from "../../config";
import { generic_errors } from "../../texts/eng/genericErrors";
import { catalogs } from "../../texts/eng/catalogs";
import { useMediaQuery, useTheme } from "@material-ui/core";
import countryCodes from 'country-codes-list'

const useArtistSignUp = ({ open, language, content, onUpdateDrawerStatus,
    onUpdateModalStatus, onUpdateAuthStatus, onUpdateUserData }) => {

    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [files, setFiles] = useState(JSON.parse(JSON.stringify(filesData)))
    const [gallery, setGallery] = useState([])
    const [selected_document, setSelectedDocument] = useState(null)
    const [step, setStep] = useState(0)
    const [area, setArea] = useState(null)

    const [document_modal, setDocumentModal] = useState(false)

    useEffect(() => {
        if (!open)
            actions.onInitModule()
    }, [open])

    const actions = {
        onInitModule: () => {

            setStep(0)
            setArea(null)

            let _form = JSON.parse(JSON.stringify(formData))
            let _files = JSON.parse(JSON.stringify(filesData))

            let temp = { ...onInitForm(_form, null, content.form.form.optional) }
            temp = { ...onInitForm(_form, null, content.form.form.fixed) }
            temp = { ...onInitForm(_form, null, { reference_code: { ...content.form.reference_code.form } }) }

            setForm(temp)
            setFiles(_files)

            setLoading(false)
            setSending(false)
            setError(null)
        },
        onCloseError: () => {
            setError(null)
        },
        onClose: async () => onUpdateDrawerStatus(false),
        onOpenDocumentModal: document => {
            setSelectedDocument(document)
            setDocumentModal(true)
        },
        onUpdateStep: (newStep) => {
            setStep(newStep)
        },
        onUpdateArea: (area) => {
            setArea(area)
            let _form = { ...form }
            _form.url.isRequired = area !== 6
            setForm(_form)
            if (ismobile)
                actions.onSelectArea(area)
        },
        onUpdateGallery: (images) => {
            const _prevGallery = [...gallery, ...images]
            let _gallery = _prevGallery
            if (_prevGallery.length >= 10)
                _gallery = _prevGallery.slice(0, 10)
            setGallery(_gallery)
        },
        onDeleteImage: (image) => {
            let _gallery = [...gallery]
            const index = _gallery.indexOf(image);

            if (index > -1) {
                _gallery.splice(index, 1);
            }

            setGallery(_gallery)
        },
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            if (id === 'confirm_password') {
                temp[id].isValid = form.password.value === data.value
                temp[id].value2 = form.password.value
            }
            if (id === 'password' && form.confirm_password.value)
                temp.confirm_password.isValid = form.confirm_password.value === data.value

            setForm(temp)
        },
        onSelectArea: (id) => {
            form.area_id.value = id
            form.area_id.isVisited = true
            form.area_id.isValid = true
            if (id === 4) {
                form.first_name.config = { ...form.first_name.config, label: 'name', placeholder: 'name' }
            }
            setStep(1)
        },
        onCloseDocumentModal: () => {
            setSelectedDocument(null)
            setDocumentModal(false)
        },
        onUpdateDocument: async (file) => {
            try {
                if (!file) {
                    setError('A document must be attached')
                    return
                }

                setSending(true)
                const file2send = { ext: onGetFileExtension(file.name) }
                //await request_upload_document(selected_document, file, file2send)
                setDocumentModal(false)
                setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onSubmit: async () => {

            if (area === 4)
                delete form.last_name

            const errors = isFormValid(form)

            // Error handler
            if (errors && errors.length) {
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }



            // Clean data
            const data2send = onGetFormData(form)
            data2send.device = "web"

            try {
                setSending(true)
                const country = catalogs.countries.find(country => country.id === data2send.country_id)
                data2send.user_type_id = area
                data2send.gender_id = 3
                data2send.country_code = country.mobile_code
                await public_server.post(`/user/register`, data2send)
                /*console.log(response)
                const token_data = response.data.data
                await onSetTokenSession(token_data)
                updateAuthorizationHeader(private_server)
                const user_request = await private_server.get('/user/me')
                onUpdateUserData(user_request.data.data.user)
                onUpdateAuthStatus(true)*/
                setError(null)
                setSending(false)
                setStep(prevVal => prevVal + 1)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        }
    }

    return {
        loading, sending, error, document_modal, form, files, selected_document,
        step, gallery, area, actions
    }
}

export default useArtistSignUp

const filesData = {
    cv: { key: 'cv', name: '', path: 'cv', status: false },
    portfolio: { key: 'portfolio', name: '', path: 'portfolio', status: false },
    other: { key: 'other', name: '', path: 'other', status: false },
}

const formData = {
    area_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'area_id',
            type: 'number',
            fullWidth: true,
            placeholder: 'Area'
        },
        rules: {
            type: 'number',
        }
    },
    first_name: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    last_name: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    country_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [...catalogs.countries],
        config: {
            id: 'country_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    email: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
        },
        rules: { type: 'email' }
    },
    password: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    confirm_password: {
        value: null,
        value2: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'confirm_password',
            type: 'password',
            fullWidth: true,
        },
        rules: {
            type: 'equals',
            min: 1,
            max: 200
        }
    },
    country_code: {
        value: '+49',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options: [...catalogs.country_codes],
        config: {
            id: 'country_code',
            type: 'select',
            fullWidth: true,
        },
        rules: { type: 'select' }
    },
    mobile: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'phone_number'
        }
    },
    url: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    reference_code: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'reference_code',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
}