import React, { useState, useEffect } from 'react';
import { onGetErrorMessage } from '../../../shared/utility'
import queryString from 'query-string'
import { public_server } from '../../../config'

const useArtistView = ({ user, history }) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [artists, setArtists] = useState([])
    const [new_artists, setNewArtists] = useState([])

    useEffect(() => {
        actions.initModule()
    }, [])

    const actions = {
        initModule: async () => {
            window.scrollTo(0, 0)
            try {
                setLoading(true)

                let params2send = { offset: 0, order: 1, order_by: 'order' }

                let filterArgs = {
                    AND: [
                        { field: "new", operator: "=", value: 1 },
                        { field: "enabled", operator: "=", value: 1 }
                    ]
                }

                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                let params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
                const _new_artists = await public_server.get(`/artist/all/ns${params}`)
                //const _artists = await request_artist_info(params)
                const _clean_new_artist = []
                _new_artists.data.data.artists.forEach(element => {
                    if (element.active === 1) _clean_new_artist.push(element)
                });
                setNewArtists([..._clean_new_artist])

                //ARTISTS
                filterArgs = { AND: [{ field: "enabled", operator: "=", value: 1 }] }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
                //params = `?${queryString.stringify(params2send)}`
                const _artists = await public_server.get(`/artist/all/ns${params}`)

                const _clean_artist = []
                _artists.data.data.artists.forEach(element => {
                    if (element.active === 1) _clean_artist.push(element)
                });

                setArtists([..._clean_artist])
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseError: () => setError(null),
        onArtistSelected: (url) => history.push(`/artists/${url}`),
        onGetArtists: () => { }
    }

    const system = { loading, error }
    const view_data = { artists, new_artists }

    return { system, view_data, actions }
}

export default useArtistView