import exhibition1 from '../../../assets/galleries/exhibition/exhibition1.png'
import exhibition2 from '../../../assets/galleries/exhibition/exhibition2.png'
import exhibition3 from '../../../assets/galleries/exhibition/exhibition3.png'

import noImage from '../../../assets/artist/noImage.png'
import arrowIcon from '../../../assets/icons/arrow_down.svg'
import featuredIcon from '../../../assets/icons/featured.svg'

export const translations = {
    main_info_section: {
        title: 'Artists',
        caption: 'MEET OUR TALENTED COMMUNITY'
    },
    new_artists_section: {
        title: 'NEW ARTISTS',
        noImage: noImage,
        noContent: 'Sorry! There are not artists yet.',
    },
    artists_section: {
        title: 'Artists',
        noImage: noImage,
        featuredIcon:featuredIcon,
        moreIcon: arrowIcon,
        see:{
            more:'See more',
            less:'See less'
        },
        filters: [
            { id: 1, value: 1, label: 'All' }
        ],
        gallery: [
            {
                artist: 'Sophie Dómine',
                title: 'Sombra y  Soledad',
                image: exhibition1,
                startDate: {//'2021-07-02 00:00:00'
                    day: '03',
                    month: 'Jan'
                },
                endDate: { //'2021-08-04 00:00:00'
                    day: '04',
                    month: 'Mar'
                },
            },
            {
                artist: 'Josue Navarro',
                title: 'Vacío',
                image: exhibition2,
                startDate: {//'2021-07-02 00:00:00'
                    day: '05',
                    month: 'Apr'
                },
                endDate: { //'2021-08-04 00:00:00'
                    day: '04',
                    month: 'Jun'
                },
            },
            {
                artist: 'Alan Navarro',
                title: 'Vacío',
                image: exhibition3,
                startDate: {//'2021-07-02 00:00:00'
                    day: '06',
                    month: 'May'
                },
                endDate: { //'2021-08-04 00:00:00'
                    day: '08',
                    month: 'Jun'
                },
            }
        ]
    },
}