import React from 'react'
import { FormControl, InputLabel, makeStyles, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors';
import { ruleValidation } from './customFunctions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment'

const CssTextField = withStyles(theme => ({
  root: {
    color: 'white',
    '& .MuiFormLabel-root': {
      color: '#6f6c6c',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '0px',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0px',
      //borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      fontWeight: 500,
      borderRadius: 12,
      '& fieldset': {
        color: 'white',
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
}), { withTheme: true })(TextField);

const CSSKeyboardDatePicker = withStyles(theme => ({
  root: {
    marginTop: '6px !important',
    '& input': {
      color: 'white',
      fontWeight: 500,
    },
    '& fieldset': {
      borderRadius: 12,
      borderColor: 'white',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
    '& .MuiOutlinedInput-root:focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '& .MuiFormControl-root': {
      marginTop: 0
    },
  }
}))(DatePicker);

const select_styles = makeStyles(theme => ({
  root: {
    color: 'white',
    '& input': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 12,
      },
    },
  },
  formC: {
    width: '100%',
    color: 'white',
    border: 'solid 1px white',
    borderRadius: 12,
    '&:hover': {
      borderColor: theme.palette.secondary.main
    },
    '&:focused': {
      borderColor: theme.palette.secondary.main,
    },
  },
  select: {
    color: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      color: 'white',
      border: 0,
    },
  },
  icon: {
    color: 'white',
  }
}))

const InputForm = props => {

  const classes = select_styles()
  const { data, variant, color, type, onChange, onInputChange, disabled, startAdornment, endAdornment, onKeyUp, onKeyDown } = props

  ///////////////////  STATE //////////////////////
  const { config, options, value, rules, isValid, isVisited } = data

  const isError = isVisited && !isValid
  let interConfig = { ...config }
  if (!isError) interConfig.helperText = ''

  const select = interConfig.type === 'select'

  const { id, label, placeholder, helperText } = data.config

  //////////////  FUNCTIONS  /////////////////

  const onInnerChange = event => {
    const response = event.target.value;
    let temp = { ...data };
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

  const onInnerChangeS = (e, item) => {
    let temp = { ...data }
    if (item) {
      temp.value = item.value
      temp.isValid = true
    } else {
      temp.value = null
      temp.isValid = rules ? false : true
    }
    temp.isVisited = true
    onChange(temp)
  }

  const handleDateChange = (date) => {
    const response = date.format('YYYY-MM-DD');
    let temp = { ...data };
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

  ///////////////////// INTERNAL VARIABLES  /////////////////////////////
  let selectedOptions = []
  let selectedValue = null
  if (data && select) {
    if (value !== null && value !== undefined) {
      const singleEl = options.find(el => el.value === value)
      selectedValue = singleEl ? singleEl : selectedValue
    }
  }

  let inputlabel = null;
  if (label) inputlabel = <Typography variant='subtitle2' color={color ? color : (!isValid && isVisited ? 'error' : 'inherit')}>{label}</Typography>


  const selectInput = (
    <div>
      {inputlabel}
      <Autocomplete
        disabled={data.disabled || disabled}
        value={selectedValue}
        options={options}
        getOptionLabel={option => option.label}
        renderInput={params => (
          <CssTextField {...params} variant='outlined'
            error={!isValid && isVisited} placeholder={placeholder}
            helperText={(!isValid && isVisited) ? helperText : null} />
        )}
        onChange={onInnerChangeS}
        onInputChange={onInputChange}
        popupIcon={<ExpandMoreIcon style={{ fill: 'white' }} />}
        noOptionsText={<Typography>Sin opciones </Typography>}
        renderOption={(option, state) =>
          <div>
            <Typography variant={state.selected ? 'subtitle1' : 'body1'}>{option.label}</Typography>
          </div>
        } />
    </div>
  )

  const error = isVisited && !isValid;

  const textInput = (
    <CssTextField value={value} variant={variant ? variant : 'outlined'}
      error={isError} {...interConfig} helperText={(!isValid && isVisited) ? helperText : null}
      onChange={onInnerChange}
      type={type ? type : interConfig.type}
      InputProps={
        {
          startAdornment: startAdornment ? startAdornment : undefined,
          endAdornment: endAdornment ? endAdornment : undefined,
          onKeyUp: onKeyUp ? onKeyUp : undefined,
          onKeyDown: onKeyDown ? onKeyDown : undefined,
        }}
    />
  )

  const dateInput = (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <CSSKeyboardDatePicker
        disabled={disabled}
        value={value ? moment(value) : null}
        placeholder={placeholder}
        error={error}
        fullWidth
        variant="inline"
        inputVariant='outlined'
        format="DD/MM/YYYY"
        label={label}
        helperText={error ? helperText : null}
        onChange={handleDateChange}
        clearable
        autoOk

      />
    </MuiPickersUtilsProvider>
  )

  let inputContent = null
  switch (config.type) {
    case 'select':
      inputContent = selectInput
      break;
    case 'date':
      inputContent = dateInput
      break;
    default:
      inputContent = textInput
      break;
  }

  return (
    <div>
      {inputContent}
    </div>
  )
}

export default InputForm