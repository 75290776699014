import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import MainContainer from '../../../../components/Structure/Layouts/MainContainer'

const styles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        width: '100%',
        height: '100vh',
        objectFit: 'cover',
        filter: 'brightness(50%)',
    },
    content: {
        width: '100%',
        height: '100%',
        color: 'white',
        position: 'relative',
        transform: 'translateY(-100vh)'
    },
    center: {
        margin: 0,
        position: 'absolute',
        top: '40%',
        transform: 'translateY(-40%)',
        [theme.breakpoints.only('xs')]: {
            left: 0,
            padding: 32
        }
    },
    title: {
        fontSize: 36,
        fontWeight: 500,
        '& span': {
            fontSize: 42,
            fontWeight: 600
        }
    },
    description: {
        width: '60%',
        fontSize: 24,
        fontWeight: 500,
        letterSpacing: '0.285em',
    }
}))

const MainBanner = ({ content }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <img src={content.banner} className={classes.image} />
            <div className={classes.content}>
                <MainContainer>
                    <div className={classes.center}>
                        <Typography className={classes.title}>
                            {content.welcome} <span>{content.kuvik}</span>
                        </Typography>
                        <Typography className={classes.description} align='justify'>
                            {content.description}
                        </Typography>
                    </div>
                </MainContainer>
            </div>
        </div >
    )
}

export default MainBanner