import { Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import GenericImage from '../../../../components/Structure/DisplayData/GenericImage';
import cx from 'classnames'
import { grey } from '@material-ui/core/colors';
import { catalogs } from '../../../../texts/eng/catalogs';
import StopIcon from '@material-ui/icons/Stop'
import { CONFIG_SETTINGS } from '../../../../config';
import { isValidDocument, onValidateURL } from '../../../../shared/utility';

const MAX_ITEMS = 1;
const MAX_WIDTH = '100%';

const CustomCarousel = ({ data, onItemSelected, noImage }) => {

    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'))

    const [currentSlide, setCurrentSlide] = useState(0)
    const [dots, setDots] = useState([])
    const [total, setTotal] = useState(1)
    const [interval, setIntervalState] = useState(null)

    useEffect(() => {
        if (data && data.length > 0) {
            setTotal(data.length)
            let _dots = []
            const ndots = Math.ceil(data.length / MAX_ITEMS);
            for (let i = 0; i < ndots; i++) _dots.push(i);
            setDots(_dots)
        }
    }, [data])

    useEffect(() => {
        setIntervalState(setInterval(() => {
            setCurrentSlide(prev => prev + 1)
        }, 5000))

        return () => clearInterval(interval)
    }, [])

    const onSetSlide = (id) => {
        clearInterval(interval)
        setCurrentSlide(id)
    }


    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                {data.map((banner, key) => {
                    const { id_banner, web_image, mobile_image, title, subtitle, description,
                        web_link, app_link } = banner

                    let image = isMobile ? mobile_image : web_image
                    let link = isMobile ? app_link : web_link

                    const isValid = isValidDocument(image)
                    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : noImage



                    return (
                        <div
                            className={cx({
                                [classes.banner_card]: true,
                                [classes.banner_cardShow]: currentSlide % total === key
                            })}
                            key={id_banner.toString()}
                        >
                            <img src={selectedImage} className={classes.image} />
                            <div className={classes.content} onClick={() => onItemSelected(link)}>
                                <div className={classes.center}>
                                    <Typography className={classes.topTitle}>
                                        {subtitle ? subtitle : ''}
                                    </Typography>
                                    <Typography className={classes.title}>
                                        {title ? title : ''}
                                    </Typography>
                                    <StopIcon color='white' />
                                    <Typography className={classes.date}>
                                        {description ? description : ''}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={classes.dotContainer}>
                <Grid container justifyContent='center' spacing={4}>
                    {dots.map(item => {
                        return (
                            <Grid item xs={12} key={`dot-${item}`}>
                                <div className={cx({
                                    [classes.dot]: true,
                                    [classes.dot_active]: currentSlide % total === item
                                })} onClick={() => onSetSlide(item)} />
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        background: 'black',
        position: 'relative',
        cursor: 'pointer'
    },
    wrapper: {
        width: '100%',
        height: '100vh',
        position: 'relative',
    },
    image: {
        width: '100%',
        height: '100vh',
        objectFit: 'cover',
        opacity: 0.5,
    },
    banner_card: {
        width: '100%',
        height: 0,
        visibility:'hidden',
        position: 'absolute',
        opacity: 0,
        transition: 'opacity 0.5s ease-in-out'
    },
    banner_cardShow: {
        height: '100%',        
        visibility:'initial',
        //transform: 'scale(1)',
        opacity: 1
    },
    dotContainer: {
        top: 0,
        left: 0,
        position: 'absolute',
        height: '100%',
        transform: 'translate(100%,50%)',
        [theme.breakpoints.only('xs')]: {
            top: '40%',
            left: '50%',
            transform: 'rotate(90deg) translate(-80%,50%)',
        }
    },
    dot: {
        width: 10,
        height: 10,
        background: grey[500],
        cursor: 'pointer',
        borderRadius: '100%',
    },
    dot_active: {
        backgroundColor: 'white'
    },
    content: {
        width: '100%',
        height: '100%',
        top: 0,
        position: 'absolute'
    },
    center: {
        color: 'white',
        width: '100%',
        boxSizing: 'border-box',
        padding: 20,
        textAlign: 'center',
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    topTitle: {
        fontSize: 24,
        fontWeight: 28,
        paddingBottom: 12,
        width: '100%'
    },
    title: {
        fontSize: 36,
        fontWeight: 400,
        paddingBottom: 12
    },
    date: {
        fontSize: 24,
        fontWeight: 400
    },
}))

export default CustomCarousel;

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}