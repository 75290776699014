import React from "react"
import { Box, Grid, makeStyles, SvgIcon, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"

const styles = makeStyles(theme => ({
    root: {
        //border:'1px solid black',
        backgroundColor: '#FCFCFC',
        marginBottom: 0,
        paddingLeft: 100,
        paddingRight: 100,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        },
    },
    container: {
        padding: '100px 100px 32px 100px',
        margin: 'auto',
        boxSizing: 'border-box',
    },
    title: {
        //fontSize: 48,
        fontWeight: 700
    },
    subtitle: {
        //fontSize: 30,
        fontWeight: 400,
        '@global span': {
            color: theme.palette.secondary.main,
            fontWeight: 600,
        }
    },
    banner: {
        width: '100%',
        height: 'auto'
    },
    image_container: {
        height: '115%',
        position: 'relative',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            height: '100%'
        }
    },
    image: {
        position: 'absolute',
        height: '86%',
        top: 0,
        left: 0,
        transform: 'translateX(55%)',
        [theme.breakpoints.only('xs')]: {
            height: '95%',
            transform: 'translateX(60%)',
        }
    },
    buttons: {
        width: 270,
        height: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        }
    }
}))

const DonwloadApp = ({ history, content }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md>
                    <div className={classes.container}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}> 
                                        <Typography variant='h4' className={classes.title}>
                                            {content.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <Typography variant='h6' align='justify' className={classes.subtitle}>
                                            {content.description[0]} <span>{content.description[1]}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <img src={content.store_buttons.ios} className={classes.buttons} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <img src={content.store_buttons.android} className={classes.buttons} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.image_container}>
                        <img src={content.banner.bg} style={{ width: '100%', height: 'auto' }} />
                        <img src={content.banner.phone} className={classes.image} />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default DonwloadApp