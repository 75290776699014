import React, { useEffect } from 'react'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

const styles = makeStyles(theme => ({
    root: {
        height: '100vh',
        background: 'black'
    },
    half: {
        width: '50%',
        height: '100vh',
        background: 'rgba(248, 248, 248, 0.1)'
    },
    content: {
        width: '100%',
        height: '100%',
        color: 'white',
        position: 'relative',
        transform: 'translateY(-100vh)'
    },
    center: {
        width: '100%',
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        [theme.breakpoints.only('xs')]: {
            padding: 32,
            boxSizing: 'border-box'
        }
    },
    title: {
        color: theme.palette.secondary.main,
        fontSize: 48,
        fontWeight: 600,
        padding: '0px 20px 12px 20px',
        [theme.breakpoints.only('xs')]: {
            fontSize: 42
        }
    },
    topTitle: {
        color: 'white',
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.5em'
    },
    description: {
        color: grey[200],
        fontSize: 15,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.285em'
    },
    divider: {
        backgroundColor: grey[600]
    },
}))

const ComingSoon = ({ content }) => {

    const classes = styles()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className={classes.root}>
            <div className={classes.half}></div>
            <div className={classes.content}>
                <div className={classes.center}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className={classes.topTitle} align='center'>
                                {content.top_title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ boxSizing: 'border-box' }}>
                                <Grid container justifyContent='center' alignItems='center'>
                                    <Grid item xs>
                                        <Divider className={classes.divider} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.title} align='center'>
                                            {content.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Divider className={classes.divider} />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.description} align='center'>
                                {content.caption}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div >
    )
}

export default ComingSoon