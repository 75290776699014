import React, { useEffect, useState } from "react"
import { alpha, Box, Card, CardActionArea, CardContent, Fade, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import Carousel from 'react-material-ui-carousel'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import cx from 'classnames'
import PageContainer from "../../../../components/Structure/Layouts/PageContainer"
import { CONFIG_SETTINGS } from '../../../../config';
import { isValidDocument } from '../../../../shared/utility';
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import { grey } from "@material-ui/core/colors"
import GenericImage from "../../../../components/Structure/DisplayData/GenericImage"

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80,
        fontFamily: theme.typography.fontFamily
        //paddingLeft: 100,
        //paddingRight: 100,
        //[theme.breakpoints.down('sm')]: {
        //    padding: 0
        //},
        //marginBottom: 100,
        //[theme.breakpoints.only('xs')]: {
        //    paddingBottom: 100,
        //    margin: 32
        //}
    },
    text: {
        //fontSize: 30,
        fontWeight: 300
    },

    bold: {
        //fontSize: 30,
        fontWeight: 500
    },

    subtitle: {
        fontSize: 30,
        fontWeight: 600
    },
    gallery: {
        color: 'black',
        width: '100%',
        margin: 'auto',
        marginTop: 40,
        marginBottom: 80,
        //padding: '0px 100px',
        boxSizing: 'border-box',
        //maxWidth:1440,
        [theme.breakpoints.up('xl')]: {
            padding: '0px 100px',
        }

    },
    menu_wrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    menu_item: {
        marginRight: 150,
        fontWeight: 500,
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        },
        [theme.breakpoints.down('xs')]: {
            flex: 1
        },
    },
    title_cards: {
        display: 'flex',
        alignItems: 'center'
    },
    text_small: {
        fontSize: 20,
        fontWeight: 300
    },
    single_card: {
        marginBottom: 32
    },
    column: {
        margin: '0px 16px'
    },
    mid_column: {
        margin: '0px 16px',
        marginTop: 48,
    }
}))

const Plataform = ({ history, platform, content }) => {

    const classes = styles()
    const theme = useTheme()

    const ismobile = useMediaQuery(theme.breakpoints.only('xs'))
    const ismobiledev = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container spacing={6} justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Typography variant='h4' style={{ letterSpacing: 2 }}>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.menu_wrapper}>
                            {content.categories.map((item, key) => (
                                <div key={`platf-cat.${key}`} className={classes.menu_item}>
                                    <div className={classes.title_cards}>
                                        <Box
                                            sx={{ width: 10, height: 30, bgcolor: item.bgcolor, display: 'inline-block', marginRight: 10 }} />
                                        {item.title}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='body1' align='justify' className={classes.text_small}>
                            {content.description}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.gallery}>
                    {platform && platform.length > 0 ?
                        <Grid container justifyContent='center' spacing={0}>
                            <Grid item xs={12} md={4}>
                                <div className={classes.column}>
                                    {content.gal_first_colum.map(item => {
                                        const cardInfo = platform.find(e => e.id_home_section === item.id)
                                        return (
                                            <div key={`single-card-${item.id}`} className={classes.single_card}>
                                                <SingleCard {...cardInfo} noImage={content.noImage} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className={classes.mid_column}>
                                    {content.gal_second_colum.map(item => {
                                        const cardInfo = platform.find(e => e.id_home_section === item.id)
                                        return (
                                            <div key={`single-card-${item.id}`} className={classes.single_card}>
                                                <SingleCard {...cardInfo} noImage={content.noImage} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className={classes.column}>
                                    {content.gal_thrid_colum.map(item => {
                                        const cardInfo = platform.find(e => e.id_home_section === item.id)
                                        return (
                                            <div key={`single-card-${item.id}`} className={classes.single_card}>
                                                <SingleCard {...cardInfo} noImage={content.noImage} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </Grid>
                        </Grid>
                        : null}
                </div>
            </MainContainer>
        </div>
    )
}
/*
content.gallery.map((card, key) => {
                            const cardInfo = platform.find(e => e.id_home_section === card.id)
                            return (
                                <Grid item xs={12} sm={6} md={4} key={`card_cont-${key}`}>
                                    <CarouselCard ismobile={ismobiledev} {...cardInfo} nopicture={content.noImage} />
                                </Grid>
                            )
                        })
*/

export default Plataform

const SingleCard = ({ link, title, description, nopicture, web_image_1,
    web_image_2, web_image_3, mobile_image_1, mobile_image_2, mobile_image_3, noImage }) => {

    const classes = useSingleCardStyles()

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
    const images = isMobile ? [mobile_image_1, mobile_image_2, mobile_image_3] : [web_image_1, web_image_2, web_image_3]

    const [hover, setHover] = useState(false)
    {/*const [selectedImages, setSelectedImages] = useState([])*/ }
    const [current, setCurrent] = useState(0)
    /*
        useEffect(() => {
            if (images && images.length > 0) {
                let _selectedImages = []
                images.map(image => {
                    const isValid = isValidDocument(image)
                    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : noImage
                    _selectedImages.push(selectedImage)
                })
    
                setSelectedImages(_selectedImages)
            }
        }, [images])
    */
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent(prev => prev + 1)
        }, Math.random() * (5000 - 3000) + 3000);
        return () => clearInterval(interval)
    }, []);

    return (
        <div className={classes.root}
            onClick={() => window.open(link)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            {images.map((image, key) => (
                <div
                    className={cx({
                        [classes.imageContainer]: true,
                        [classes.showImage]: current % 3 === key
                    })}
                >
                    {/*<img src={image} className={classes.image} />*/}
                    <GenericImage src={image} ratio='3/4' />
                </div>
            ))}
            <Fade in={hover}>
                <div className={classes.overlay}>
                    <Typography align="center" className={classes.description}>{description}</Typography>
                </div>
            </Fade>
            <Typography className={classes.title}>{title}</Typography>
        </div>
    )
}

const useSingleCardStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        background: 'black',
        borderRadius: 10,
        cursor: 'pointer',
        position: 'relative',
        color: 'white',
        '&:hover': {
            color: theme.palette.secondary.main
        }
        //border:'1px solid red'
    },
    title: {
        position: 'absolute',
        padding: 20,
        bottom: 0, left: 0,
        zIndex: 3
    },
    overlay: {
        position: 'absolute',
        background: alpha('#000', 0.35),
        top: 0, left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 10,
        zIndex: 2
    },
    description: {
        position: 'absolute',
        top: '50%', left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        width: 170,

    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    imageContainer: {
        width: 0,
        height: 0,
        opacity: 0,
        transition: 'width 0s, height 0s, opacity 0.5s ease-in-out'
    },
    showImage: {
        width: '100%',
        height: '100%',
        opacity: 1
    },
}))


/**
 * 
 * const styles_image_card = makeStyles(theme => ({
    card: {
        height: 500,
        background: 'black',
        borderRadius: 7,
        margin: 'auto',
        boxSizing: 'border-box',
        margin: 24,
        marginBottom: 32,
        [theme.breakpoints.only('xs')]: {
            margin: 0,
            marginBottom: 32,
        }
    },
    down: {
        marginTop: 45,
        marginBottom: 0,
    },
    action_area: {
        height: '100%',
        borderRadius: 7
    },
    card_content: {
        height: '100%',
        position: 'relative',
        padding: 0
    },
    carousel: {
        boxSizing: 'border-box',

    },
    content: {
        width: '100%',
        color: 'white',
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translate(-50%,-100%)',
        padding: 24,
        boxSizing: 'border-box'
    },
    image: {
        width: '100%',
        height: 500,
        objectFit: 'cover',
        boxSizing: 'border-box',
    },
    imageHover: {
        opacity: 0.5
    },
    title: {
        //fontSize: 20,
        fontWeight: 500,
        textTransform: 'uppercase'
    },
    description: {
        //fontSize: 14
    },
    contentInfo: {
        display: 'none'
    },
    contentInfoShow: {
        height: 200,
        display: 'block'
    }
}))

const CarouselCard = ({ ismobile, down, title, description, nopicture, web_image_1,
    web_image_2, web_image_3, mobile_image_1, mobile_image_2, mobile_image_3 }) => {

    const classes = styles_image_card()
    const theme = useTheme()
    const isMobile = theme.breakpoints.only('xs')

    const [show, setShow] = useState(false)
    let images = isMobile ? [mobile_image_1, mobile_image_2, mobile_image_3] : [web_image_1, web_image_2, web_image_3]

    images.forEach(image => {
        const isValid = isValidDocument(image)
        return isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : nopicture
    })

    return (
        <Card
            classes={{
                root: cx({
                    [classes.card]: true,
                    [classes.down]: down && !ismobile
                })
            }}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            <CardActionArea classes={{ root: classes.action_area }}>
                <CardContent classes={{ root: classes.card_content }}>
                    <Carousel
                        className={classes.carousel}
                        navButtonsWrapperProps={{ style: { display: 'none' } }}
                        indicatorContainerProps={{ style: { display: 'none' } }}
                    >
                        {images.map((image, key) => <img key={`car_img-${key}`} src={image} className={cx({ [classes.image]: true, [classes.imageHover]: show })} />)}
                    </Carousel>
                    <div className={classes.content}>
                        <Grid container justifyContent='center' alignItems='stretch' spacing={8}>
                            <Grid item xs={12}>
                                <div className={cx({
                                    [classes.contentInfo]: true,
                                    [classes.contentInfoShow]: show
                                })}>
                                    <Typography variant="caption" align='center' component='p'>
                                        {description}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" component="div" className={classes.title}>
                                    {title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card >
    )
}
 * 
 */