import React, { useState } from "react"
import { Button, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Icon, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import cx from 'classnames'
import PageContainer from "../../../../components/Structure/Layouts/PageContainer"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"

const styles = makeStyles(theme => ({
    root: {
        //width: '100%',
        //height: 'auto',
        minHeight: '120vh',
        //paddingLeft: 100,
        //paddingRight: 100,
        //paddingBottom: 100,
        boxSizing: 'border-box',
      /*  [theme.breakpoints.up('md')]: {
            height: '110vh'
        },
        [theme.breakpoints.up(1200)]: {
            height: '120vh'
        },
        [theme.breakpoints.up(1300)]: {
            height: '110vh'
        },
        [theme.breakpoints.up(1600)]: {
            height: '110vh'
        },
        [theme.breakpoints.up(2000)]: {
            height: '110vh'
        },*/
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em',
    },
    divider: {
        backgroundColor: grey[600]
    },
    titleS: {
        fontSize: 32,
        fontWeight: 600,
        textTransform: 'uppercase',
        '& span': {
            color: theme.palette.secondary.main
        }
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        filter: 'brightness(0.5)',
        [theme.breakpoints.only('sm')]: {
            maxHeight: 300
        }
    },
    cards: {
        //marginTop: -50,
        padding: '40px 100px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px',
        }
    }
}))


const MemberBenefits = ({ history, content }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title}>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.description} align='center'>
                            {content.description}
                        </Typography>
                    </Grid>
                </Grid>
            </MainContainer>
            <div className={classes.cards}>
                <Grid container spacing={3}>
                    {content.options.map(option => (
                        <Grid item xs={12} md={3}>
                            <MembershipCard {...option} />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div >
    )
}

export default MemberBenefits


const stylesCard = makeStyles(theme => ({
    root: {
        //width: 300,
        //padding:'0px 100px',
        //height: '100%',
        position: 'relative',
        margin: 'auto',
        height: 'auto',
        [theme.breakpoints.only('xs')]: {
            //marginBottom: 500,
            //width: '100%',
            //height: 'auto',
            //minHeight: 'inherit',
        },
        //[theme.breakpoints.between(1132, 1448)]: {
        //    width: '100%'
        //},

    },
    rootH: {
        //minHeight: 800,
    },
    imageContainer: {
        position: 'relative',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        paddingTop: '177%',
        //border:'1px solid black',
        borderRadius: 10,
        //width: 300,
        //height: 700,
        [theme.breakpoints.only('xs')]: {
            //width: '100%',
            //height: '100%'
        },
        [theme.breakpoints.between(1132, 1448)]: {
            //width: '100%'
        },
        boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.17)'
    },
    image: {
        position: 'absolute',
        borderRadius: 10,
        top: 0, left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    card: {
        width: '90%',
        height: 220,
        boxSizing: 'border-box',
        padding: 12,
        background: 'rgba(39, 39, 39, 0.95)',
        margin: 'auto',
        color: 'white',
        marginTop: 20
    },
    cardH: {
        background: 'black',
        height: 'auto',
        //transform: 'translate(-50%, -40%)'
    },
    icon: {
        width: 60,
        height: 60,
        border: '1px solid white',
        borderRadius: '100%',
        background: 'rgba(255, 255, 255, 0.08)',
        position: 'relative'
    },
    iconH: {
        background: theme.palette.secondary.main
    },
    title: {
        fontSize: 16,
        fontWeight: 600
    },
    description: {
        fontSize: 14,
        fontWeight: 500,
    },
    list: {
        fontSize: 14,
        fontWeight: 500,
        display: 'list-item',
        listStylePosition: 'inside'
    }
}))

const MembershipCard = ({ icon, title, description, list, image }) => {

    const [hover, setHover] = useState(false)
    const classes = stylesCard()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const contentView = (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                {description && description.map(text => (
                    <Grid item xs={12}>
                        <Typography className={classes.description} align='justify'>
                            {text}
                        </Typography>
                    </Grid>
                ))}
                {list && list.map(item => (
                    <Grid item xs={12}>
                        <Typography className={classes.list}>
                            {item}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )

    return (
        <>
            {/*<div className={classes.root2}>
                <img src={image} width='100%' />
    </div>*/}

            <div
                className={cx({
                    [classes.root]: true,
                    [classes.rootH]: hover,
                })}
                onMouseEnter={!isMobile ? () => setHover(true) : null}
                onMouseLeave={!isMobile ? () => setHover(false) : null}
                onClick={isMobile ? () => setHover(prev => !prev) : null}
            >
                <div className={classes.imageContainer}>
                    <img src={image} className={classes.image} />
                </div>
                <Card className={cx({
                    [classes.card]: true,
                    [classes.cardH]: hover
                })}>
                    <CardActionArea>
                        <CardContent>
                            <Grid container justifyContent='center' spacing={4}>
                                <Grid item>
                                    <div className={cx({
                                        [classes.icon]: true,
                                        [classes.iconH]: hover
                                    })}>
                                        <SVGIcon src={icon} color='white' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }} />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom className={classes.title} align='center'>
                                        {title}
                                    </Typography>
                                </Grid>
                                {hover && contentView}
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>

            </div>
        </>

    )
}
