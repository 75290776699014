import React from 'react'
import { TextField, withStyles } from '@material-ui/core'
import { ruleValidation } from './customFunctions'

const CssTextField = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    '& .MuiFormLabel-root': {
      color: '#6f6c6c',
    },
    '& label': {
      width: '100%'
    },
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '& .MuiInput-underline:before': {
      borderBottom: '0px',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0px',
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 12,
    },
    '& .MuiInputBase-input': {
      color: 'white',
    }
  },
}), { withTheme: true })(TextField);

const InputFormG = props => {

  const { data, variant, color, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown } = props

  const onInnerChange = event => {
    const response = event.target.value;
    let temp = { ...data };
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

  const { config, value, isValid, isVisited } = data

  const isError = isVisited && !isValid
  let interConfig = { ...config }
  if (!isError) interConfig.helperText = ''


  return (
    <div>
      <CssTextField value={value} variant={variant ? variant : 'standard'}
        error={isError} {...interConfig} onChange={onInnerChange}
        InputProps={
          {
            startAdornment: startAdornment ? startAdornment : undefined,
            endAdornment: endAdornment ? endAdornment : undefined,
            onKeyUp: onKeyUp ? onKeyUp : undefined,
            onKeyDown: onKeyDown ? onKeyDown : undefined,
          }}
      />
    </div>
  )
}

export default InputFormG