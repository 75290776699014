import React, { useState, useEffect } from 'react';
import { translations as translations_esp } from '../../../texts/eng/website/exhibitions-translations'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import { onGetErrorMessage } from '../../../shared/utility';
//import { request_home_info } from '../request';
import Header from './sections/Header';
import Current from './sections/Current';
import Individuals from './sections/Individuals';
import InterestBanner from './sections/InterestBanner';
import Coming from './sections/Coming';
import useExhibitionsView from './useExhibitionsView';
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer';
import Archived from './sections/Archived/Archived';
import { actionTypes } from '../../../store/actions';

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        marginTop: 140,
        //minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const ExhibitionsView = ({ is_auth, history, onUpdateContactDrawer }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useExhibitionsView({ is_auth, history, onUpdateContactDrawer })
    const { loading, sending, success, error } = system

    return system.loading ? <div className={classes.loadingContainer}><div className={classes.loading}><CircularProgress style={{ color: 'white' }} /></div></div> :
        <LoadingContainer loading={loading} sending={sending} error={error} success={success} onCloseError={actions.onCloseError} onCloseSuccess={actions.onCloseSuccess}>
            <div className={classes.content}>
                <Header history={history} content={content.main_info_section} />
                {view_data.current &&
                    <Current
                        history={history}
                        exhibitionsData={view_data.current}
                        content={content.current_section}
                        disabled={!is_auth}
                        onSaveExhibition={actions.onSaveExhibition}
                        onDownloadExhibition={actions.onDownloadExhibition}
                        onVisitExhibition={actions.onVisitExhibition}
                    />
                }
                {view_data.individuals &&
                    <Individuals
                        history={history}
                        exhibitions={view_data.individuals}
                        content={content.individuals_section}
                        disabled={!is_auth}
                        onSaveExhibition={actions.onSaveExhibition}
                        onVisitExhibition={actions.onVisitExhibition}
                    />
                }
                <InterestBanner history={history} content={content.interest_banner_section} onShowContactDrawer={actions.onShowContactDrawer}/>
                {view_data.coming &&
                    <Coming
                        history={history}
                        exhibitionsData={view_data.coming}
                        content={content.coming_section}
                        onDownloadExhibition={actions.onDownloadExhibition}
                        onShowContactDrawer={actions.onShowContactDrawer}
                    />
                }
                {view_data.archived_exhibitions &&
                    <Archived
                        history={history}
                        filter={view_data.filter}
                        exhibitions={view_data.archived_exhibitions}
                        images={view_data.exhibitionImages}
                        content={content.archived_section}
                        onVisitExhibition={actions.onVisitExhibition}
                        onFilter={actions.onSendFilter}
                    />
                }
            </div>
        </LoadingContainer>
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        is_auth: state.is_auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateContactDrawer: (contact_drawer) => dispatch({ type: actionTypes.DRAWER_CONTACT, contact_drawer }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitionsView);