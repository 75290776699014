import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { isValidDocument, onGetErrorMessage, onValidateURL } from '../../../shared/utility'
import queryString from 'query-string'
import { CONFIG_SETTINGS, private_server, public_server } from '../../../config'
import FileSaver from 'file-saver'
import axios from 'axios'

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const useMagazinesDetailsView = ({ match, history, onUpdateContactDrawerStatus, onUpdateMagazineReference }) => {

    const classes = styles()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [subtitle, setSubtitle] = useState(null)
    const [iframe, setIframe] = useState(null)
    const [pdfGallery, setPdfGallery] = useState(null)
    const [artists, setArtists] = useState([])

    const magazineURL = match.params.url

    useEffect(() => {
        actions.initModule()
    }, [])

    const actions = {
        initModule: async () => {
            window.scrollTo(0, 0)
            try {
                setLoading(true)

                let _magazines = await public_server.get(`/magazine/url/ns?url=${magazineURL}`)
                const { title, iframe, file, id_magazine } = _magazines.data.data.magazine
                setSubtitle(title)
                setIframe(iframe)

                if (isValidDocument(file)) {
                    const fullpath = `${CONFIG_SETTINGS.S3_SERVER_URL}${file}`
                    //delete axios.defaults.headers.common["Authorization"]
                    const response = await axios.get(fullpath, {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                        },
                    }, { responseType: 'blob' })
                    //console.log(response.data)
                    setPdfGallery(fullpath)
                    //FileSaver.saveAs(response.data, `document`)
                }

                let filterArgs = {
                    "AND": [{ "field": "magazine_id", "operator": "=", "value": id_magazine },]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))

                let params2send = { offset: 0, order: 1, order_by: 'id_magazine_artist' }
                let params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
                const _artists = await public_server.get(`/magazineartist/all/ns${params}`)
                setArtists([..._artists.data.data.magazine_artists])

                params = `?${queryString.stringify(params2send)}`

            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onOpenContactDrawer: async () => {
            await onUpdateContactDrawerStatus(true)
        },
        onArtistSelected: (enabled, url) => {
            //console.log(enabled, url)
            if (enabled === 1 && url && url.length > 0)
                history.push(`/artists/${url}`)
        }
    }

    const system = { loading, sending, success, error }
    const view_data = { subtitle, pdfGallery, iframe, artists }

    return { system, view_data, actions }
}

export default useMagazinesDetailsView