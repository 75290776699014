import React, { useState } from "react"
import { CONFIG_SETTINGS, private_server, public_server } from "../../config";
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, onSetProMode, onSetTokenSession, updateAuthorizationHeader } from "../../shared/utility";
import { generic_errors } from "../../texts/eng/genericErrors";

const useLogInDrawer = ({ language, content, onUpdateDrawerStatus,
    onUpdateDrawerSignUp, onUpdateAuthStatus, onUpdateUserData, onUpdateProMode }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [showPassword, setShowPassword] = useState(false)

    const actions = {
        onInitModule: () => {
            const _form = onInitForm(form, null, content.form)
            setLoading(false)
            setSending(false)
            setError(null)
            setForm(_form)
        },
        onClose: async () => {
            await onUpdateDrawerStatus(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onUpdateShowPassword: (value) => {
            setShowPassword(value)
        },
        onOpenSignUp: async () => {
            await onUpdateDrawerStatus(false)
            await onUpdateDrawerSignUp(true)
        },
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSubmit: async () => {
            const errors = isFormValid(form)

            // Error handler
            if (errors && errors.length) {
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            data2send.device = "web"

            try {
                setSending(true)
                const response = await public_server.post(`/login`, data2send)
                const token_data = response.data.data
                await onSetTokenSession(token_data)
                updateAuthorizationHeader(private_server)
                const user_request = await private_server.get('/user/me')
                onUpdateUserData(user_request.data.data.user)
                await onUpdateAuthStatus(true)
                setError(null)
                await onUpdateProMode(true)
                await onSetProMode(true)
                onUpdateDrawerStatus(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        }
    }

    return { loading, sending, success, error, form, showPassword, actions }
}

export default useLogInDrawer

const formData = {
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'email',
        }
    },
    password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
}