import { green, red } from '@material-ui/core/colors';
import moment from 'moment-timezone'
import { CONFIG_SETTINGS, DASHBOARD_SETTINGS, nodata, private_server, public_server, TOKEN_STATUSES } from '../config';
import { ERROR_CODES } from '../texts/eng/errorCodes';


export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};


///////////////////// SERVER HANDLERS ///////////////////////////
export const updateAuthorizationHeader = (server) => {
    const logData = JSON.parse(localStorage.getItem('session_data'));
    if (!logData) {
        console.log('Token not valid')
        return
    }
    server.defaults.headers.common['Authorization'] = `Bearer ${logData.token}`;
}

export const onGetTokenFromMemory = () => JSON.parse(localStorage.getItem('session_data'))

export const onClearTokenSession = async () => await localStorage.removeItem('session_token')

export const onSetTokenSession = async (token_data) => {
    if (token_data) await localStorage.setItem('session_data', JSON.stringify(token_data))
}

export const onGetProModeFromMemory = () => JSON.parse(localStorage.getItem('pro_mode'))

export const onSetProMode = async (pro_mode) => {
    await localStorage.setItem('pro_mode', JSON.stringify(pro_mode))
}

export const onClearMemory = async () => {
    await localStorage.removeItem('session_data')
    //await localStorage.removeItem('pro_mode')
}


export const onValidateToken = (session_data) => {
    if (!session_data) return TOKEN_STATUSES.NOT_VALID
    const current_time = moment().unix()
    const token_time = session_data.exp
    const diff = current_time - token_time
    if (diff < 0) {
        if (diff > -15 * 60) return TOKEN_STATUSES.REAUTH
        return TOKEN_STATUSES.VALID
    } else {
        return TOKEN_STATUSES.EXPIRED
    }

}

export const isValidDocument = (filename) => filename && filename !== "0" && filename !== 0 && filename !== ""

export const onGetCurrencyValue = (number, precision, symbol) => {
    let _symbol = "$"
    if(symbol) _symbol = symbol
    let result = ''
    if (!number) return `${_symbol}0`
    let temp = parseFloat(number).toFixed(precision !== null && precision !== undefined ? precision : 2)
    result = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    result = `${_symbol}${result}`
    return result
}

export const onReAuthToken = async () => {
    updateAuthorizationHeader(private_server)
    const token_request = await private_server.get('/login/reauthenticate')
    onSetTokenSession(token_request.data.data)
}

export const onGetCurrentUserProfile = async () => {
    updateAuthorizationHeader(private_server)
    const user_request = await private_server.get('/user/me')
    return user_request.data.data.user
}

export const onGetFormatNumber = (number, unit, precision) => {
    if (!number) return `0 ${unit}`
    let temp = parseFloat(number).toFixed(precision !== undefined && precision !== null ? precision : 2)
    return `${temp} ${unit}`
}

export const onSetValueInForm = (form, key, value) => {
    let _form = { ...form }
    _form[key].value = value !== undefined && value !== null ? value : ''
    _form[key].isValid = value !== undefined && value !== null
    _form[key].isVisited = value !== undefined && value !== null
    return _form

}

export const isFormValid = (form, keys) => {
    let errors = []
    if (form) {
        if (keys) {
            keys.forEach(item => {
                if (form[item].isRequired && !form[item].isValid) errors.push(item)
            })
        } else {
            Object.keys(form).forEach(item => {
                if (form[item].isRequired && !form[item].isValid) errors.push(item)
            })
        }
    }
    return errors
}

export const onSetErrorsToForm = (form, errors) => {
    let temp = { ...form }
    if (errors && errors.length) errors.forEach(item => {
        temp[item].isVisited = true
        temp[item].isValid = false
    })
    return temp
}
export const onGetFormData = (form, keys) => {
    let temp = {}
    if (form) {
        if (keys) {
            keys.forEach(item => {
                if (form[item].isValid) temp = { ...temp, [item]: form[item].value }
            })
        } else {
            Object.keys(form).forEach(item => {
                if (form[item].isValid) temp = { ...temp, [item]: form[item].value }
            })
        }

    }
    return temp
}

export const onGetSelectedFormData = (form, origin) => {
    let temp = {}
    if (form) {
        Object.keys(form).forEach(item => {
            if (form[item].isValid && form[item].value !== origin[item]) temp = { ...temp, [item]: form[item].value }
        })
    }
    return temp
}

export const onInitForm = (form, origin, translations) => {
    let response = { ...form }
    Object.keys(form).forEach(item => {
        if (origin) {
            const value = origin[item]
            if (value) response[item] = { ...response[item], value: value, isVisited: true, isValid: true }
        }
        if (translations && translations[item]) response[item].config = { ...response[item].config, ...translations[item] }
    })
    return response
}

export const onInitFiles = (files, translations) => {
    let response = { ...files }
    Object.keys(files).forEach(item => {
        if (translations && translations[item]) response[item] = { ...response[item], ...translations[item] }
    })
    return response
}

export const onCheckBooleanForm = (data) => {
    let _result = { ...data }
    if (data.value) {
        _result.value = true
        _result.isValid = true
    } else {
        _result.value = false
        _result.isValid = false
    }
    return _result
}

export const onCheckDateForm = (data) => {
    let momentDate = moment(data.value)
    let _result = { ...data }
    if (momentDate.isValid()) {
        _result.value = momentDate.format('YYYY-MM-DD')
        _result.isValid = true
    } else {
        _result.value = null
        _result.isValid = false
    }
    return _result
}

export const onGetDateFormat = (data, format) => {
    if (!data) return null
    const momentdata = moment(data)
    if (!momentdata.isValid()) return null
    if (format) return momentdata.format(format)
    return momentdata.format('YYYY-MM-DD')
}

export const onGetCatalogs = (options, keyValue, keyLabel) => {
    if (!options) return []
    return options.map(item => {
        return {
            value: item[keyValue], label: item[keyLabel] ? item[keyLabel] : ''
        }
    })
}

export const onGetEmployeesCatalogs = (options) => {
    if (!options) return []
    return options.map(item => {
        const fullname = onGetFullname(item.first_name, item.last_name)
        return {
            value: item.id_user, label: fullname
        }
    })
}

export const onGetFullname = (first_name, last_name) => {
    if (!first_name) return 'N/D'
    return `${first_name ? first_name : ''} ${last_name ? last_name : ''}`
}


export const onGetFileExtension = (filename) => filename.split('.').pop();

export const onGetUserID = () => {
    let res = null
    let localData = localStorage.getItem('data')
    if (localData && typeof localData === 'string') {
        localData = JSON.parse(localData)
        res = localData.user_id
    }
    return res
}

export const onGetErrorMessage = (error) => {
    let message = 'There was an error'
    if (!error) return ''
    if (error.response) {
        if (error.response.data) {
            if (error.response.data.error && error.response.data.error.code) {
                const _error = ERROR_CODES[error.response.data.error.code]
                if (_error) message = _error
            }

        }
    }
    return message
}

const monthsName = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

export const onGetMonthsCatalog = (year) => {
    let response = []
    for (let i = 0; i < 12; i++) {
        const startDate = moment([year, i])
        const endDate = moment(startDate).endOf('month')
        response.push({
            value: i + 1,
            label: monthsName[i],
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD')
        })
    }
    return response
}

export const onGetTimeDuration = (minutes) => {
    if (!minutes) return '0h'
    const _hours = Math.floor(minutes / 60)
    const _minutes = minutes % 60
    return _minutes > 0 ? `${_hours}h ${Math.floor(_minutes)}m` : `${_hours}h`
}

export const onGetDecimalNumber = (number) => {
    let result = ''
    if (!number) return '0.0'
    let temp = parseFloat(number).toFixed(1)
    result = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result
}

export const onGetResponsibleObj = (responsible_id, users, img) => {
    const no_responsible = { first_name: 'Sin', last_name: 'Registro', image: img }
    if (!responsible_id) return no_responsible
    if (!users) return no_responsible
    const _user = users.find(el => el.id_user === responsible_id)
    if (!_user) return no_responsible
    return _user
}

export const onUpdateDocumentList = (files, origin) => {
    if (!origin) return files
    let _files = files.map(item => {
        const document_path = origin[item.key]
        const isValid = isValidDocument(document_path)
        return { ...item, status: isValid }
    })
    return _files
}

export const onCheckAPIURL = async () => {

}

////////////////////////////////////////  DEVICE FILTERING  //////////////////////////////////////////////////

export const onGetModbusGeneralData = (device, search_keys) => {

    let data2send = {}

    if (!search_keys) return {}
    if (!device) return {}
    if (!device.data) return {}

    const device_data = device.data

    search_keys.forEach(item => {
        const foundEl = device_data[item]
        if (foundEl) {
            data2send = {
                ...data2send,
                [item]: {
                    type: foundEl.type,
                    unit: foundEl.unit,
                    value: foundEl.value,
                }
            }
        }
    })

    return data2send


}


export const onGetDeviceData = (device) => {

    let data2send = {}

    if (!device) return {}
    if (!device.data) return {}

    const current_data = device.data

    Object.keys(current_data).forEach(item => {
        data2send = {
            ...data2send,
            [item]: current_data[item].value
        }
    })

    return data2send


}

export const onGetTimeFormat = (data, format) => {
    if (!data) return nodata
    const _data = moment(data)

    if (!_data.isValid()) return nodata
    if (format) return _data.format(format)
    return _data.format('DD MMM, HH:mm')
}

export const onGetDeviceType = (device) => {
    if (!device) return nodata
    if (!device.model) return nodata
    let response = ''
    const { type, name } = device.model
    if (type) response = type
    if (name) response = `${response} - ${name}`
    return response
}

export const onGetDeviceStatus = (data) => {

    let data2send = {
        data: 'Offline',
        color: red[700]
    }

    if (!data) return data2send
    const current_time = moment()
    const device_time = moment(data)
    if (!device_time.isValid()) return data2send

    if (current_time.diff(device_time) > 5 * 60 * 1000) return data2send

    data2send.data = 'Online'
    data2send.color = green[700]

    return data2send
}

export const onUpdateToken = (token_data) => {
    if (token_data) return
    localStorage.setItem('token', token_data.token)
}

export const onValidateURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }


