import React from "react"
import { Divider, Grid, makeStyles, SvgIcon, Typography } from "@material-ui/core"
import InputFormW from "../../../components/Forms/InputFormW"
import RoundedButton from "../../../components/Actions/RoundedButton"
import { grey } from "@material-ui/core/colors"

const styles = makeStyles(theme => ({
    title: {
        fontSize: 22,
        fontWeight: 500
    },
    divider: {
        backgroundColor: grey[600]
    },
    icon: {
        fill: 'white',
        width: 20,
        height: 20,
        paddingRight: 10
    }
}))

const Step5 = ({ form, content, actions }) => {
    const classes = styles()

    return (
        <Grid container justifyContent='center' alignItems='center' spacing={5}>
            <Grid item xs={12}>
                <Grid container justifyContent='center' spacing={2}>
                    {Object.keys(content.form).slice(0, 2).map((item, key) => (
                        <Grid item xs={12} sm={4}>
                            <InputFormW data={form[item]} onChange={actions.onChange} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title}>
                                    {content.social_media}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {Object.keys(content.form).slice(2).map((item, key) => (
                        <Grid item xs={12} sm={4}>
                            <InputFormW
                                startAdornment={
                                    <img className={classes.icon} src={content.form[item].icon} />
                                }
                                data={form[item]} onChange={actions.onChange}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item>
                <RoundedButton onClick={actions.onNextStep}>
                    {content.button}
                </RoundedButton>
            </Grid>
        </Grid>
    )
}

export default Step5