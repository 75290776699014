import React, { useEffect, useState } from 'react';
import { Drawer, alpha, Grid, Icon, makeStyles, Typography, useMediaQuery, useTheme, SvgIcon } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionTypes } from '../../../store/actions'
import general_translations from '../../../texts/eng/pro/general-translations'
import cx from 'classnames';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import { withRouter } from 'react-router';
import RoundedButton from '../../../components/Actions/RoundedButton';

const Sidebar = ({ open, history, ismobile, onUpdateDrawerStatus }) => {

    const classes = useStyles()

    useEffect(() => {
        if (ismobile)
            onUpdateDrawerStatus(false)
    }, [])

    const isURLSelected = (path) => {
        return history.location.pathname.split('/')[1] === path.split('/')[1];
    }

    const onChangeView = (url) => {
        history.push(url)
    }

    const content = general_translations.sidebar

    return (
        <div>
            <Drawer open={open}
                variant={ismobile ? 'temporary' : 'persistent'}
                classes={{ paper: classes.drawer }}
            >
                <div className={classes.container}>
                    <div className={classes.logo_container}>
                        <img src={content.logo} alt='' className={classes.logo} />
                    </div>
                    <div className={classes.menu}>
                        <Grid container justifyContent='center' spacing={1}>
                            <Grid item xs={12}>
                                <Grid container justifyContent='center'>
                                    {content.list.map((item, key) => {

                                        const isactive = isURLSelected(item.path)

                                        return (
                                            <Grid item xs={12} key={key.toString()}>
                                                <SidebarItem active={isactive} {...item} onChangeView={onChangeView} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <RoundedButton
                                    size={'small'}
                                    color='black'
                                    onClick={() => history.push(content.pro_button.url)}
                                    style={{ fontSize: 14 }}
                                >
                                    {content.pro_button.label}
                                </RoundedButton>
                            </Grid>
                            <Grid item xs={12}>
                                <SidebarItem primary active={isURLSelected(content.logout.path)} {...content.logout} onChangeView={onChangeView} />
                            </Grid>
                        </Grid>

                    </div>
                </div>

            </Drawer>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    drawer: {
        color: theme.palette.secondary.main,
        background: 'white',
        border: 0,
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    logo: {
        width: '100%',
        height: '100%',
        padding: 8,
        boxSizing: 'border-box',
        //objectFit:'cover',
        position: 'absolute',
        top: 0, left: 0
    },
    logo_container: {
        width: 52,
        height: 52,
        margin: 'auto',
        borderRadius: '50%',
        boxSizing: 'border-box',
        background: 'white',
        position: 'relative'
    },
    container: {
        width: 125,
        padding: '24px 0px',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    title: {
        marginTop: 8
    },
    menu: {
        marginTop: 24
    }
}))

const mapStateToProps = state => {
    return {
        open: state.sidebar_drawer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (sidebar_drawer) => dispatch({ type: actionTypes.DRAWER_SIDEBAR, sidebar_drawer })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar))


const SidebarItem = ({ active, name, path, icon, onChangeView, primary }) => {

    const classes = useStylesItem()
    const [hover, setHover] = useState(false)
    const theme = useTheme()

    return (
        <div
            className={cx({
                [classes.root]: true,
                [classes.primary]: primary,
                [classes.active]: active,
                [classes.activeP]: active && primary,
            })}
            onClick={() => onChangeView(path)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Grid container justifyContent='center' alignItems='center' spacing={1}>
                <Grid item>
                    <SVGIcon
                        src={icon}
                        size={20}
                        colorStroke={active && hover ? 'white' : (primary ? theme.palette.primary.main : theme.palette.secondary.main)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography align='center' className={classes.title}>{name}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}

const useStylesItem = makeStyles(theme => ({
    root: {
        color: theme.palette.secondary.main,
        padding: '10px 14px',
        '&:hover': {
            background: alpha(theme.palette.secondary.main, 0.05),
            cursor: 'pointer',
            transition: theme.transitions.create(['background'], {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),

    },
    primary: {
        color: theme.palette.primary.main,
        '&:hover': {
            background: alpha(theme.palette.primary.main, 0.05)
        }
    },
    active: {
        background: 'rgba(189, 139, 64, 0.1)',
        '&:hover': {
            color: 'white',
            background: theme.palette.secondary.main,
            cursor: 'pointer',
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    activeP: {
        background: 'rgba(109, 26, 18, 0.1)',
        '&:hover': {
            background: theme.palette.primary.main,
        }
    },
    title: {
        fontSize: 12
    }
}))