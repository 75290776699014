import React, { useEffect, useState } from "react"
import { Checkbox, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, FormControlLabel, makeStyles, Typography, useMediaQuery, TextField, CardActions, IconButton } from '@material-ui/core'
import { useTheme, withStyles } from "@material-ui/styles"
import { grey } from "@material-ui/core/colors"
import MomentUtils from '@date-io/moment';
import cx from 'classnames'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./Carousel.css"
import MainContainer from "../../../../../components/Structure/Layouts/MainContainer"
import { isValidDocument, onGetDateFormat } from "../../../../../shared/utility"
import { CONFIG_SETTINGS } from "../../../../../config"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import SVGIcon from "../../../../../components/Structure/DisplayData/SVGIcon";
import { catalogs } from "../../../../../texts/eng/catalogs";

const TextFieldDate = withStyles(theme => ({
    root: {
        color: theme.palette.secondary.main,
        '& svg': {
            color: theme.palette.secondary.main,
        },
        '& input': {
            color: theme.palette.secondary.main,
        },
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        },
        '& .MuiInput-underline:before': {
            borderBottom: '0px',
        },
        '& .MuiInput-underline:after': {
            borderBottom: '0px'
        },
    },
}))(TextField);

const styles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '50vh',
        paddingTop: 80,
        paddingBottom: 80,
        boxSizing: 'border-box'
    },
    content: {
        paddingTop: 20,
        paddingBottom: 80,
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    galleryTitle: {
        fontSize: 28,
        fontWeight: 600,
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    date: {
        fontSize: 16,
        fontWeight: 500,
        '& span': {
            fontSize: 24,
            fontWeight: 400,
        }
    },
    description: {
        color: '#A5A5A5',
        fontSize: 16,
        fontWeight: 600,
        [theme.breakpoints.up('md')]: {
            lineHeight: '1.8em'
        }
    },
    carouselContainer: {
        marginTop: -100
    },
    featuredButton: {
        color: theme.palette.secondary.main,
        background: 'transparent',
        borderRadius: 4,
        border: `1px solid ${theme.palette.secondary.main}`,
        padding: '6px 28px 6px 10px',
        '&:hover': {
            color: 'white',
            background: `${theme.palette.secondary.dark}80`,
        },
    },
    featuredButtonActive: {
        color: 'white',
        background: theme.palette.secondary.main,
    },
    noContent: {
        fontSize: 16,
        fontWeight: 300
    },
    filterButton: {
        background: 'rgba(189, 139, 64, 0.14)',
        padding: 10,
        boxSizing: 'border-box',
        borderRadius: '100%',
        border: `1px solid ${theme.palette.secondary.main}`,
        '&:hover': {
            background: `${theme.palette.secondary.dark}50`,
        }
    },
    filterLabel: {
        fontSize: 18,
        fontWeight: 600
    }
}))

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
    }
}

const PreviousEditions = ({ history, content, magazines, onGetPreviousEditions, onClick }) => {
    
    const classes = styles()
    const theme = useTheme()
    const mobileXS = useMediaQuery(theme.breakpoints.only('xs'))
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const deviceType = mobileXS ? 'mobileXS' : 'mobile'

    //const [filter, setFilter] = useState(1)
    const [currentSlide, setCurrentSlide] = useState(1)
    const [nextSlide, setNextSlide] = useState(false)
    const [interval, setIntervalState] = useState(null)

    const [openDatePicker, setOpenDatePicker] = useState(false)
    const [date, setDate] = useState(null)
    const [featured, setFeatured] = useState(false)
    //const selectedFilter = catalogs.art_filter.find(e => e.id === filter)
    const allFilter = catalogs.art_filter.find(e => e.value === 1)


    useEffect(() => {
        setIntervalState(setInterval(() => setNextSlide(true), 8000))
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        let filter = {
            AND: [
                { field: "magazine_status_id", operator: "=", value: 6 }
            ]
        }
        if (featured) filter.AND.push({ field: 'featured', operator: '>', value: 0 })
        if (date) filter.AND.push({ field: 'created_at', operator: '=', value: date })
        filter = JSON.stringify(filter)

        onGetPreviousEditions(filter)
    }, [allFilter, featured, date])

    const onClearFilters = () => {
        setFeatured(false)
        setDate(null)
    }

    const CustomArrow = ({ onClick, side }) => {

        const handleClick = () => {
            //console.log('in', nextSlide, side, interval)
            clearInterval(interval)
            setIntervalState(null)
            setNextSlide(false)
            onClick()
            setIntervalState(setInterval(() => setNextSlide(true), 8000))
            let increment = side === 'right' ? 1 : -1
            setCurrentSlide(prev => {
                console.log(prev + increment, (prev + increment) % magazines.length)
                if (prev + increment < 0)
                    return magazines.length - 1
                else
                    return (prev + increment) % magazines.length
            })
        }

        if (nextSlide && side === 'right') {
            //console.log('AUTO SWIP')
            handleClick()
        }

        return (
            <button
                onClick={handleClick}
                aria-label="Go to next slide"
                className={
                    `react-multiple-carousel__arrow
                    react-multiple-carousel__arrow--${side}
                    carousel_arrow 
                    carousel_arrow-${side}`
                }
            />
        );
    }

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container alignItems='center' spacing={3}>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} align='right'>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>
                <div className={classes.content}>
                    <Grid container justify='space-between' alignItems='center' spacing={2}>
                        <Grid item>
                            <IconButton
                                className={classes.filterButton}
                                onClick={onClearFilters}
                            >
                                <SVGIcon
                                    size={24}
                                    src={allFilter.icon}
                                    colorStroke={theme.palette.secondary.main}
                                />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    views={["year", "month"]}
                                    clearable
                                    placeholder={content.date}
                                    //variant="inline"
                                    format="MMM YYYY"
                                    openTo="year"
                                    disableFuture
                                    value={date}
                                    InputProps={{ readOnly: true }}
                                    onClick={() => setOpenDatePicker(true)}
                                    open={openDatePicker}
                                    onClose={() => setOpenDatePicker(false)}
                                    TextFieldComponent={TextFieldDate}
                                    onChange={(date) => setDate(date)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item>
                            <FormControlLabel
                                className={cx({
                                    [classes.featuredButton]: true,
                                    [classes.featuredButtonActive]: featured
                                })}
                                control={
                                    <Checkbox
                                        checked={featured}
                                        icon={<StarBorderIcon style={{ fill: theme.palette.secondary.main }} />}
                                        checkedIcon={<StarIcon style={{ fill: 'white' }} />}
                                        onChange={(event) => setFeatured(event.target.checked)}
                                    />
                                }
                                label={content.featured}
                            />
                        </Grid>
                    </Grid>
                </div>
            </MainContainer>
            {magazines.length > 0 ?
                <div className={classes.carouselContainer}>
                    <Carousel
                        responsive={responsive}
                        ssr
                        infinite
                        keyBoardControl
                        //centerMode={!mobile}
                        customTransition="transform 300ms ease-in-out"
                        itemClass="carouselItem"
                        deviceType={deviceType}
                        removeArrowOnDeviceType={["mobileXS"]}
                        //arrows={false}
                        // afterChange={nextSlide => setCurrentSlide(nextSlide)}
                        customLeftArrow={<CustomArrow side='left' />}
                        customRightArrow={<CustomArrow side='right' />}
                    >
                        {magazines.map((item, key) => {
                            //console.log('CUR', currentSlide)
                            // let idx = currentSlide ? Math.abs(currentSlide - 3 ) % magazines.length : 1
                            //console.log('IDX', idx)

                            return (
                                <CarouselItem
                                    key={key.toString()}
                                    current={currentSlide === key}
                                    {...item}
                                    content={content}
                                    onClick={onClick}
                                />
                            )
                        })}
                    </Carousel>
                </div>
                :
                <Typography className={classes.noContent} align='center'>
                    {content.noContent}
                </Typography>
            }
        </div>
    )
}

export default PreviousEditions

const styles_carousel = makeStyles(theme => ({
    root: {
        paddingTop: 64,
        paddingBottom: 64,
        width: '95%',
        height: '100%',
        margin: 'auto',
        transition: 'padding-top 0.2s ease-in',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            padding: 32,
            boxSizing: 'border-box',
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    current: {
        paddingTop: 0,
        transition: 'padding-top 0.2s ease-in',
    },
    cardActionArea: {
        //pointerEvents: 'none',
        position: 'relative',
        borderRadius: 25,
    },
    cardContent: {
        display: 'none',
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        padding: 32,
        boxSizing: 'border-box'
    },
    showCardContent: {
        display: 'block'
    },
    media: {
        width: '100%',
        paddingTop: "calc(100% * (3 / 2))",
        borderRadius: 5,
        display: 'block',

        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    title: {
        fontSize: 32,
        fontWeight: 600,
        marginBottom: 0
    },
    description: {
        color: grey[400],
        fontSize: 15,
        fontWeight: 400,
        letterSpacing: '0.025em'
    },
}))

const CarouselItem = ({ current, title, created_at, web_image, mobile_image, content, onClick, url }) => {

    const classes = styles_carousel()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [hover, setHover] = useState(false)

    const image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : content.noImage

    return (
        <div
            className={cx({
                [classes.root]: true,
                [classes.current]: !mobile && current
            })}
        >
            <Card className={classes.root}>
                <CardActionArea
                    className={classes.cardActionArea}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}>
                    <CardMedia
                        className={classes.media}
                        image={selectedImage}
                        title={"Magazine Individuals"}
                    />
                    <CardContent className={cx({
                        [classes.cardContent]: true,
                        [classes.showCardContent]: hover
                    })}>
                        <Grid container justifyContent='center' alignItems='center'>
                            <Grid item xs={12} sm={6}>
                                <RoundedButton
                                    fullWidth
                                    color='transparent_white_square'
                                    onClick={() => onClick(url)}
                                >
                                    {content.open}
                                </RoundedButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
                <CardActions style={{ background: 'transparent', padding: 0, paddingTop: 10 }}>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item>
                            <Typography gutterBottom className={classes.title}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.description}>
                                {onGetDateFormat(created_at, 'DD-MMM-YYYY')}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </div >
    )
}