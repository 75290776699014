import React, { useState } from "react"
import { Avatar, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from "@material-ui/styles"
import cx from 'classnames'
import { useEffect } from "react"
import { CONFIG_SETTINGS } from "../../../../config"
import { isValidDocument } from "../../../../shared/utility"
import { grey } from "@material-ui/core/colors"

const styles = makeStyles(theme => ({
    root: {
        background: '#FCFCFC',
        paddingTop: 80,
        paddingBottom: 80,
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
    },
    titleGold: {
        color: theme.palette.secondary.main,
    },
    subtitle: {
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: '0.265em'
    },
    text: {
        fontSize: 14,
        //fontWeight: 600,
    },
    avatarImage: {
        width: 64,
        height: 64
    },
    nationality: {
        fontSize: 14,
        fontWeight: 600,
        color: grey[700],
        padding: '2px 24px 2px 24px',
        border: `1px solid ${grey[700]}`,
        borderRadius: 3
    },
}))

const Details = ({ history, masterclass, content }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))

    const [expandedPanels, setExpandedPanels] = useState([])

    useEffect(() => {
        let _expandedPanels = []

        _expandedPanels = content.textSections.map(section => {
            if (section.expanded)
                return section.key
        })

        _expandedPanels.push('artist')

        setExpandedPanels(_expandedPanels)
    }, [])

    let isValid = isValidDocument(masterclass.professional_name)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${masterclass.professional_name}` : null

    const handleChange = (panel) => (event, isExpanded) => {
        let _expandedPanels = [...expandedPanels]
        if (isExpanded)
            _expandedPanels.push(panel)
        else
            _expandedPanels.pop(panel)

        setExpandedPanels(_expandedPanels)
    }

    return (
        <MainContainer className={classes.root}>
            {content.textSections.map(section => {
                let expanded = expandedPanels.includes(section.key)

                return (
                    <Accordion defaultExpanded={section.expanded} onChange={handleChange(section.key)}>
                        <AccordionSummary
                            expandIcon={expanded ? <RemoveIcon color='secondary' /> : <AddIcon />}
                        >
                            <Typography className={cx({
                                [classes.title]: true,
                                [classes.titleGold]: expanded
                            })}>
                                {section.label}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <Typography className={classes.text} align='justify'>
                                {masterclass[section.key]}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
            <Accordion defaultExpanded={content.artist.expanded} onChange={handleChange(content.artist.key)}>
                <AccordionSummary
                    expandIcon={expandedPanels.includes(content.artist.key) ? <RemoveIcon color='secondary' /> : <AddIcon />}
                >
                    <Typography className={cx({
                        [classes.title]: true,
                        [classes.titleGold]: expandedPanels.includes(content.artist.key)
                    })}>
                        {content.artist.label}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container justifyContent='center' alignItems='center' spacing={2}>
                        <Grid item>
                            <Avatar src={selectedImage} className={classes.avatarImage} />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.title} align='justify'>
                                        {masterclass.professional_name ? masterclass.professional_name : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.subtitle} align='justify'>
                                        {masterclass.artist_discipline ? masterclass.artist_discipline : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom className={classes.nationality}>
                                {masterclass.country_abbreviation ? masterclass.country_abbreviation : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </MainContainer>
    )
}

export default Details

const Accordion = withStyles({
    root: {
        borderTop: '1px solid #C5C5C5',
        borderBottom: '1px solid #C5C5C5',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {
        background: '#FCFCFC',
    },
})(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
    root: {
        borderBottom: 'none',
        minHeight: 56,
        paddingLeft: 20,
        paddingRight: 20,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
            background: 'rgba(189, 139, 64, 0.07)',
        },
    },
}))(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        paddingTop: 0,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 32,
    },
}))(MuiAccordionDetails);