import React from "react"
import { Card, CardActionArea, CardContent, CardMedia, Divider, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from "@material-ui/styles"
import { grey } from "@material-ui/core/colors"
import PageContainer from "../../../../components/Structure/Layouts/PageContainer"
import { isValidDocument, onGetFullname } from "../../../../shared/utility"
import { CONFIG_SETTINGS, nodata } from "../../../../config"
import { catalogs } from "../../../../texts/eng/catalogs"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import GenericImage from "../../../../components/Structure/DisplayData/GenericImage"

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
        background: '#FCFCFC',
        //minHeight: '80vh',
        //paddingBottom:100,
    },
    content: {
        padding: 100,
        position: 'relative',
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 32,
            paddingRight: 32,
        }
    },
    center: {
        minWidth: 220,
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%,-50%)'
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    image: {
        width: '100%',
        height: 'auto',
        filter: 'brightness(50%)'
    },
    imageContainer: {
        position: 'relative'
    },
    noArtist: {
        fontSize: 16,
        fontWeight: 300
    },
}))

const Artists = ({ history, artists, content }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    const onArtistSelected = (artist) => {
        console.log(artist)
        if (artist.enabled === 1) history.push(`/artists/${artist.artist_url}`)
    }

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title} align='right'>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ padding: '0px 40px' }}>
                            <Grid container spacing={3}>
                                {artists && artists.length > 0 ?
                                    artists.map((artist, key) => (
                                        <Grid item xs={12} sm={6} key={`artists.exhibition${key}`}>
                                            <ArtistCard {...artist} history={history} onClick={() => onArtistSelected(artist)} />
                                        </Grid>
                                    ))
                                    :
                                    <Grid item xs={12}>
                                        <Typography className={classes.noArtist} align='center'>
                                            {content.noContent}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </Grid>

                </Grid>
            </MainContainer>
        </div>
    )
}

export default Artists

const stylesCard = makeStyles(theme => ({
    root: {
        maxWidth: 400,
        boxShadow: 'none',
        margin: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        }
    },
    media: {
        height: '100%',
        minHeight: 400,
    },
    artist: {
        fontSize: 24,
        fontWeight: 600,
        marginBottom: 0
    },
    carrer: {
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: '0.265em'
    },
    nationality: {
        marginTop: 8,
        fontSize: 14,
        fontWeight: 600,
        color: grey[700],
        padding: '2px 24px 2px 24px',
        border: `1px solid ${grey[700]}`,
        borderRadius: 3,
        display: 'inline-block'
    },
    container: {
        //padding:16
    }
}))

const ArtistCard = ({ artist_professional_image, artist_professional_name, artist_discipline_subject, 
    artist_enabled, country_abbreviation, discipline_subject, image, onClick }) => {

    const classes = stylesCard()
    const isValid = isValidDocument(image)

    return (
        <div style={{ cursor: 'pointer' }} onClick={artist_enabled === 1 ? onClick : null}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <GenericImage src={artist_professional_image} />
                </Grid>
                <Grid item xs={12} md={7}>
                    <div className={classes.container}>
                        <Typography variant={'h6'} style={{ fontWeight: 700 }}>
                            {artist_professional_name}
                        </Typography>
                        <Typography variant={'body1'} style={{ fontWeight: 500, fontSize: 20, color: '#595959', letterSpacing: '0.265em' }}>
                            {artist_discipline_subject}
                        </Typography>
                        <Typography gutterBottom className={classes.nationality}>
                            {country_abbreviation ? country_abbreviation : nodata}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>

    )
}