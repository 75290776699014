import React, { useState } from "react"
import { Divider, Grid, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from "@material-ui/styles"
import { grey } from "@material-ui/core/colors"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import cx from 'classnames'
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import GenericImage from "../../../../components/Structure/DisplayData/GenericImage"
import SwipableArtworks from "../../../../components/Structure/DisplayData/SwipableArtworks"

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80,
        marginBottom: 80,
        '&:hover': {

        }
        //paddingLeft: 100,
        //paddingRight: 100,
        //[theme.breakpoints.down('sm')]: {
        //    padding: 32
        //},
    },
    content: {
        paddingTop: 32
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    button: {
        background: theme.palette.secondary.main,
        color: 'white'
    }
}))

const Artwork = ({ artwork, content, page, onChangePage, onBuy, onOpenArtWork, onOpenNFT }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container alignItems='center' spacing={3}>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} align='right'>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>
                <div className={classes.content}>
                    <SwipableArtworks artwork={artwork} content={content} page={page} onChangePage={onChangePage} onBuy={onBuy} onOpenArtWork={onOpenArtWork} />
                </div>
            </MainContainer>
        </div>
    )
}

export default Artwork
