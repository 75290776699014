import { Grid, makeStyles, Typography } from "@material-ui/core"
import RoundedButton from "../../components/Actions/RoundedButton"

const CompletedView = ({ content, onClose }) => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.main_body}>
                <Typography className={classes.title}>
                    {content.title1} <span>{content.title2}</span>
                </Typography>
                <Typography className={classes.message}>
                    {content.message}
                </Typography>
            </div>
            <div className={classes.bottom}>
                <RoundedButton onClick={onClose}>{content.button}</RoundedButton>
            </div>
        </div >
    )
}

export default CompletedView

const useStyles = makeStyles(theme => ({
    root: {
        width:'100%',
        height: '100%',
        boxSizing:'border-box'
    },
    main_body: {
        padding: '100px 50px 130px',
        //height:'100%'
    },
    title: {
        fontSize:32,
        paddingBottom: 20,
        '& span': {
            color: theme.palette.secondary.main
        }
    },
    message: {
        fontWeight: 400,
        fontSize: 24,
        paddingBottom: 20,
        letterSpacing: '0.025em'
    },
    circle: {
        background: 'rgba(189, 139, 64, 0.23)',
        borderRadius: '50%',
        width: 70,
        height: 70,
        padding: 20
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    bottom: {
        width: '100%',
        bottom: 0,
        position: 'absolute',
        borderTop: '1px solid #5A5A5A',
        padding: '70px 0px',
        textAlign: 'center'
    }

}))