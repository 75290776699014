import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { onGetErrorMessage } from '../../../shared/utility'
import queryString from 'query-string'
import { private_server, public_server } from '../../../config'

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const useMagazinesView = ({ is_auth, history, onUpdateContactDrawerStatus, onUpdateMagazineReference }) => {

    const classes = styles()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [magazines, setMagazines] = useState([])
    const [new_releases, setNewReleases] = useState([])
    const [individuals, setIndividuals] = useState([])

    useEffect(() => {
        actions.initModule()
    }, [])

    const actions = {
        initModule: async () => {
            window.scrollTo(0, 0)
            try {
                setLoading(true)
                let params2send = { offset: 0, order: 1, order_by: 'id_magazine' }
                let filterArgs = {
                    AND: [
                        { field: "magazine_status_id", operator: "=", value: 4 },
                        { field: "magazine_type_id", operator: "=", value: 2 },
                    ]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                let params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`

                let _new_releases = []
                console.log(is_auth)
                if (is_auth)
                    _new_releases = await private_server.get(`/magazine/all/${params}`)
                else
                    _new_releases = await public_server.get(`/magazine/all/ns${params}`)

                //const _magazines = await request_magazines_info(params)
                setNewReleases([..._new_releases.data.data.magazines])

                filterArgs = {
                    AND: [
                        { field: "magazine_status_id", operator: "=", value: 4 },
                        { field: "magazine_type_id", operator: "=", value: 1 },
                    ]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
                const _individuals = await public_server.get(`/magazine/all/ns${params}`)
                setIndividuals([..._individuals.data.data.magazines])

                filterArgs = {
                    AND: [
                        { field: "magazine_status_id", operator: "=", value: 6 }
                    ]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
                const _magazines = await public_server.get(`/magazine/all/ns${params}`)
                setMagazines([..._magazines.data.data.magazines])

            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onOpenContactDrawer: async () => {
            await onUpdateContactDrawerStatus(true)
        },
        onShowCommunityPage: async () => {
            await onUpdateMagazineReference(true)
            history.push('/community')
        },
        onGetIndividuals: async (filterArgs) => {
            try {
                setSending(true)
                const params2send = { offset: 0, order: 0, order_by: 'id_magazine' }
                let params = null
                if (filterArgs)
                    params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
                else
                    params = `?${queryString.stringify(params2send)}`

                const _magazines = await public_server.get(`/magazine/all/ns${params}`)
                //const _magazines = await request_magazines_info(params)
                setIndividuals([..._magazines.data.data.magazines])
                setSending(false)
            } catch (error) {
                console.log(error)
                onGetErrorMessage(error)
            }
            setSending(false)
        },
        onGetPreviousEditions: async (filterArgs) => {
            try {
                setSending(true)
                const params2send = { offset: 0, order: 0, order_by: 'id_magazine' }
                let params = null
                if (filterArgs)
                    params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
                else
                    params = `?${queryString.stringify(params2send)}`

                const _magazines = await public_server.get(`/magazine/all/ns${params}`)
                //const _magazines = await request_magazines_info(params)
                setMagazines([..._magazines.data.data.magazines])
                setSending(false)
            } catch (error) {
                console.log(error)
                onGetErrorMessage(error)
            }
            setSending(false)
        },
        onSaveMagazine: async (magazineID) => {
            try {
                setSending(true)
                const data = { magazine_id: magazineID }
                console.log(data)
                await private_server.post(`savedmagazine/me`, data)

                let params2send = { offset: 0, order: 1, order_by: 'id_magazine' }
                let params = `?${queryString.stringify(params2send)}`
                const _magazines = await public_server.get(`/magazine/all/ns${params}`)
                setIndividuals([..._magazines.data.data.magazines])
                setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onGoToMagazineDetails: (url) => {
            history.push(`magazines/${url}`)
        }
    }


    return { loading, sending, success, error, actions, magazines, new_releases, individuals }
}

export default useMagazinesView