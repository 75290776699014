import React, { useEffect, useRef, useState } from "react"
import { Avatar, Box, Card, Divider, Grid, IconButton, ListItem, ListItemText, makeStyles, SvgIcon, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { FixedSizeList } from 'react-window'
import { CONFIG_SETTINGS } from "../../../../../config";
import { isValidDocument, onGetDateFormat } from "../../../../../shared/utility";
import { grey } from "@material-ui/core/colors";
import SVGIcon from "../../../../../components/Structure/DisplayData/SVGIcon";
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./ArchivedCarousel.css"
import classNames from "classnames"
import PageContainer from "../../../../../components/Structure/Layouts/PageContainer";
import MainContainer from "../../../../../components/Structure/Layouts/MainContainer";
import GenericImage from "../../../../../components/Structure/DisplayData/GenericImage";
import moment from "moment";
import RoundedButton from "../../../../../components/Actions/RoundedButton";

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 180,
        paddingBottom: 80,
        backgroundColor: '#FCFCFC',
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    noContent: {
        fontSize: 16,
        fontWeight: 300
    },
    image: {
        width: '100%',
        //height: '100%',
        paddingTop: '75%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 10,
    },
    avatarIcon: {
        borderRadius: 0,
        background: 'black'
    }
}))

const responsive = {
    mobile: {
        breakpoint: { max: 4000, min: 0 },
        items: 1
    }
};

const Archived = ({ exhibitions, images, content, filter, onFilter, onVisitExhibition }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.only('xs'))
    const isLG = useMediaQuery(theme.breakpoints.only('lg'))
    const isXL = useMediaQuery(theme.breakpoints.up('xl'))

    let _selectedImages = []
    let selectedImages = []

    if (images) {
        selectedImages = ismobile ? images.mobile : images.web
        _selectedImages = selectedImages.map(image => {
            const isValid = isValidDocument(image)
            const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : content.noImage
            return selectedImage
        })
    }
    const _images = _selectedImages.map((image, key) => <Avatar key={`image${key}`} src={image} className={classes.avatarIcon} />)

    const [nextSlide, setNextSlide] = useState(false)


    const CustomDot = ({ index, onClick, active }) => {

        const handleClick = async () => {
            setNextSlide(true)
            await onClick()
            setNextSlide(false)
        }

        return (
            <button
                onClick={(e) => {
                    handleClick()
                    e.preventDefault()
                }}
                className={classNames("custom-dot", {
                    "custom-dot--active": active
                })}
            >
                {React.Children.toArray(_images)[index]}
            </button>
        );
    };

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container justifyContent='center' spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title} align='right'>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {exhibitions && exhibitions.length > 0 ?
                        <>
                            {//POR AHORA NO SE MUESTRA (Para mostrar quitar el false)
                                false && filter &&
                                <Grid item xs={12}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs>
                                            ALL
                                        </Grid>
                                        <Grid item>
                                            {filter.featured ?
                                                <RoundedButton
                                                    color='secondary'
                                                    onClick={() => onFilter('featured', false)}
                                                >
                                                    {content.featured.label}
                                                </RoundedButton>
                                                :
                                                <RoundedButton
                                                    color='transparent_secondary'
                                                    onClick={() => onFilter('featured', true)}
                                                >
                                                    {content.featured.label}
                                                </RoundedButton>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {/*   LISTA DE EXHIBICIONES            */}
                            <Grid item xs={12} md={6}>
                                <ListExhibitions exhibitions={exhibitions} onClick={onVisitExhibition} featuredIcon={content.featured.icon} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Carousel
                                    responsive={responsive}
                                    autoPlay={!nextSlide}
                                    ssr
                                    infinite
                                    keyBoardControl
                                    centerMode={false}
                                    customTransition="transform 300ms ease-in-out"
                                    showDots
                                    arrows={false}
                                    renderDotsOutside
                                    dotListClass='carouselArchivedDotList'
                                    customDot={<CustomDot />}
                                >
                                    {selectedImages.map(image =>
                                        <GenericImage src={image} ratio={isXL ? '16/9' : (isLG ? '1/1' : '3/4')} />
                                    )}
                                </Carousel>
                            </Grid>
                        </>
                        :
                        <Grid item xs={12}>
                            <Typography className={classes.noContent} align='center'>
                                {content.noContent}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </MainContainer>
        </div >
    )
}

export default Archived

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 600,
        overflow: 'auto',
        boxSizing: 'border-box',
        position: 'relative'
        //maskImage: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(254,254,254,1) 28%, rgba(0,212,255,1) 100%)',

    },
    content: {
        width: '100%'
    },
    overlay: {
        top: 0,
        transform: 'translateY(500px)',
        zIndex: 1,
        width: '100%',
        height: 100,
        position: 'sticky',
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%, rgba(0,212,255,0) 100%)',
    },
    listContainer: {
        width: '100%',
        position: 'absolute',
        top: 0
    },
    listItem: {
        width: '100%',
        padding: 12,
        paddingLeft: 0,
        borderBottom: `1px solid ${grey[300]}`,
        boxSizing: 'border-box',
        display: 'inline-flex',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: grey[200]
        }
    },
    image: {
        width: 60,
        height: 60,
        paddingRight: 20
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        [theme.breakpoints.only('xs')]: {
            fontSize: 16
        }
    },
    date: {
        fontSize: 12,
        '& span': {
            fontSize: 18
        },
        [theme.breakpoints.only('xs')]: {
            '& span': {
                fontSize: 16
            },
        }
    },
    yearContainer: {
        borderLeft: `6px solid ${theme.palette.primary.main}`,
        paddingLeft: 10,
        marginTop: 30,
        marginBottom: 20
    },
    year: {
        fontSize: 18,
        fontWeight: 400,
        letterSpacing: '0.165em'
    }
}));

const ListExhibitions = ({ exhibitions, featuredIcon, onClick }) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.only('xs'))
    let newYear = 0

    return (
        <div className={classes.root}>
            {/*<div className={classes.overlay}></div>*/}
            <div className={classes.listContainer}>
                {exhibitions.map(exhibition => {

                    const { url, start_date, end_date, title, date_text, featured,
                        web_image, mobile_image } = exhibition
                    const image = ismobile ? mobile_image : web_image
                    const year = moment(start_date).year()
                    let flagYear = false

                    if (newYear !== year) {
                        newYear = year
                        flagYear = true
                    }


                    let startDate = start_date ? [onGetDateFormat(start_date, 'DD'), onGetDateFormat(start_date, 'MMM')] : ''
                    let endDate = end_date ? [onGetDateFormat(end_date, 'DD'), onGetDateFormat(end_date, 'MMM')] : ''

                    return (
                        <>
                            {flagYear &&
                                <div className={classes.yearContainer}>
                                    <Typography className={classes.year}>
                                        {year}
                                    </Typography>
                                </div>
                            }
                            <div className={classes.listItem} onClick={() => onClick(url)}>
                                <div className={classes.image}>
                                    <GenericImage src={image} ratio='1/1' borderRadius={5} />
                                </div>
                                <div className={classes.content}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent='space-between'>
                                                <Grid item>
                                                    <Typography className={classes.title}>
                                                        {title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    {featured && featured > 0 ?
                                                        <div className={classes.featuredTag}>
                                                            <SVGIcon src={featuredIcon} color={theme.palette.secondary.main} size={24} />
                                                        </div>
                                                        : null}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.date}>
                                                <span>{startDate[0]}</span> {startDate[1]} - <span>{endDate[0]}</span> {endDate[1]}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
        </div>
    )
}