import React from 'react';
import { translations as translations_esp } from '../../../texts/eng/pro/artworks-translations'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer'
import useArtworksView from './useArtworksView'
import { actionTypes } from '../../../store/actions'
import TitleContainer from '../../../components/Structure/Layouts/TitleContainer';
import ActionTopBar from '../../../components/Structure/Table/ActionTopBar';

const styles = makeStyles(theme => ({
    root: {
        padding: 80,
        paddingBottom: 0,
    },
}))

const ArtworksView = ({ history, is_auth, onUpdateContactDrawerStatus, onUpdateMagazineReference }) => {

    const classes = styles()

    const { loading, sending, success, error, view_data, actions} = useArtworksView({
            is_auth, history,
            onUpdateContactDrawerStatus, onUpdateMagazineReference
        })

    const content = translations_esp

    return (
        <LoadingContainer
            loading={loading}
            sending={sending}
            success={success}
            error={error}
            onCloseSuccess={actions.onCloseSuccess}
            onCloseError={actions.onCloseError}
        >
            <TitleContainer title={content.title}>
                <ActionTopBar filter={content.filter} filterValue={view_data.filterValue} />
            </TitleContainer>
        </LoadingContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        is_auth: state.is_auth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateContactDrawerStatus: (contact_drawer) => dispatch({ type: actionTypes.DRAWER_CONTACT, contact_drawer }),
        onUpdateMagazineReference: (magazine_reference) => dispatch({ type: actionTypes.REFERENCE_MAGAZINE, magazine_reference }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtworksView)