import React, { useState, useEffect } from 'react';
import { translations as translations_esp } from '../../../texts/eng/website/aboutus-translations'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import { onGetErrorMessage } from '../../../shared/utility';
import MainBanner from './sections/MainBanner';
import WhyUs from './sections/WhyUs';
import Motivation from './sections/Motivation';
import KuvikBanner from './sections/KuvikBanner';
import GameChangers from './sections/GameChangers';
import Alliances from './sections/Alliances';

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const AboutUsView = ({ user, history }) => {

    const classes = styles()

    const [loading, setLoading] = useState(false)

    const [homeData, setHomeData] = useState(null)

    const content = translations_esp

    useEffect(() => {
        const initModule = async () => {
            window.scrollTo(0, 0)
            try {
                setLoading(true)
               // const _homeData = await request_home_info()
                //setHomeData({ ...homeData })
            } catch (error) {
                console.log(error)
                onGetErrorMessage(error)
            }
            setLoading(false)
        }

        initModule()
    }, [])

    return loading ? <div className={classes.loadingContainer}><div className={classes.loading}><CircularProgress style={{ color: 'white' }} /></div></div> :
        <React.Fragment>
            <div className={classes.content}>
                <MainBanner history={history} content={content.main_info_section} />
                <WhyUs history={history} content={content.whyus_section} />
                <Motivation history={history} content={content.our_motivation_section} />
                <KuvikBanner history={history} content={content.kuvik_banner_section} />
                <GameChangers history={history} content={content.game_section} />
                <Alliances history={history} content={content.our_alliances_section} />
            </div>
        </React.Fragment>
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        cart: state.cart
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //onUpdateCart: (cart) => dispatch({ type: actionTypes.ECO_UPDATE_CART, cart })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUsView);