import React from 'react'
import { Switch, withStyles } from '@material-ui/core';
import SVGIcon from '../Structure/DisplayData/SVGIcon';

const IconSwitch = withStyles((theme) => ({
    root: {
        width: 55,
        height: 24,
        padding: 0,
        margin: theme.spacing(1),
        overflow: 'inherit'
    },
    switchBase: {
        padding: 1,
        top: '50%',
        left: '-10%',
        transform: 'translateY(-50%)',
        '&$checked': {
            transform: 'translate(30px, -50%)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: theme.palette.secondary.main,
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    input: {

    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, icon, ...props }) => {
    const _icon = (
        <SVGIcon
            src={icon}
            size={20}
            style={{
                background: '#999999',
                borderRadius: '100%',
                padding: 8
            }}
        />
    )

    const _iconChecked = (
        <SVGIcon
            src={icon}
            size={20}
            style={{
                background: '#6D1A12',
                borderRadius: '100%',
                padding: 8
            }}
        />
    )

    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
            icon={_icon}
            checkedIcon={_iconChecked}
        />
    );
});

export default IconSwitch