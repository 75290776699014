import React, { useState, useEffect } from 'react';
import { translations as translations_esp } from '../../../texts/eng/website/exhibitions-details-translations'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { onGetErrorMessage, onValidateURL } from '../../../shared/utility';
import MainBanner from './sections/MainBanner';
import Virtual from './sections/Virtual';
import Artwork from './sections/Artwork';
import Artists from './sections/Artists';
import Magazines from './sections/Magazines'
import VirtualMagazine from './sections/VirtualMagazine';
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer';
import { public_server } from '../../../config';
import queryString from 'query-string'

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const useExhibitionsDetails = ({ user, history, match, onUpdateArtworkData, onUpdateModalStatus }) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [exhibitionId, setExhibitionId] = useState(null)
    const [exhibition, setExhibition] = useState(null)
    const [artwork, setArtwork] = useState([])
    const [artists, setArtists] = useState(null)


    useEffect(() => {
        window.scrollTo(0, 0)
        actions.initModule()
    }, [])

    const actions = {
        initModule: async () => {

            const exhibitionUrl = match.params.url
            try {
                setLoading(true)

                const _exhibitionRequiest = await public_server.get(`/exhibition/url/ns?url=${exhibitionUrl}`)
                //console.log(_exhibitionRequiest.data.data.exhibition)
                setExhibition(_exhibitionRequiest.data.data.exhibition)
                setExhibitionId(_exhibitionRequiest.data.data.exhibition.id_exhibition)
                const exhibitionID = _exhibitionRequiest.data.data.exhibition.id_exhibition

                //ARTWORK
                let filterArgs = { AND: [{ field: "exhibition_id", operator: "=", value: exhibitionID }] }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))

                let params2send = { offset: 0, order: 1, order_by: 'order' }
                params2send = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
                const exhibition_artworks = await public_server.get(`exhibitionartwork/all/ns${params2send}`)
                setArtwork([...exhibition_artworks.data.data.exhibition_artworks])

                //ARTISTS
                const _artists = await public_server.get(`exhibitionartist/all/ns${params2send}`)
                setArtists([..._artists.data.data.exhibition_artists])

                console.log(_artists.data.data.exhibition_artists)

                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseError: () => setError(null),
        onOpenArtWork: async (data) => {
            let _data = {
                id_artwork: data.artwork_id,
                title: data.artwork,
                artist_professional_name: data.artwork_artist,
                country_abbreviation: data.country_abbreviation,
                date: data.artwork_date,
                technique: data.artwork_technique,
                dimensions: data.artwork_dimensions,
                description: data.artwork_description,
                price: data.artwork_price,
                nft: 0,
                sold: data.artwork_sold,
                web_image: data.artwork_web_image,
                mobile_image: data.artwork_mobile_image,
            }
            await onUpdateArtworkData(_data)
            await onUpdateModalStatus(true)
        },
    }

    const system = { loading, error }
    const view_data = { exhibition, artwork, artists }

    return { system, view_data, actions }
}

export default useExhibitionsDetails