import React, { useEffect, useState } from "react"
import { alpha, Button, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Icon, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { grey } from "@material-ui/core/colors"
import { useTheme } from "@material-ui/styles"
import { CONFIG_SETTINGS } from "../../../../config"
import { isValidDocument } from "../../../../shared/utility"
import { catalogs } from "../../../../texts/eng/catalogs"
import PageContainer from "../../../../components/Structure/Layouts/PageContainer"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import GenericImage from "../../../../components/Structure/DisplayData/GenericImage"


const Artists = ({ history, artists, content, onArtistSelected }) => {

    const classes = styles()
    const theme = useTheme()
    const [seeMore, setSeeMore] = useState(false)

    const more = artists.length > 6
    const _artists = (!seeMore && more) ? artists.slice(0, 6) : artists

    return (
        <MainContainer style={{ marginTop: 100 }}>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <Grid container alignItems='center' spacing={3}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title} align='right'>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                {_artists.length > 0 ?
                    _artists.map(artist => (
                        <Grid item xs={12} sm={6} md={4}>
                            <ArtistCard {...artist} history={history} noImage={content.noImage}
                                featuredIcon={content.featuredIcon} onItemSelected={onArtistSelected} />
                        </Grid>
                    ))
                    :
                    <Grid item xs={12}>
                        <Typography className={classes.noArtist} align='center'>
                            {content.noContent}
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12}>
                    <IconButton onClick={() => setSeeMore(prev => !prev)} className={classes.button}>
                        <div className={classes.button}>
                            {seeMore ?
                                <SVGIcon
                                    size={54}
                                    src={content.moreIcon}
                                    colorStroke={theme.palette.secondary.main}
                                    style={{ margin: 'auto', transform: 'rotate(180deg)' }}
                                />
                                :
                                <SVGIcon
                                    size={54}
                                    src={content.moreIcon}
                                    colorStroke={theme.palette.secondary.main}
                                    style={{ margin: 'auto' }}
                                />
                            }
                            <Typography className={classes.more} align='center'>
                                {seeMore ? content.see.less : content.see.more}
                            </Typography>
                        </div>
                    </IconButton>
                </Grid>
            </Grid>
        </MainContainer>
    )
}

const styles = makeStyles(theme => ({
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    noArtist: {
        fontSize: 16,
        fontWeight: 300
    },
    carouselItem: {
        height: 650,
        marginTop: -100,
        '& li': {
            margin: 10,
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                padding: 10,
                boxSizing: 'border-box'
            }
        },
        '& ul.react-multi-carousel-dot-list': {
            top: 0,
            marginTop: 600,
            height: 'fit-content',
        }
    },
    lineButton: {
        width: 120,
        height: 1.5,
        borderRadius: 0,
        //marginLeft: 10,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    button: {
        display: 'block',
        margin: 'auto',
        borderRadius: 12
    },
    more: {
        color: theme.palette.secondary.main,
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: '0.525em'
    },
}))

export default Artists



const ArtistCard = ({ id_user, url, professional_name, country_abbreviation, discipline_subject,
    professional_image, featured, featuredIcon, onItemSelected }) => {

    const classes = useArtistCardStyles()
    const theme = useTheme()

    return (
        <div className={classes.root} onClick={() => onItemSelected(url)}>
            <div className={classes.artist_card} key={id_user.toString()} >
                <GenericImage src={professional_image} ratio='3/4' />
                {featured && featured > 0 ?
                    <div className={classes.featuredTag}>
                        <SVGIcon src={featuredIcon} color={theme.palette.secondary.main} size={42} />
                    </div>
                    : null}
            </div>
            <div style={{ marginTop: 16, padding: 4 }}>
                <Grid container alignItems='flex-start'>
                    <Grid item xs>
                        <Typography gutterBottom className={classes.artist}>
                            {professional_name ? professional_name : '-'}
                        </Typography>
                        <Typography className={classes.carrer}>
                            {discipline_subject ? discipline_subject : '-'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom className={classes.nationality}>
                            {country_abbreviation}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </div>

    )
}

const useArtistCardStyles = makeStyles(theme => ({
    artist_card: {
        background: 'black',
        width: "100%",
        borderRadius: 10,
        position: 'relative',
    },
    root: {
        fontFamily: theme.typography.fontFamily,
        cursor: 'pointer',
        borderRadius: 10,
        '&:hover': {
            background: alpha("#000", 0.03)
        }
    },
    artist: {
        fontSize: 24,
        fontWeight: 600,
        marginBottom: 0
    },
    carrer: {
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: '0.265em'
    },
    nationality: {
        fontSize: 14,
        fontWeight: 600,
        color: grey[700],
        padding: '2px 24px 2px 24px',
        border: `1px solid ${grey[700]}`,
        borderRadius: 3
    },
    featuredTag: {
        top: 0,
        position: 'absolute',
        right: 20
    }
}))