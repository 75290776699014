import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { AppBar, Card, CardContent, Dialog, Grid, IconButton, Slide, Toolbar, Typography } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { isFormValid, isValidDocument, onGetErrorMessage, onGetFileExtension, onGetFormData, onInitFiles, onInitForm, onSetErrorsToForm, onSetTokenSession, updateAuthorizationHeader } from "../../shared/utility";
import { CONFIG_SETTINGS, private_server, public_server } from "../../config";
import { generic_errors } from "../../texts/eng/genericErrors";
import { catalogs } from "../../texts/eng/catalogs";
import axios from "axios"

const useArtistRegisterDrawer = ({ language, user, content, onUpdateDrawerStatus,
    onUpdateProMode, onUpdateProfilePicture, onUpdateModalStatus, onUpdateUserData }) => {

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [files, setFiles] = useState(JSON.parse(JSON.stringify(filesData)))
    const [gallery, setGallery] = useState([])
    const [selected_document, setSelectedDocument] = useState(null)
    const [step, setStep] = useState(5)
    const [maxStep, setMaxStep] = useState(0)
    const finalStep = 5

    const [document_modal, setDocumentModal] = useState(false)

    const actions = {
        onInitModule: async () => {
            try {
                setLoading(true)
                //KUVIK PRO MODE
                await onUpdateProMode(true)

                let _form = {}
                let _files = {}

                content.steps.forEach(stepData => {
                    if (stepData.form) {
                        if (stepData.form.fixed) {
                            _form = { ..._form, ...onInitForm(form, null, stepData.form.optional) }
                            _form = { ..._form, ...onInitForm(form, null, stepData.form.fixed) }
                        }
                        _form = { ..._form, ...onInitForm(form, null, stepData.form) }
                    }
                    if (stepData.reference_code)
                        _form = { ..._form, ...onInitForm(form, null, { reference_code: { ...stepData.reference_code.form } }) }
                    if (stepData.files)
                        _files = { ...onInitFiles(files, stepData.files) }
                })

                let disciplines = await private_server.get('artist/disciplines')
                disciplines = disciplines.data.data.artist_disciplines
                disciplines = disciplines.map(discipline => {
                    return {
                        id: discipline.id_discipline,
                        value: discipline.id_discipline,
                        label: discipline.subject
                    }
                })

                _form.discipline_id.options = [...disciplines]
                setForm(_form)
                setFiles(_files)

                let _filter = {
                    AND: [
                        { field: "exhibition_status_id", operator: "=", value: 4 },
                        {
                            OR: [
                                { field: "exhibition_type_id", operator: "=", value: 2 },
                                { field: "exhibition_type_id", operator: "=", value: 3 },
                            ]
                        }
                    ]
                }
                _filter = encodeURIComponent(JSON.stringify(_filter))
                const response = await private_server.get(`/sampleartwork/all/me`)
                const _artworks = response.data.data.sample_artworks

                let _gallery = []

                _artworks.map(artwork => {
                    const _image = artwork ? artwork.image : null
                    const isValid = isValidDocument(_image)
                    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : null

                    _gallery.push({ id: artwork.id_sample_artwork, image: selectedImage })
                })
                setGallery(_gallery)
                //...catalogs.disciplines

                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onClose: async () => {
            await onUpdateProfilePicture(null)
            await onUpdateDrawerStatus(false)
        },
        onCloseError: () => setError(null),
        onCloseSuccess: () => setSuccess(false),
        onOpenDocumentModal: document => {
            setSelectedDocument(document)
            setDocumentModal(true)
        },
        onUpdateStep: (newStep) => {
            //PRIMERO REVISAMOS QUE ESTE BIEN LA INFO DEL PASO ACTUAL
            if (step !== 1) {
                let keys = Object.keys(content.steps[step].form)
                if (step === 0)
                    keys = [...Object.keys(content.steps[step].form.optional), ...Object.keys(content.steps[step].form.fixed)]
                console.log('onUpdateStep', keys)

                const errors = isFormValid(form, keys)
                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    return
                }
            }

            setStep(newStep)
        },
        onUpdateGallery: async (artworks) => {
            try {
                let _prevGallery = [...gallery]
                for (const artwork of artworks) {
                    if (_prevGallery.length < 12) {
                        const new_artwork = await actions.onSubmitArtwork(artwork.image)
                        _prevGallery.push(new_artwork)
                    }
                }
                //onSubmitArtwork
                setGallery(_prevGallery)
                setSending(false)
            } catch (error) {
                console.log(error)
            }
        },
        onSubmitArtwork: async (image) => {
            try {
                setSending(true)

                let response = await private_server.post('sampleartwork/me', { title: " " })
                const { id_sample_artwork } = response.data.data.sample_artwork

                const file2send = { name: "image", ext: onGetFileExtension(image.name) }

                const options = { headers: { 'Content-Type': image.type } }
                response = await private_server.post(`sampleartwork/${id_sample_artwork}/image/me`, file2send)
                await axios.put(response.data.data.url, image, options)

                //Obtenemos la imagen del registro en la base
                response = await private_server.get(`sampleartwork/${id_sample_artwork}/me`)
                const artwork = response.data.data.sample_artwork
                const _image = artwork ? artwork.image : null
                const isValid = isValidDocument(_image)
                const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : null

                setSuccess(true)
                setSending(false)

                return { id: id_sample_artwork, image: selectedImage }
            } catch (error) {
                setError(onGetErrorMessage(error))
            }

        },
        onDeleteImage: async (artwork) => {
            try {
                setSending(true)
                let _gallery = [...gallery]
                const index = _gallery.indexOf(artwork)

                if (index > -1) {
                    await private_server.delete(`sampleartwork/${artwork.id}/me`)
                    _gallery.splice(index, 1)
                }

                setGallery(_gallery)
                setSending(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
        },
        onChangeProfilePicture: async () => {
            await onUpdateModalStatus(true)
        },
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSelectArea: (id) => {
            form.area_id.value = id
            form.area_id.isVisited = true
            form.area_id.isValid = true
            setStep(1)
        },
        onNextStep: () => {

            //PRIMERO REVISAMOS QUE ESTE BIEN LA INFO DEL PASO ACTUAL
            if (step !== 1) {
                let keys = Object.keys(content.steps[step].form)
                if (step === 0)
                    keys = [...Object.keys(content.steps[step].form.optional), ...Object.keys(content.steps[step].form.fixed)]
                console.log(keys)
                const errors = isFormValid(form, keys)
                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    return
                }
            }

            if (step + 1 === finalStep)
                actions.onSubmit()
            else {
                setStep(prevVal => prevVal + 1)
                setMaxStep(prevVal => prevVal + 1)
            }
        },
        onCloseDocumentModal: () => {
            setSelectedDocument(null)
            setDocumentModal(false)
        },
        onUpdateDocument: async (file) => {
            try {
                if (!file) {
                    setError('A document must be attached')
                    return
                }

                setSending(true)
                const file2send = { name: selected_document.key, ext: onGetFileExtension(file.name) }
                if (user.user_type_id === 3) {
                    const options = { headers: { 'Content-Type': file.type } }
                    const response = await private_server.post(`artist/me/file`, file2send)
                    await axios.put(response.data.data.url, file, options)
                } else
                    setError('This user is not an artist')
                setDocumentModal(false)
                setSuccess(true)
                setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onSubmit: async () => {
            // Clean data
            
            const data2send = onGetFormData(form)
            data2send['artist_status_id'] = 1

            try {
                setSending(true)
                await private_server.patch('artist/me', data2send)


                setError(null)
                setSuccess(true)
                setSending(false)
                setStep(prevVal => prevVal + 1)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        }
    }

    return {
        loading, sending, success, error, document_modal, form, files, selected_document,
        maxStep, step, gallery, actions
    }
}

export default useArtistRegisterDrawer

const filesData = {
    cv: { key: 'cv', path: 'cv', status: false },
    portfolio: { key: 'portfolio', path: 'portfolio', status: false },
    other: { key: 'other_documents', path: 'other', status: false },
}

const formData = {
    area_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'area_id',
            type: 'number',
            fullWidth: true,
            placeholder: 'Area'
        },
        rules: {
            type: 'number',
        }
    },
    gender_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [...catalogs.artist_genders],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'number',
        }
    },
    first_name: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    last_name: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    discipline_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [],
        config: {
            id: 'discipline_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    country_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [...catalogs.countries],
        config: {
            id: 'country_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    birth_date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    country_code: {
        value: '+49',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options: [...catalogs.country_codes],
        config: {
            id: 'country_code',
            type: 'select',
            fullWidth: true,
        },
        rules: { type: 'select' }
    },
    /*
     email: {
         value: null,
         error: false,
         isVisited: false,
         isRequired: true,
         isValid: false,
         config: {
             id: 'email',
             type: 'text',
             fullWidth: true,
         },
         rules: {
             type: 'email',
         }
     },
     */
    mobile: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'phone_number'
        }
    },
    url: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    reference_code: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'reference_code',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    motivational_tweet: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'motivational_tweet',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 140
        }
    },
    biography: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'biography',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'words',
            min: 1,
            max: 250
        }
    },
    statement: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'statement',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'words',
            min: 1,
            max: 200
        }
    },
    sponsorship: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.artist_sponsorship],
        config: {
            id: 'sponsorship',
            type: 'radio',
            fullWidth: true,
        },
        rules: { type: 'radio' }
    },
    choosing_reason: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'choosing_reason',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'words',
            min: 1,
            max: 200
        }
    },
    professional_name: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'professional_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    facebook: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'facebook',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    twitter: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'twitter',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    instagram: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'instagram',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    tiktok: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'tiktok',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    pinterest: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'pinterest',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    youtube: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'youtube',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    vimeo: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'vimeo',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    opensea: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'opensea',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    web: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'web',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
}