import React, { useState, useEffect } from 'react';
import { makeStyles, Fade, useTheme } from '@material-ui/core';
import noimage from '../../../assets/noimage.png'
import { CONFIG_SETTINGS } from '../../../config';
import cx from 'classnames'

const image_ratio = {
    '1/1': '100%',
    '16/9': '56.25%',
    '4/3': '75%',
    '3/4': '133%',
}

const GenericImage = ({ src, size, noborder, ratio, noImagePadding, lowBrightness, borderRadius, style, fullpath, objectFit }) => {

    const classes = useStyles()
    const [error, setError] = useState(true)

    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)

    let imgSrc = src ? `${CONFIG_SETTINGS.S3_SERVER_URL}${src}` : null
    if(fullpath) imgSrc = src
    const _ratio = ratio ? ratio : '1/1'

    let image_height = image_ratio[_ratio]

    return (
        <div className={classes.image_container} style={{ paddingTop: image_height, borderRadius: borderRadius, filter: lowBrightness ? 'brightness(0.5)' : 'none', ...style }}>
            {error ? <div className={classes.image_wrapper} style={{ padding: 80 }} ><img src={noimage} alt='' className={classes.image} style={{ objectFit: 'contain', }} /></div> : null}
            <Fade in={!error}>
                <div className={classes.image_wrapper} style={{borderRadius: borderRadius}}>
                    <img
                        alt='' src={imgSrc}
                        className={cx({
                            [classes.image]: true,
                            [classes.imageH]: lowBrightness,
                            [classes.imageContain]: objectFit === 'contain'
                        })}
                        style={{borderRadius: borderRadius}}
                        onLoad={onImageLoad}
                        onError={onError}
                    />
                </div>

            </Fade>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    image_container: {
        position: 'relative',
        margin: 'auto',
        width: '100%',
        paddingTop: '100%',
        boxSizing: 'border-box',
        borderRadius: 10,
        height: '100%',
        //background:'white'
    },
    image_wrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        borderRadius: 10
    },
    imageH: {
        filter: 'brightness(0.5)'
    },
    imageContain:{
        background:'black',
        objectFit: 'contain',
    },
    svg_container: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: 8,
        boxSizing: 'border-box'
    }
}))

export default GenericImage;