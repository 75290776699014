import React from 'react'
import { MenuItem, TextField, withStyles } from '@material-ui/core'
import { ruleValidation } from './customFunctions'

const CssTextField = withStyles(theme => ({
  root: {
    color: 'white',
    borderBottom: `0.5px solid #6f6c6c`,
    '& label': {
      width: '100%'
    },
    '& label.Mui-focused': {
      //color: theme.palette.secondary.main,
      color: 'white'
    },
    '& .MuiFormLabel-root': {
      color: '#6f6c6c', 
    },
    '& .MuiInput-underline:before': {
      borderBottom: `0.5px solid #6f6c6c`
    },
    '& .MuiInput-underline:after': {
      borderBottom: `0.5px solid ${theme.palette.secondary.main}`,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 12,
    },
    '& .MuiInputBase-input': {
      color: 'white',
    }
  },
}), { withTheme: true })(TextField);

const LineSelectFormLight = props => {

  const { data, onChange, startAdornment, endAdornment, variant } = props

  const onInnerChange = event => {
    const response = event.target.value;
    let temp = { ...data };
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

  const { config, value, isValid, isVisited, isRequired, options } = data

  const isError = (isVisited && !isValid) || (isVisited && isRequired && !isValid)
  let interConfig = { ...config }
  if (!isError) interConfig.helperText = ''

  const _options = options ? options : []

  return (
    <div>
      <CssTextField value={value} variant={variant ? variant : 'standard'}
        error={isError} {...interConfig} select onChange={onInnerChange}
        InputProps={
          {
            startAdornment: startAdornment ? startAdornment : undefined,
            endAdornment: endAdornment ? endAdornment : undefined,
          }}
      >
        {_options.map(item => {
          return (
            <MenuItem key={item.toString()} value={item}>
              {item}
            </MenuItem>
          )
        })}
      </CssTextField>
    </div>
  )
}

export default LineSelectFormLight