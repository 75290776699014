import React, { useEffect, useRef, useState } from "react"
import { useTheme } from "@material-ui/styles"
import { Divider, Grid, Icon, makeStyles, MobileStepper, Typography } from '@material-ui/core'
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import SVGIcon from "../../../../../components/Structure/DisplayData/SVGIcon"
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm } from "../../../../../shared/utility"
import cx from 'classnames'
import Step1 from "./Steps/FormStepper"
import Step2 from "./Steps/Step2"
import Joined from "./Steps/Joined"
import moment from "moment"
import LoadingContainer from "../../../../../components/Structure/Layouts/LoadingContainer"
import MainContainer from "../../../../../components/Structure/Layouts/MainContainer"
import useWelcome from "./useWelcome"
import { grey } from "@material-ui/core/colors"
import InputForm from "../../../../../components/Forms/InputFormW"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FormStepper from "./Steps/FormStepper"

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
        paddingBottom: 20,
        margin: 'auto',
        boxSizing: 'border-box',
        //minHeight: '50vh',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: '100%'
        }
    },
    content: {
        //border:'1px solid black',
        //padding: 150,
        [theme.breakpoints.only('xs')]: {
            //paddingLeft: 32,
            //paddingRight: 32,
        }
    },
    title: {
        //fontSize: 48,
        fontWeight: 600,
        '@global span': {
            color: theme.palette.secondary.main,
            fontWeight: 700,
        }
    },
    subtitle: {
        //fontSize: 30,
        fontWeight: 400
    },
    white: {
        color: 'white'
    },
    stepper: {
        width: 250,
        //marginTop: 50,
        margin: 'auto',
        bottom: 0,
        //  top: '80%',
        background: 'transparent',
    },
    stepperButton: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    input: {
        backgroundColor: '#F4F4F4',
        padding: '0px 24px 10px 24px',
        borderRadius: 10
    },
    backgroundImages: {
        width: 0,
        height: 0,
        opacity: 0,
        transition: 'width 0s, height 0s, opacity 0.5s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            height: '100%'
        }
    },
    backgroundImagesShow: {
        position: 'absolute',
        top: 0,
        zIndex: -1,
        width: '100%',
        height: '100%',
        opacity: 1
    },
    imageBG: {
        width: '33.3%',
        height: '100%',
        objectFit: 'cover',
        filter: 'brightness(0.3)'
    },
    stepContainer: {
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    step: {
        fontSize: 220,
        fontWeight: 600,
        color: grey[300],
        position: 'absolute',
        transform: 'translate(100%, -55%)',
        //transform: 'translateY(-75%)',
    },
    formContent: {
        //marginTop: -100,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
}))

const Welcome = ({ language, history, content }) => {

    const classes = styles()
    const theme = useTheme()

    const { system, view_data, actions } = useWelcome({ language, history, content })

    let formContent = null

    if (view_data.step < view_data.maxStep - 1)
        formContent = <FormStepper step={view_data.step} history={history} checked form={view_data.form} onChange={actions.onChange} content={content.stepper.steps[view_data.step]} handleStepper={actions.handleStepper} />
    if (view_data.step === view_data.maxStep - 1)
        formContent = <Joined history={history} checked content={content.stepper.steps[2]} />

    let current = content.stepper.steps[view_data.step]

    return (
        <LoadingContainer loading={system.loading} sending={system.sending} success={system.success} error={system.error} onCloseError={actions.onCloseError} onCloseSuccess={actions.onCloseSuccess}>
            <div className={classes.root}>

                <div className={cx({
                    [classes.backgroundImages]: true,
                    [classes.backgroundImagesShow]: view_data.step === view_data.maxStep - 1
                }
                )}>
                    <img src={content.stepper.steps[2].imagesBG[0]} className={classes.imageBG} />
                    {/*style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10, }} />*/}
                    <img src={content.stepper.steps[2].imagesBG[1]} className={classes.imageBG} />
                    <img src={content.stepper.steps[2].imagesBG[2]} className={classes.imageBG} />
                    {/*style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, }} />*/}
                </div>

                <MainContainer>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='h4' className={cx({ [classes.title]: true, [classes.white]: current.white })}>
                                        {current.title[0]} <span>{current.title[1]}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='h6' className={cx({ [classes.subtitle]: true, [classes.white]: current.white })}>
                                        {current.subtitle}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.form}>
                                <div className={classes.formContent}>
                                    <Grid container justifyContent='space-between' alignItems='center'>
                                        <Grid item xs={12} lg={8}>
                                            {formContent}
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <div className={classes.stepContainer}>
                                                <Typography className={classes.step} align='center'>{view_data.step + 1}</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <MobileStepper
                                    steps={view_data.maxStep}
                                    position="static"
                                    variant="text"
                                    activeStep={view_data.step}
                                    classes={{ root: classes.stepper }}
                                    style={{ color: current.white ? 'white' : '' }}
                                    nextButton={
                                        <div className={classes.stepperButton} onClick={() => actions.handleStepper(true)}>
                                            <SVGIcon
                                                src={content.stepper.ctrl_button}
                                                size={80}
                                                color={view_data.step === view_data.maxStep - 1 ? 'grey' : (current.white ? 'white' : theme.palette.secondary.main)}
                                                style={{ transform: 'rotate(270deg)', textAlign: 'center' }}
                                            />
                                        </div>
                                    }
                                    backButton={
                                        <div className={classes.stepperButton} onClick={() => actions.handleStepper(false)}>
                                            <SVGIcon
                                                src={content.stepper.ctrl_button}
                                                size={80}
                                                color={view_data.step === 0 ? 'grey' : theme.palette.secondary.main}
                                                style={{ transform: 'rotate(90deg)', textAlign: 'center' }}
                                            />
                                        </div>
                                    }
                                />
                            </div>
                        </Grid>
                    </Grid >
                </MainContainer>
            </div >
        </LoadingContainer>
    )
}


export default Welcome
