import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { grey } from '@material-ui/core/colors'

const styles = makeStyles(theme => ({
    root: {
        //paddingTop: 100,
        //paddingBottom: 100,
        padding: 80,
        margin: 'auto',
        boxSizing: 'border-box',
        //[theme.breakpoints.up('xl')]: {
        //    width: 1600
        //},
        //[theme.breakpoints.down('lg')]: {
        //    width: 1200,
        //    //margin:'auto'
        //},
        //[theme.breakpoints.down('md')]: {
        //    padding: 100,
        //    width: '100%'
        //},
        [theme.breakpoints.only('xs')]: {
            padding: 32
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
        textTransform: 'capitalize',
        color: grey[800],
        letterSpacing: '0.065em',
    },
}))

const TitleContainer = props => {

    const classes = styles()

    return (
        <div className={classNames(classes.root, props.className)} style={props.style}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {props.children}
                </Grid>
            </Grid>
        </div>
    )
}

export default TitleContainer