import React, { useState } from 'react';
import { AppBar, Divider, SwipeableDrawer, Grid, IconButton, List, ListItem, ListItemText, makeStyles, Toolbar, useMediaQuery, useScrollTrigger, useTheme, Collapse, Avatar, Popover, Popper, Paper, Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import general_translations from '../../../texts/eng/website/general-translations'
import cx from 'classnames'
import { CONFIG_SETTINGS, DASHBOARD_SETTINGS } from '../../../config';
import { actionTypes } from '../../../store/actions';
import { isValidDocument, onClearMemory, onGetErrorMessage } from '../../../shared/utility';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import RoundedButton from '../../../components/Actions/RoundedButton';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon';
import { ExpandLess } from '@material-ui/icons';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: 'auto',
        boxSizing: 'border-box',
        position: 'relative',
        padding: '16px 45px',
    },
    button: {
        background: 'white',
        color: 'black',
        '&:hover': {
            background: 'white',
        }
    },
    appbar: {
        background: 'white',
        boxShadow: 'none',
        borderBottom: `1px solid ${grey[200]}`,
        //boxShadow: DASHBOARD_SETTINGS.BOXSHADOW,
        //height: 76,
        color: 'grey',
    },
    appbar_mono: {
        background: 'transparent',
        boxShadow: 'none',
        color: 'grey',
        borderBottom: `1px solid transparent`,
    },
    drawer: {
        color: 'white',
        backgroundColor: 'black'
    },
    list: {
        width: 250,
    },
    logoContainer: {
        position: 'relative'
    },
    logo: {
        width: 45,
        height: 'auto',
        //position: 'fixed',
        //left: 70,
        '&:hover': {
            cursor: 'pointer'
        },
    },
    searchbar: {
        position: 'absolute',
        top: 24,
        left: '50%',
        transform: 'translateX(-50%)'
    },
    subtitle: {
        paddingLeft: theme.spacing(4),
        background: grey[300],
        color: 'black'
    },
    login_button: {
        background: theme.palette.primary.main,
        color: 'white', textTransform: 'none',
        fontFamily: theme.typography.fontFamily,
        fontSize: 15,
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    }
}))

const Topbar = ({ user, history, is_auth, login_drawer, onItemSelected, onUpdateContactModal }) => {
    const classes = useStyles()
    const theme = useTheme()
    
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 100 })
    
    const [menu_drawer, setMenuDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const openMenu = Boolean(anchorEl)
    const content = general_translations.topbar
    const isValid = user && isValidDocument(user.image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${user.image}` : content.noImage
    
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClick = () => {
        setOpen(!open)
    }

    const onClickItemMenu = (url) => {
        if (url) {
            history.push(url)
            setMenuDrawer(false)
        } else
            handleClick()
    }

    const current_path = history.location.pathname

    const isTransparent = content.transparent.includes(current_path) || current_path.includes('/artists/') || current_path.includes('/exhibitions/')
    //console.log(isTransparent)

    let _trigger = trigger || !isTransparent

    const profileMenu = (
        <div>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <Avatar src={selectedImage} />
            </IconButton>
            <Menu
                id="menu-appbar"
                keepMounted
                getContentAnchorEl={null}
                elevation={0}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={openMenu}
                onClose={handleClose}
            >
                {content.profile_menu.map(item => (
                    <MenuItem onClick={() => onItemSelected(item.url)}>{item.label}</MenuItem>
                ))}
            </Menu>
        </div>
    )

    const desktopView = (
        <Grid container alignItems='center'>
            <Grid item >
                {
                    !login_drawer &&
                    <div className={classes.logoContainer}>
                        <img
                            src={_trigger ? content.logo_alt : content.logo}
                            className={classes.logo}
                            onClick={() => onClickItemMenu('/')}
                        />
                    </div>
                }
            </Grid>
            <Grid item xs>

                <Grid container justifyContent='flex-end' alignItems='center' spacing={4} >
                    <Grid item>
                        <Grid container justifyContent='center' alignItems='center' spacing={2}>
                            {content.titles.map((link, key) =>
                                <Grid item key={`menu-${key}`}>
                                    <LinkItem
                                        dark={!_trigger}
                                        history={history}
                                        selected={current_path === link.url}
                                        {...link}
                                    />
                                </Grid>
                            )}
                            <Grid item onClick={() => onUpdateContactModal(true)}>
                                <LinkItem
                                    dark={!_trigger}
                                    history={history}
                                    {...content.contact}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item>
                                {is_auth ? profileMenu :
                                    <Button size='small' onClick={() => onItemSelected(content.login_button.url)} className={classes.login_button}>
                                        {content.login_button.label}
                                    </Button>
                                }
                            </Grid>
                            {/*<Grid item>
                                <SVGIcon src={content.search} size={24} color={_trigger ? 'black' : 'white'} />
                            </Grid>*/}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    const mobileView = (
        <div>
            <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => setMenuDrawer(true)}
            >
                <MenuIcon />
            </IconButton>
            <SwipeableDrawer
                classes={{ paperAnchorLeft: classes.drawer }}
                anchor={'left'}
                open={menu_drawer}
                onClose={() => setMenuDrawer(false)}
            >
                <div
                    className={classes.list}
                    role="presentation"
                    onKeyDown={() => setMenuDrawer(false)}
                >
                    <List>
                        <ListItem button onClick={() => onClickItemMenu('/')}>
                            <img src={content.logo_alt} />
                        </ListItem>
                    </List>
                    <Divider style={{ background: '#aaa' }} />
                    {content.titles.map((item, key) => (
                        <React.Fragment key={`navfrag-${key}`}>
                            <List key={`navlist-${key}`} style={open ? { paddingBottom: 0 } : null}>
                                <ListItem
                                    button
                                    key={`nav-${key}`}
                                    onClick={() => onClickItemMenu(item.url)}
                                >
                                    <ListItemText primary={item.title} />
                                    {!item.url && (open ? <ExpandLess /> : <ExpandMore />)}
                                </ListItem>
                                {item.subtitles && item.subtitles.map((subtitle, key) => (
                                    <React.Fragment key={`navfrag-${key}`}>
                                        <Collapse in={open} timeout="auto" unmountOnExit className={classes.subtitle}>
                                            <List component="div" disablePadding>
                                                <Divider key={`nav_div-${key}`} style={{ background: '#aaa' }} />
                                                <List key={`navlist-${key}`}>
                                                    <ListItem button key={`nav-${key}`} onClick={() => onClickItemMenu(subtitle.url)}>
                                                        <ListItemText primary={subtitle.title} />
                                                    </ListItem>
                                                </List>
                                            </List>
                                        </Collapse>

                                    </React.Fragment>
                                ))}
                            </List>
                            <Divider key={`nav_div-${key}`} style={{ background: '#aaa' }} />
                        </React.Fragment>
                    ))}
                    <List>
                        <ListItem>
                            {is_auth ? profileMenu :
                                <Button size='small' onClick={() => onItemSelected('/login')}>
                                    {content.login_button.label}
                                </Button>
                            }
                        </ListItem>
                    </List>
                </div>

            </SwipeableDrawer>
        </div>
    )

    return (
        <AppBar className={cx({
            [classes.appbar]: true,
            [classes.appbar_mono]: !_trigger
        })}>
            <Toolbar className={classes.root}  >
                {ismobile ? mobileView : desktopView}
            </Toolbar>
        </AppBar>
    );
}




const mapStateToProps = state => {
    return {
        user: state.user,
        is_auth: state.is_auth,
        login_drawer: state.login_drawer,
        signup_drawer: state.signup_drawer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
        onUpdateContactModal: (contact_drawer) => dispatch({ type: actionTypes.DRAWER_CONTACT, contact_drawer }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));


const LinkItem = ({ selected, dark, history, url, title, subtitles }) => {

    const classes = useLinkItemStyles()
    const [anchorEl, setAnchorEl] = useState(null)

    const onShowMenu = (event) => setAnchorEl(event.currentTarget)
    const onCloseMenu = () => setAnchorEl(null)

    return (
        <div className={cx({
            [classes.root]: true,
            [classes.root_dark]: dark,
            [classes.root_selected]: selected,
        })}   >
            <div onMouseEnter={subtitles ? onShowMenu : undefined} onMouseLeave={subtitles ? onCloseMenu : undefined}>
                <a className={classes.link} href={url} >
                    {title}
                </a>
                {subtitles ? <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom' style={{ zIndex: 9000 }} >
                    <div className={cx({
                        [classes.menu]: true,
                        [classes.menu_dark]: dark,
                    })}>
                        {subtitles.map(item => {
                            return (
                                <div className={cx({
                                    [classes.sublink]: true,
                                    [classes.sublink_dark]: dark
                                })} key={item.title} >
                                    <a className={classes.link} href={item.url} >
                                        {item.title}
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                </Popper> : null}
            </div>
        </div>
    )
}

const useLinkItemStyles = makeStyles(theme => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        color: "#151515",
        cursor: 'pointer',
        padding: '6px 0px',
        borderBottom: '2px solid transparent',
        transition: 'all 0.1s linear',
        '&:hover': {
            transition: 'all 0.3s ease',
            color: theme.palette.secondary.main
        }
    },
    root_dark: {
        color: "#FFF",
    },

    root_selected: {
        borderBottom: '2px solid #151515',
    },
    link: {
        textDecoration: 'none',
        color: "inherit",
        fontSize: 15
    },
    paper: {
        background: 'transparent',
        boxShadow: 'none',
        marginTop: 36
    },
    menu: {
        display: 'flex',
        background: 'white',
        marginTop: 16
    },
    menu_dark: {
        background: 'transparent'
    },
    sublink: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        fontSize: 15,
        width: 100,
        textAlign: 'center',
        color: "#151515",
        cursor: 'pointer',
        margin: '0px 4px',
        borderBottom: '2px solid transparent',
        transition: 'all 0.1s linear',
        '&:hover': {
            transition: 'all 0.3s ease',
            color: theme.palette.secondary.main
        }
    },
    sublink_dark: {
        color: 'white'
    }
}))


