import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import cx from 'classnames'

const styles = makeStyles(theme => ({
    card: {
        position: 'relative',
        witdth: '100%',
        height: 'auto',
        padding: 32,
        minHeight: 15,
        background: '#353535',
        borderRadius: '11px 11px 0px 0px'
    },
    cardTitle: {
        top: '50%',
        left: '50%',
        fontSize: 14,
        fontWeight: 600,
        position: 'absolute',
        textTransform: 'uppercase',
        transform: 'translate(-50%,-50%)',
        color: theme.palette.secondary.main,
        letterSpacing: '0.37em'
    },
    title: {
        fontSize: 32,
        fontWeight: 700,
        textTransform: 'uppercase',
        [theme.breakpoints.only('xs')]: {
            fontSize: 28
        }
    }
}))

const Step2 = ({ title, description, stepCards }) => {

    const classes = styles()

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            2. {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {description.map(text => (
                                <Grid item xs={12}>
                                    <Typography variant='body2' align='justify'>
                                        {text}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container justifyContent={'center'} alignItems='center' spacing={4}>
                    {stepCards.map((text, key) => (
                        <Grid item xs={12} key={`step2.${key}`}>
                            <div className={classes.card}>
                                <Typography className={classes.cardTitle} align='center'>
                                    {text}
                                </Typography>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Step2