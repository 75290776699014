import React from "react"
import { Button, Divider, Grid, Icon, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import { useTheme } from "@material-ui/styles"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import { grey } from "@material-ui/core/colors"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
        backgroundColor: 'black',
        color: 'white',
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    titleS: {
        fontSize: 32,
        fontWeight: 600,
        '& span': {
            color: theme.palette.secondary.main
        }
    },
    divider: {
        backgroundColor: grey[600]
    },
    imageContainer: {
        width: '100%',
        height: 'auto',
        maxHeight: 600,
        padding: 10,
        boxSizing: 'border-box',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            objectFit: 'cover',
        },
    },
    date: {
        fontSize: 18,
        fontWeight: 500,
        '& span': {
            fontSize: 24,
            fontWeight: 400,
        }
    },
    description: {
        color: '#A5A5A5',
        fontSize: 24,
        fontWeight: 600,
        lineHeight: '272.24%'
    },
    lineButton: {
        width: 120,
        height: 2,
        backgroundColor: grey[700],
        borderRadius: 0,
        marginLeft: 10,
        '&:hover': {
            cursor: 'pointer'
        }
    }
}))

const responsive = {
    tablet: {
        breakpoint: { max: 4000, min: 500 },
        items: 1,
        partialVisibilityGutter: 50
    },
    mobile: {
        breakpoint: { max: 500, min: 0 },
        items: 1,
        partialVisibilityGutter: 50
    },
}

const Motivation = ({ history, content }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const deviceType = mobile ? 'mobile' : 'tablet'

    const lineButtons = content.gallery.map(item => <div className={classes.lineButton}></div>)

    const CustomDot = ({ onClick, ...rest }) => {
        const {
            onMove,
            index,
            active,
        } = rest;

        return (
            <button
                style={{ border: 0, background: 'transparent' }}
                className={active ? "active" : "inactive"}
                onClick={() => onClick()}
            >
                {React.Children.toArray(lineButtons)[index]}
            </button>
        );
    };

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container justifyContent='center' spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title} align='right'>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6} lg={5}>
                                <Carousel
                                    arrows={false}
                                    responsive={responsive}
                                    swipeable={false}
                                    draggable={false}
                                    showDots
                                    enderDotsOutside
                                    ssr={true}
                                    infinite
                                    partialVisible={true}
                                    autoPlay
                                    //autoPlaySpeed={5000}
                                    keyBoardControl={true}
                                    customTransition="transform 300ms ease-in-out"
                                    transitionDuration={500}
                                    containerClass="carousel-container"
                                    deviceType={deviceType}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                    customDot={<CustomDot />}
                                >
                                    {content.gallery.map(image => <div className={classes.imageContainer}>
                                        <img src={image} className={classes.image} />
                                    </div>)}
                                </Carousel>
                            </Grid>
                            <Grid item xs={12} md={6} lg={7}>
                                <Grid container alignItems='stretch' spacing={4}>
                                    <Grid item>
                                        <Typography className={classes.titleS}>
                                            {content.titleS[0]} <span>{content.titleS[1]}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={5}>
                                            {content.description.map(text => (
                                                <Grid item xs={12}>
                                                    <Typography className={classes.description}>
                                                        {text}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>
    )
}

export default Motivation
