import React, { useEffect, useState } from "react"
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import { catalogs } from "../../../../texts/eng/catalogs"
import { isValidDocument, onGetErrorMessage, onInitForm } from "../../../../shared/utility"
import CheckboxForm from "../../../../components/Forms/CheckboxForm"
import LoadingContainer from "../../../../components/Structure/Layouts/LoadingContainer"
import { CONFIG_SETTINGS, public_server } from "../../../../config"
import StopIcon from '@material-ui/icons/Stop'
import StopOutlinedIcon from '@material-ui/icons/StopOutlined'
import cx from 'classnames'

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        letterSpacing: '0.065em',
        '& span': {
            color: theme.palette.secondary.main,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 20
        }
    },
    subtitle: {
        fontSize: 18,
        fontWeight: 400,
    },
    category: {
        borderRadius: 5,
        border: `1px solid ${theme.palette.secondary.main}`,
        padding: 12,
        paddingLeft: 20,
        paddingRight: 0,
        boxSizing: 'border-box'
    },
    categorySelected: {
        background: 'rgba(189, 139, 64, 0.17)',
        border: `1px solid rgba(189, 139, 64, 0.24)`,
    },
    categoryLabel: {
        fontSize: 20,
        fontWeight: 600,
        color: theme.palette.secondary.main
    },
    divider: {
        backgroundColor: grey[600]
    },
    results: {
        color: grey[600],
        fontSize: 20,
        fontWeight: 600
    },
    noClasses: {
        fontSize: 14,
        fontWeight: 300
    },
}))

const FindClasses = ({ history, masterclasses, content, onUpdateClasses }) => {

    const classes = styles()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    //const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [form, setForm] = useState({})

    /*  useEffect(() => {
          const init = async () => {
              try {
                  setLoading(true)
                  catalogs.masterclass_categories.map(category => {
                      let temp = { ...form }
                      temp[category.id].config = { ...temp[category.id].config, value: category.value }
                      setForm(temp)
                  })
              } catch (error) {
                  console.log(error)
                  setError(onGetErrorMessage(error))
              }
              setLoading(false)
          }
          init()
      }, [])*/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                let response = await public_server.get('masterclasscategory/all/ns')
                response = response.data.data.masterclass_categories

                let _form = {}

                response.map(category => {
                    _form[category.name] = {
                        value: false,
                        error: false,
                        isVisited: false,
                        label: category.name,
                        config: {
                            value: category.id_masterclass_category,
                            id: category.name,
                            type: 'checkbox'
                        }
                    }
                })

                setForm(_form)

            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }
        init()
    }, [])

    const onChange = async (data) => {
        try {
            setSending(true)
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
            await onUpdateClasses(temp)
            setSending(false)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setSending(false)
    }

    console.log(form)

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container justifyContent='center' spacing={8}>
                    <Grid item xs={12}>
                        <Grid container justifyContent='center' spacing={2}>
                            <Grid item xs={12}>
                                <Typography className={classes.title} align='center'>
                                    {content.title[0]} <span>{content.title[1]}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.subtitle} align='center'>
                                    {content.subtitle}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent='center' spacing={4}>
                            {Object.keys(form).map((category, key) => (
                                <Grid item xs={12} sm={4} key={key}>
                                    <div className={cx({
                                        [classes.category]: true,
                                        [classes.categorySelected]: form[category].value
                                    })}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Typography className={classes.categoryLabel}>
                                                    {form[category].label}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <CheckboxForm size='small' data={form[category]} onChange={onChange} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingContainer loading={loading} sending={sending} error={error} success={success} onCloseError={() => setError(null)} onCloseSuccess={() => setSuccess(false)}>
                            <Grid container alignItems='center' spacing={4}>
                                <Grid item xs>
                                    <Divider className={classes.divider} />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.results} align='center'>
                                        {masterclasses.length} {content.results}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Divider className={classes.divider} />
                                </Grid>
                                <Grid item xs={12}>
                                    {masterclasses.length > 0 ?
                                        <Grid container spacing={4}>
                                            {masterclasses.map(masterclass => (
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <MasterclassCard {...masterclass} content={content} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                        :
                                        <Typography className={classes.noClasses} align='center'>
                                            {content.noContent}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </LoadingContainer>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>
    )
}

const styles_class_card = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'block',
        boxShadow: 'none',
        margin: 'auto',
    },
    cardActionArea: {
        //pointerEvents: 'none',
        position: 'relative',
    },
    cardContent: {
        color: 'white',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        padding: 32,
        boxSizing: 'border-box'
    },
    showCardContent: {
        display: 'block'
    },
    media: {
        background: 'grey',
        paddingTop: "calc(100% * (4 / 3))",
        width: '100%',
        height: '100%',
        display: 'block',
        borderRadius: 5,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        filter: 'brightness(0.5)'
    },
    artist: {
        fontSize: 14,
        fontWeight: 400,
        textTransform: 'capitalize',
        letterSpacing: '0.025em',
        [theme.breakpoints.only('xs')]: {
            fontSize: 9
        }
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        [theme.breakpoints.only('xs')]: {
            fontSize: 18
        }
    },
    levelContainer: {
        width: 'fit-content',
        marginLeft: 'auto'
    },
    level: {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '0.165em',
        [theme.breakpoints.only('xs')]: {
            fontSize: 10,
        }
    },
    bottom: {
        width: '100%',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: 0,
        left: 0,
        padding: 32,
        textAlign: 'center'
    },
    levelInd: {
        fontSize: 12
    }
}))

const MasterclassCard = ({ history, artist, title, masterclass_level, masterclass_level_id,
    content, web_image, mobile_image }) => {

    const classes = styles_class_card()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))

    const image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : content.noImage

    let level = []
    for (let i = 0; i < 5; i++) {
        level.push(i < masterclass_level_id ?
            <StopIcon className={classes.levelInd} /> :
            <StopOutlinedIcon className={classes.levelInd} />)
    }

    return (
        <Card className={classes.root} onClick={() => history.push(`/masterclasses/`)}>
            <CardActionArea className={classes.action_area}>
                <CardMedia
                    className={classes.media}
                    image={selectedImage}
                    title={"New master classes"}
                />
                <CardContent className={classes.cardContent}>
                    <div className={classes.levelContainer}>
                        <Typography className={classes.level}>
                            {masterclass_level}
                        </Typography>
                        <div className={classes.levelBar}>
                            {level}
                        </div>
                    </div>
                </CardContent>
            </CardActionArea >
            <CardActions style={{ background: 'transparent', padding: 0, paddingTop: 10 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.artist}>
                            {artist.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActions>
        </Card >
    )
}

const formData = {
    painting: {
        value: false,
        error: false,
        isVisited: false,
        config: {
            id: 'painting',
            type: 'checkbox',
        },
    },
    sculpture: {
        value: false,
        error: false,
        isVisited: false,
        config: {
            id: 'sculpture',
            type: 'checkbox',
        },
    },
    drawing: {
        value: false,
        error: false,
        isVisited: false,
        config: {
            id: 'drawing',
            type: 'checkbox',
        },
    },
    watercolor: {
        value: false,
        error: false,
        isVisited: false,
        config: {
            id: 'watercolor',
            type: 'checkbox',

        },
    },
    photography: {
        value: false,
        error: false,
        isVisited: false,
        config: {
            id: 'photography',
            type: 'checkbox',

        },
    },
    ceramic: {
        value: false,
        error: false,
        isVisited: false,
        config: {
            id: 'ceramic',
            type: 'checkbox',

        },
    },
}
export default FindClasses