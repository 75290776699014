import React, { useEffect, useState } from "react"
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, Grid, Icon, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./MagazineCarousel.css"
import { grey } from "@material-ui/core/colors"
import { useTheme } from "@material-ui/styles"
import { CONFIG_SETTINGS } from "../../../../../config"
import { isValidDocument, onGetDateFormat } from "../../../../../shared/utility"
import { catalogs } from "../../../../../texts/eng/catalogs"
import PageContainer from "../../../../../components/Structure/Layouts/PageContainer"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import cx from 'classnames'

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1200 },
        items: 3,
        partialVisibilityGutter: 80
    },
    largeDesktop: {
        breakpoint: { max: 1200, min: 1000 },
        items: 3,
        partialVisibilityGutter: 50
    },
    desktop: {
        breakpoint: { max: 1000, min: 700 },
        items: 3,
    },
    largeTablet: {
        breakpoint: { max: 700, min: 450 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 450, min: 350 },
        items: 1,
        partialVisibilityGutter: 100
    },
    mobile: {
        breakpoint: { max: 350, min: 0 },
        items: 1,
    }
}

const styles = makeStyles(theme => ({
    root: {
        paddingBottom: 100,
        paddingLeft: 150,
        paddingRight: 100,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    noMagazines: {
        fontSize: 16,
        fontWeight: 300
    },
    carouselItem: {
        height: 650,
        marginTop: -100,
        '& li': {
            margin: 10,
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                padding: 10,
                boxSizing: 'border-box'
            }
        },
        '& ul.react-multi-carousel-dot-list': {
            top: 0,
            marginTop: 600,
            height: 'fit-content',
        }
    },
    lineButton: {
        width: 120,
        height: 1.5,
        borderRadius: 0,
        //marginLeft: 10,
        '&:hover': {
            cursor: 'pointer'
        }
    },
}))

const Magazines = ({ history, magazines, content }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const tablet = useMediaQuery(theme.breakpoints.only('md'))
    const deviceType = mobile ? 'mobile' : (tablet ? 'tablet' : 'desktop')

    return (
        <div className={classes.root}>
            <PageContainer>
                <Grid container alignItems='center' spacing={3}>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} align='right'>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>
            </PageContainer>
            <Carousel
                responsive={responsive}
                ssr
                infinite
                autoPlay
                partialVisible
                keyBoardControl
                centerMode={false}
                customTransition="transform 300ms ease-in-out"
                transitionDuration={500}
                deviceType={deviceType}
                arrows={false}
                itemClass="magazine-carouselItem"
            >
                {magazines && magazines.map(magazine =>
                    <MagazineCard
                        {...magazine}
                        history={history}
                        noImage={content.noImage}
                        content={content}
                    />
                )}
            </Carousel>
        </div >
    )
}

export default Magazines

const stylesCard = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        boxShadow: 'none',
        margin: 'auto',
    },
    cardActionArea: {
        //pointerEvents: 'none',
        position: 'relative',
        borderRadius: 25,
    },
    cardContent: {
        display: 'none',
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        padding: 32,
        boxSizing: 'border-box'
    },
    showCardContent: {
        display: 'block'
    },
    media: {
        width: '100%',
        height: '100%',
        background: 'grey',
        paddingTop: "calc(100% * (16 / 9))",
        borderRadius: 25,
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    mediaH: {
        filter: 'brightness(0.5)'
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        textTransform: 'uppercase',
        color: theme.palette.secondary.main
    },
    description: {
        fontSize: 14,
        fontWeight: 400,
        color: grey[500]
    },
    nftIcon: {
        background: theme.palette.secondary.main,
        borderRadius: '100%',
        padding: 10
    },
    documentButton: {
        textTransform: 'capitalize'
    },
    buyButton: {
        textTransform: 'capitalize'
    }
}))

const MagazineCard = ({ content, magazine_title, magazine_description, magazine_web_image,
    magazine_mobile_image, noImage }) => {

    const classes = stylesCard()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [hover, setHover] = useState(false)

    const _image = mobile ? magazine_mobile_image : magazine_web_image
    const isValid = isValidDocument(_image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : noImage


    return (
        <Card className={classes.root}>
            <CardActionArea
                className={classes.cardActionArea}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <CardMedia
                    className={cx({
                        [classes.media]: true,
                        [classes.mediaH]: hover
                    })}
                    image={selectedImage}
                    title={"Artist Magazines"}
                />
                <CardContent className={cx({
                    [classes.cardContent]: true,
                    [classes.showCardContent]: hover
                })}>
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item xs={12} sm={6}>
                            <RoundedButton fullWidth color='transparent_white_square'>
                                {content.open}
                            </RoundedButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ background: 'transparent', padding: 0, paddingTop: 10 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            {magazine_title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.description}>
                            {magazine_description}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}