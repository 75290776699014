import React, { useState, useEffect } from 'react'
import { translations as translations_esp } from '../../../texts/eng/website/masterclasses-details-translations'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import MainBanner from './sections/MainBanner'
import Masterclass from './sections/Masterclass'
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer'
import Details from './sections/Details'
import ComingSoon from '../ComingSoon'
import { actionTypes } from '../../../store/actions'
import useMasterclassesDetailsView from './useMasterclassesDetailsView'
import { Typography } from '@material-ui/core'
import NextMasterclass from './sections/NextMasterclass'

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
        marginTop: 70,
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
    noClasses: {
        fontSize: 16,
        fontWeight: 300
    },
}))

const MasterclassesDetailsView = ({ is_auth, match, history, onUpdateDrawerSignUp, onUpdateDrawerLogIn }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useMasterclassesDetailsView({ match, history, onUpdateDrawerLogIn, onUpdateDrawerSignUp })

    /*return (
        <ComingSoon content={content.coming_soon}/>
    )*/

    return (
        <LoadingContainer loading={system.loading} success={system.success} error={system.error} onCloseError={actions.onCloseError} onCloseSuccess={actions.onCloseSuccess}>
            <div className={classes.content}>
                {view_data.masterclass ?
                    <>
                        <MainBanner is_auth={is_auth} masterclass={view_data.masterclass} history={history} content={content.main_banner_section} onGoToUrl={actions.onGoToUrl} />
                        <Masterclass masterclass={view_data.masterclass} history={history} content={content.masterclass_section} onOpenLogIn={actions.onOpenLogIn} onOpenSignUp={actions.onOpenSignUp} />
                        <Details masterclass={view_data.masterclass} history={history} content={content.details_section} />
                        <NextMasterclass masterclass={view_data.masterclass} content={content.next_masterclass_section} />
                    </>
                    :
                    <Typography className={classes.noClasses} align='center'>
                        {content.noContent}
                    </Typography>
                }
            </div>
        </LoadingContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        is_auth: state.is_auth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerSignUp: (signup_drawer) => dispatch({ type: actionTypes.DRAWER_UPDATE_SIGNUP, signup_drawer }),
        onUpdateDrawerLogIn: (login_drawer) => dispatch({ type: actionTypes.DRAWER_UPDATE_LOGIN, login_drawer }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterclassesDetailsView)