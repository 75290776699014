import React from "react"
import { Divider, Grid, Icon, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import Carousel from "react-multi-carousel"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { grey } from "@material-ui/core/colors";
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon";
import "react-multi-carousel/lib/styles.css"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer";
import { isValidDocument } from "../../../../shared/utility";
import { CONFIG_SETTINGS } from "../../../../config";
import ExhibitionCarousel from "./ExhibitionCarousel";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        partialVisibilityGutter: 40
    },
    tablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 2,
        partialVisibilityGutter: 20
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
    }
}

const styles = makeStyles(theme => ({
    root: {
        background: 'black',
        color: 'white',
        paddingTop: 80,
        paddingBottom: 80,
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[400]
    },
    lineButton: {
        width: 120,
        height: 2,
        backgroundColor: grey[700],
        borderRadius: 0,
        marginLeft: 10
    },
    noContent: {
        fontSize: 16,
        fontWeight: 300
    },
}))


const Individuals = ({ history, content, exhibitions, disabled, onVisitExhibition }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const tablet = useMediaQuery(theme.breakpoints.only('md'))

    const deviceType = mobile ? 'mobile' : (tablet ? 'tablet' : 'desktop')

    const lineButtons = exhibitions.map(item => <div className={classes.lineButton}></div>)

    const CustomDot = ({ onClick, ...rest }) => {
        const {
            onMove,
            index,
            active,
            carouselState: { currentSlide, deviceType }
        } = rest;

        return (
            <button
                style={{ border: 0, background: 'transparent' }}
                className={active ? "active" : "inactive"}
                onClick={() => onClick()}
            >
                {React.Children.toArray(lineButtons)[index]}
            </button>
        );
    };

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container justifyContent='center' spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title} align='right'>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            {exhibitions.length === 0 &&
                                <Grid item xs={12}>
                                    <Typography className={classes.noContent} align='center'>
                                        {content.noContent}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {exhibitions.length > 0 &&
                    <ExhibitionCarousel banners={exhibitions} noImage={content.noImage} featuredIcon={content.featuredIcon} onClick={onVisitExhibition}/>
                }
            </MainContainer>
        </div>
    )
}

export default Individuals
