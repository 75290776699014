import React, { useEffect, useState } from "react"
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm } from "../../../../../shared/utility"
import { generic_errors } from "../../../../../texts/eng/genericErrors"
import { public_server } from "../../../../../config"
import moment from "moment"

const useWelcome = ({ language, history, content }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [step, setStep] = useState(0)
    const [direction, setDirection] = useState('left')

    const maxStep = 3

    const STEPS_FORM = [
        ['email', { tags: ['collecting_art', 'exhibitions', 'art_news', 'knowledge'] }],
        ['first_name', 'last_name']
    ]

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: () => {
            let _form = null
            content.stepper.steps.map(stepData => {
                if (stepData.form)
                    _form = { ...onInitForm(form, null, stepData.form) }
            })

            setForm(_form)
        },
        onCloseError: () => setError(null),
        onCloseSuccess: () => setSuccess(false),
        handleStepper: async (next) => {
            if (next && step < maxStep - 1) {
                setDirection('left')
                if (step < maxStep - 1)
                    await actions.onSubmit()
                else
                    setStep(prevStep => prevStep + 1)
            }
            if (!next && step > 0) {
                setDirection('right')
                if (step === maxStep - 1)
                    setStep(0)
                else
                    setStep(prevStep => prevStep - 1)
            }
        },
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSubmit: async () => {
            let keys = []
            let _form = { ...form }

            if (step === 0)
                STEPS_FORM[step].map(step => {
                    if (typeof step === 'object' && step !== null) {
                        Object.keys(step).map(key => {
                            let values = []
                            step[key].map(item => {
                                if (form[item].value)
                                    values.push(form[item].value)
                            })

                            _form[key].isVisited = true

                            if (values.length > 0) {
                                values = values.join(',')
                                _form[key].value = values
                                _form[key].isValid = true

                            } else
                                _form[key].isValid = false
                            keys.push(key)
                        })
                    } else
                        keys.push(step)
                })
            else
                keys = [...STEPS_FORM[step]]

            setForm({ ..._form })
            const errors = isFormValid(_form, keys)

            // Error handler
            if (errors && errors.length) {
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                //setStep(step-1)
                return
                console.log(errors)
            }
            if (step < maxStep - 1) {
                setDirection('left')
                setStep(prevStep => prevStep + 1)
                return
            }
            // Clean data
            let data2send = onGetFormData(form)

            data2send.name = `${data2send.first_name} ${data2send.last_name}`
            delete data2send.first_name
            delete data2send.last_name
            data2send.date = moment()

            try {
                setSending(true)
                const response = await public_server.post(`/newsletterrequest/ns`, data2send)
                setStep(prevStep => prevStep + 1)
                setError(null)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        }
    }

    const system = { sending, success, error }
    const view_data = { form, step, maxStep, direction }

    return { system, view_data, actions }

}


export default useWelcome

const formData = {
    email: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
            placeholder: 'email@gmail.com',
            //placeholder: 'Set the email'
        },
        rules: {
            type: 'email'
        }
    },
    tags: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'tags',
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    collecting_art: {
        value: false,
        error: false,
        isVisited: false,
        isValid: true,
        config: {
            id: 'collecting_art',
            type: 'checkbox',

        },
    },
    exhibitions: {
        value: false,
        error: false,
        isVisited: false,
        isValid: true,
        config: {
            id: 'exhibitions',
            type: 'checkbox',
        },
    },
    art_news: {
        value: false,
        error: false,
        isVisited: false,
        isValid: true,
        config: {
            id: 'art_news',
            type: 'checkbox',
        },
    },
    knowledge: {
        value: false,
        error: false,
        isVisited: false,
        isValid: true,
        config: {
            id: 'knowledge',
            type: 'checkbox',
        },
    },
    first_name: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    last_name: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    }
}