import React, { useState, useEffect } from 'react';
import { translations as translations_esp } from '../../../texts/eng/website/exhibitions-translations'
import { onGetErrorMessage } from '../../../shared/utility';
import { private_server, public_server } from '../../../config'
import axios from 'axios'
import FileSaver from 'file-saver'
import queryString from 'query-string'

const useExhibitionsView = ({ user, is_auth, history, onUpdateContactDrawer }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [current, setCurrent] = useState(null)
    const [individuals, setIndividuals] = useState(null)
    const [coming, setComing] = useState(null)
    const [archived_exhibitions, setArchivedExhibitions] = useState(null)

    const [exhibitionImages, setExhibitionImages] = useState(null)
    const [filter, setFilter] = useState({ featured: false, type: null })


    const content = translations_esp

    useEffect(() => {
        actions.initModule()
    }, [])

    useEffect(() => {
        actions.onUpdateArchived()
    }, [filter])

    const actions = {
        initModule: async () => {
            window.scrollTo(0, 0)
            try {
                setLoading(true)
                let _filter = {
                    AND: [
                        { field: "exhibition_status_id", operator: "=", value: 4 },
                        {
                            OR: [
                                { field: "exhibition_type_id", operator: "=", value: 2 },
                                { field: "exhibition_type_id", operator: "=", value: 3 },
                            ]
                        }
                    ]
                }
                _filter = encodeURIComponent(JSON.stringify(_filter))
                const _current = is_auth ? await private_server.get(`/exhibition/all?filter=${_filter}`) : await public_server.get(`/exhibition/all/ns?filter=${_filter}`)
                setCurrent(_current.data.data)

                //INDIVIDUALS
                _filter = {
                    AND: [
                        { field: "exhibition_status_id", operator: ">=", value: 1 },
                        { field: "exhibition_status_id", operator: "<=", value: 4 },
                        { field: "exhibition_type_id", operator: "=", value: 1 },
                    ]
                }

                _filter = encodeURIComponent(JSON.stringify(_filter))
                const _individuals = is_auth ? await private_server.get(`/exhibition/all?filter=${_filter}`) : await public_server.get(`/exhibition/all/ns?filter=${_filter}`)
                //const _individuals = await public_server.get(`/exhibition/all/ns`)
                setIndividuals(_individuals.data.data.exhibitions)

                //COMING
                _filter = {
                    OR: [
                        { field: "exhibition_status_id", operator: "=", value: 2 },
                        { field: "exhibition_status_id", operator: "=", value: 3 },
                    ]
                }
                _filter = encodeURIComponent(JSON.stringify(_filter))
                const _coming = is_auth ? await private_server.get(`/exhibition/all?filter=${_filter}`) : await public_server.get(`/exhibition/all/ns?filter=${_filter}`)
                setComing(_coming.data.data)

                //ARCHIVED
                _filter = {
                    AND: [
                        { field: "exhibition_status_id", operator: "=", value: 6 },
                    ]
                }
                _filter = encodeURIComponent(JSON.stringify(_filter))

                const params = queryString.stringify({ offset: 0, order_by: 'start_date', order: 1 })
                const _archived = is_auth ? await private_server.get(`/exhibition/all?${params}&filter=${_filter}`) : await public_server.get(`/exhibition/all/ns?${params}&filter=${_filter}`)
                const _archived_exhibitions = _archived.data.data.exhibitions
                setArchivedExhibitions(_archived_exhibitions)

                let images = { web: [], mobile: [] }

                _archived_exhibitions.forEach(exhibition => {
                    images.web.push(exhibition.web_image)
                    images.mobile.push(exhibition.mobile_image)
                })

                setExhibitionImages(images)


            } catch (error) {
                console.log(error)
                onGetErrorMessage(error)
            }
            setLoading(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onShowContactDrawer: () => onUpdateContactDrawer(true),
        onVisitExhibition: (url) => {
            //console.log(`exhibitions/details/${id}`)
            history.push(`exhibitions/${url}`)
        },
        onSaveExhibition: async (exhibitionID, saved) => {
            try {
                setSending(true)
                const data = { exhibition_id: exhibitionID }
                let _filter = {
                    AND: [
                        { field: "exhibition_id", operator: "=", value: exhibitionID },
                    ]
                }
                _filter = encodeURIComponent(JSON.stringify(_filter))

                //SI YA ESTA GUARDADA LA QUITAMOS
                if (saved) {
                    const saved_ex = await private_server.get(`savedexhibition/all/me?filter=${_filter}`)
                    console.log(saved_ex.data.data)
                    const saved_exhibition_id = saved_ex.data.data.saved_exhibitions[0].id_saved_exhibition
                    await private_server.delete(`savedexhibition/${saved_exhibition_id}/me`)
                } else
                    await private_server.post(`savedexhibition/me`, data)

                _filter = {
                    AND: [
                        { field: "exhibition_status_id", operator: "=", value: 4 },
                        {
                            OR: [
                                { field: "exhibition_type_id", operator: "=", value: 2 },
                                { field: "exhibition_type_id", operator: "=", value: 3 },
                            ]
                        }
                    ]
                }

                _filter = encodeURIComponent(JSON.stringify(_filter))
                let _current = await private_server.get(`/exhibition/all?filter=${_filter}`)
                setCurrent(_current.data.data.exhibitions)

                /*_filter = {
                    AND: [
                        { field: "exhibition_status_id", operator: ">=", value: 1 },
                        { field: "exhibition_status_id", operator: "<=", value: 4 },
                        { field: "exhibition_type_id", operator: "=", value: 1 },
                    ]
                }

                _filter = encodeURIComponent(JSON.stringify(_filter))
                const _individuals = await private_server.get(`/exhibition/all?filter=${_filter}`)
                setIndividuals(_individuals.data.data.exhibitions)*/
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onDownloadExhibition: async (file, name) => {
            try {
                setSending(true)
                let _name = name ? name : 'document'
                let response = await axios.get(file, { responseType: 'blob' })
                FileSaver.saveAs(response.data, _name)
                setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onSendFilter: (id, value) => {
            let _filter = { ...filter }
            _filter[id] = value
            setFilter(_filter)
        },
        onUpdateArchived: async () => {
            try {
                setSending(true)
                let _filter = {
                    AND: [
                        { field: "exhibition_status_id", operator: "=", value: 6 },
                    ]
                }
                if (filter.featured)
                    _filter.AND.push({ field: "featured", operator: ">", value: 0 })

                _filter = encodeURIComponent(JSON.stringify(_filter))
                const params = queryString.stringify({ offset: 0, order_by: 'start_date', order: 1 })

                const _archived = is_auth ? await private_server.get(`/exhibition/all?${params}&filter=${_filter}`) : await public_server.get(`/exhibition/all/ns?${params}&filter=${_filter}`)

                const _archived_exhibitions = _archived.data.data.exhibitions
                setArchivedExhibitions(_archived_exhibitions)

                let images = { web: [], mobile: [] }

                _archived_exhibitions.forEach(exhibition => {
                    images.web.push(exhibition.web_image)
                    images.mobile.push(exhibition.mobile_image)
                })

                setExhibitionImages(images)
                setSending(false)                
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        }
    }

    const system = { loading, sending, success, error }
    const view_data = { filter, current, individuals, coming, archived_exhibitions, exhibitionImages }

    return { system, view_data, actions }
}

export default useExhibitionsView