import noImage from '../../../assets/artist/noImage.png'
import freeImage from '../../../assets/masterclassImage.png'
import shareIcon from '../../../assets/icons/share.svg'
import clockIcon from '../../../assets/icons/wall-clock.svg'
import arrowIcon from '../../../assets/icons/expand_down.svg'
import customClockIcon from '../../../assets/icons/wall-clock-white-clockhands.svg'

export const translations = {
    noContent: 'Sorry! There are not classes yet.',
    coming_soon: {
        top_title: 'Coming soon',
        title: 'May 1st, 2022',
        caption: 'We commit to something bigger than ourselves'
    },
    main_banner_section: {
        title: 'NEW CLASSES',
        noImage: noImage,
        noContent: 'Sorry! There are not classes yet.',
        share: {
            icon: shareIcon,
            label: 'Share'
        },
        getItFree: 'Get it free',
        clock: clockIcon
    },
    masterclass_section: {
        noImage: noImage,
        noContent: 'Sorry! The video is not available.',
        master_content: 'Masterclass content',
        clock: customClockIcon,
        arrow: arrowIcon,
        logIn: 'Log in',
        signUp: 'Sign up',
        or: 'or',
        message: 'to get this masterclass for free',
        minutes: 'mins',
    },
    details_section: {
        noImage: noImage,
        textSections: [
            {
                key: 'description',
                label: 'Description',
                expanded: true
            },
            {
                key: 'details',
                label: 'Details',
            },
        ],
        artist: {
            key: 'artist',
            label: 'Instructor',
            expanded: true
        },

    },
    next_masterclass_section: {
        title: 'Next masterclass',
        noContent: 'Sorry! There is not a next masterclass yet.',
        clock: customClockIcon,
        minutes: 'mins',
    }
}