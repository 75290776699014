import React, { useState } from "react"
import { Divider, Grid, makeStyles, Step, StepContent, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import SVGIcon from "../../../../../components/Structure/DisplayData/SVGIcon"
import PropTypes from 'prop-types'
import cx from 'classnames'
import Step1 from "./Steps/Step1"
import Step2 from "./Steps/Step2"
import Step3 from "./Steps/Step3"
import Step4 from "./Steps/Step4"
import PageContainer from "../../../../../components/Structure/Layouts/PageContainer"
import MainContainer from "../../../../../components/Structure/Layouts/MainContainer"

const styles = makeStyles(theme => ({
    root: {
        paddingTop:80,
        paddingBottom:80,
        backgroundColor: 'black',
        color: 'white',
        //paddingLeft: 100,
        //paddingRight: 100,
        [theme.breakpoints.down('sm')]: {
            //padding: 32
        },
    },
    content: {
        marginTop: 100,
        minHeight:400,
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    stepperContainer: {
        width: '100%',
        //paddingLeft: 64,
        paddingTop: 24,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            boxSizing: 'border-box',
            padding: 32
        }
    },
    stepper: {
        color: 'white',
        background: 'transparent',
        padding: 0,
        paddingTop: 12,
        '& .MuiStepIcon-root': {
            width: 32,
            height: 32,
            fontSize: 26,
            fontWeight: 500,
            border: `solid 1.5px white`,
            borderRadius: '100%',
            cursor: 'pointer',
            '& .MuiStepIcon-text': {
                fill: 'darkgrey'
            },
        },
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            background: 'rgba(203, 203, 203, 0.24)',
            '& .MuiStepIcon-text': {
                fill: 'white'
            }
        }
    },
    stepperLabel: {
        color: 'white',
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 700,
    },
}))

const Application = ({ history, content }) => {

    const classes = styles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'))

    const [step, setStep] = useState(1)

    let stepContent = null

    switch (step) {
        case 1:
            stepContent = <Step1 {...content.steps[0]} />
            break;
        case 2:
            stepContent = <Step2 {...content.steps[1]} />
            break;
        case 3:
            stepContent = <Step3 {...content.steps[2]} />
            break;
        case 4:
            stepContent = <Step4 {...content.steps[3]} />
            break;
    }

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container justifyContent='center' spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title} align='center'>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.stepperContainer}>
                            <Stepper
                                activeStep={step - 1} className={classes.stepper} alternativeLabel={!isMobile} orientation={isMobile ? 'vertical' : 'horizontal'}>
                                {content.header.map((stepInfo, key) => (
                                    <Step key={`stepper_community.${key}`} onClick={() => setStep(key + 1)}>
                                        <StepLabel StepIconComponent={(props) => <StepIcon {...props} icon={stepInfo.icon} />}>
                                            {step !== key + 1 && <Typography className={classes.stepperLabel}>{stepInfo.title}</Typography>}
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                        <div className={classes.content}>
                            {stepContent}
                        </div>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>
    )
}

export default Application

const stepIconStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'rgba(100, 100, 100, 0.21)',
        zIndex: 1,
        color: '#fff',
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100%',
        border: 'solid 1px white',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    active: {
        marginTop: -25,
        width: 80,
        height: 80,
        backgroundColor: '#313131',
        [theme.breakpoints.only('xs')]: {
            marginTop: 0,
            width: 50,
            height: 50,
        }
    },
    completed: {
        marginTop: 0,
        background: 'rgba(203, 203, 203, 0.5)',
    },
}))

const StepIcon = (props) => {

    const classes = stepIconStyles()
    const theme = useTheme()
    const isMobile = theme.breakpoints.only('xs')

    const { active, completed, icon } = props;

    return (
        <div
            className={cx({
                [classes.root]: true,
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            <SVGIcon src={icon} size={active ? (isMobile ? 30 : 50) : 20} color='white' />
        </div>
    );
}

StepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};