import React from "react"
import { Divider, Grid, Icon, makeStyles, Typography } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import PageContainer from "../../../../components/Structure/Layouts/PageContainer"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
//import './video.css'

const Abstract = ({ history, content, openSignUpDrawer }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container spacing={0} justifyContent='center'>
                    <Grid item xs={12}>
                        <Typography variant='h5' align='center' style={{ letterSpacing: 8 }}>
                            {content.title}
                        </Typography>
                        <Typography variant='h6' align='center' color='secondary' style={{ letterSpacing: 4 }}>
                            {content.subtitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.message}>
                            {content.description.map((info, key) => {
                                return (
                                    <Typography key={key} variant='body1' align='center' className={classes.text}>
                                        {info.map((text, k) => {
                                            return text.bold ? <span key={k}> {text.text}</span> : text.text
                                        })}
                                    </Typography>
                                )
                            })}
                        </div>

                    </Grid>
                    <Grid item xs></Grid>
                    <Grid item xs={12}>
                        <div className={classes.video_wrapper} >
                            <div className={classes.video}>
                                <video  width='100%' height='100%'
                                    className={classes.iframe}
                                    src={content.video}
                                    title="YouTube video player"
                                    controls
                                    autoPlay
                                    muted
                                    //frameBorder="0"
                                    //allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen  
                                ></video>
                            </div>
                            <div className={classes.video_content}>
                                <Grid container justifyContent='space-between' alignItems='center' spacing={4}>
                                    <Grid item>
                                        <Typography variant='h6' className={classes.subtitle}>
                                            {content.comunity.title}
                                        </Typography>
                                        <Typography variant='body2'>
                                            {content.comunity.description}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <RoundedButton size='large' onClick={openSignUpDrawer}>
                                            {content.comunity.join_button}
                                        </RoundedButton>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                    </Grid>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
            </MainContainer>
        </div>
    )
}

const styles = makeStyles(theme => ({
    root: {

        paddingTop: 80,
        paddingBottom: 80,
        backgroundColor: '#FCFCFC',
    },
    text: {
        fontSize: 20,
        fontWeight: 300,
        '& span': {
            fontWeight: 500
        }
    },
    message: {
        width: 750,
        margin: '24px auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    video_wrapper: {
        width: 1000,
        margin: 'auto',
        marginTop: 24,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    video: {
        width:'100%',
        margin: 'auto',
        borderRadius: 8,
        height: 0,
        textAlign: 'center',
        position: 'relative',
        paddingBottom: "56.25%",
        overflow: 'hidden',
        filter: 'drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.31))'
    },
    iframe: {
        position: 'absolute',
        borderRadius:12,
        top: 0,
        left: 0,
        bottom:0,
        right:0,
        width: '100%',
        height: '100%',
        border:'none',
        '& video': {
            width: '100%',
            height: '100%',
            objectFit: 'fill',
        }
    },

    video_content: {
        marginTop: 16
    },
    subtitle: {
        //fontSize: 30,
        fontWeight: 600
    }
}))

export default Abstract