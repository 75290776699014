import React from 'react'
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { ruleValidation } from './customFunctions';
import { makeStyles } from '@material-ui/styles';
import StopIcon from '@material-ui/icons/Stop';

const styles = makeStyles(theme => ({
    root: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 3,
        marginRight: 10,
        color: 'transparent',
        '& .MuiSvgIcon-root': {
            margin: -5
        },
        '&$checked': {
            color: theme.palette.primary.main
        }
    },
    formRoot: {
        marginLeft: 0,
        marginRight: 0
    }
}))

const CheckboxForm = props => {

    const classes = styles()
    const { data, onChange, disabled, size } = props

    const onInnerChange = event => {
        const response = event.target.checked
        let temp = { ...data }
        temp.value = response
        temp.isVisited = true
        temp.isValid = true
        onChange(temp)
    }

    const { config, value, isVisited } = data
    const { id, label } = config

    return (
        <FormControlLabel
            control={
                <Checkbox
                    name={id}
                    checked={value}
                    onChange={onInnerChange}
                    color="primary"
                    disabled={disabled}
                    icon={<StopIcon fontSize={size ? size : 'medium'} />}
                    checkedIcon={<StopIcon fontSize={size ? size : 'medium'} />}
                    classes={{ root: classes.root }}
                />
            }
            classes={{ root: classes.formRoot }}
            label={label}
        />
    )
}

export default CheckboxForm