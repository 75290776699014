import React, { useState } from "react"
import { alpha, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, Grid, Icon, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import "react-multi-carousel/lib/styles.css"
import { grey } from "@material-ui/core/colors"
import { useTheme } from "@material-ui/styles"
import { CONFIG_SETTINGS } from "../../../../config"
import { isValidDocument } from "../../../../shared/utility"
import PageContainer from "../../../../components/Structure/Layouts/PageContainer"
import cx from 'classnames'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"

const styles = makeStyles(theme => ({
    root: {
        paddingLeft: 100,
        paddingRight: 100,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        },
    },
    content: {
        paddingTop: 100,
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
}))

const NewReleases = ({ history, magazines, content, onSave, onClick }) => {

    const classes = styles()

    const [sending, setSending] = useState(true)
    const [success, setSuccess] = useState(true)

    return (
        <div className={classes.root}>
            <PageContainer>
                <Grid container alignItems='center' spacing={3}>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} align='right'>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>
                <div className={classes.content}>
                    <Grid container spacing={4}>
                        {magazines.length > 0 ?
                            magazines.map(magazine =>
                                <Grid item xs={12} sm={6} md={4}>
                                    <MagazineCard
                                        {...magazine}
                                        onSave={onSave}
                                        history={history}
                                        content={content}
                                        onClick={onClick}
                                    />
                                </Grid>
                            )
                            :
                            <Grid item xs={12}>
                                <Typography className={classes.noMagazine} align='center'>
                                    {content.noContent}
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </div>
            </PageContainer>
        </div >
    )
}

export default NewReleases

const stylesCard = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        boxShadow: 'none',
        margin: 'auto',
    },
    cardActionArea: {
        //pointerEvents: 'none',
        position: 'relative',
        borderRadius: 5,
    },
    cardActions: {
        width: '100%',
        background: 'transparent',
        padding: 0,
        paddingTop: 10,
        display: 'block',
       // transform: 'translateY(-20%)',
    },
    cardContent: {
        display: 'none',
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        padding: 32,
        boxSizing: 'border-box'
    },
    showCardContent: {
        display: 'block',
    },
    media: {
        width: '100%',
        height: '100%',
        background: 'grey',
        paddingTop: "calc(100% * (3 / 2))",
        borderRadius: 5,
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        //filter: 'drop-shadow(0px 4.80392px 36.0294px rgba(0, 0, 0, 0.23));'
    },
    mediaH: {
        filter: 'drop-shadow(0px 4.80392px 36.0294px rgba(0, 0, 0, 0.23)) brightness(0.6)'
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        textTransform: 'uppercase',
        color: 'black'
    },
    description: {
        fontSize: 14,
        fontWeight: 400,
        color: grey[500],
        textTransform: 'uppercase'
    },
    infoContent: {
      //  marginTop: 10
    },
    buttonsBanner: {
        bottom: 0,
        padding: 10,
        paddingLeft: 10,
        paddingRight: 10,
        width: '80%',
        height: 'fit-content',
        borderRadius: 5,
        color: grey[700],
        margin: 'auto',
        backgroundColor: 'rgba(255, 255, 255, 0.91)',
        [theme.breakpoints.only('xs')]: {
            width: '70%',
            padding: 10
        }
    },
    buttonsBannerH: {
        backgroundColor: 'white',
    },
    button: {
        alignItems: 'center',
        display: 'inline-flex',
        textTransform: 'capitalize',
        '& p': {
            fontSize: 14,
            fontWeight: 400,
            paddingLeft: 5,
            paddingRight: 5
        },
        [theme.breakpoints.only('xs')]: {
            margin: 'auto'
        },
    },
    featuredTag: {
        top: 0,
        position: 'absolute',
        right: 10
    }
}))

const MagazineCard = ({ content, id_magazine, title, description, web_image,
    mobile_image, saved, onSave, onClick, url, featured }) => {

    const classes = stylesCard()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [hover, setHover] = useState(false)

    const _image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(_image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : content.noImage

    return (
        <Card className={classes.root}>
            <CardActionArea
                className={classes.cardActionArea}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <CardMedia
                    className={cx({
                        [classes.media]: true,
                        [classes.mediaH]: hover
                    })}
                    image={selectedImage}
                    title={"Artist Magazines"}
                />
                <CardContent className={cx({
                    [classes.cardContent]: true,
                    [classes.showCardContent]: hover
                })}>
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item xs={12} sm={6}>
                            <RoundedButton
                                fullWidth
                                color='white_square'
                                onClick={() => onClick(url)}
                            >
                                {content.open}
                            </RoundedButton>
                        </Grid>
                    </Grid>
                </CardContent>
                {featured && featured > 0 ?
                    <div className={classes.featuredTag}>
                        <SVGIcon src={content.featuredIcon} color={hover ? 'white' : theme.palette.secondary.main} size={42} />
                    </div>
                    : null}
            </CardActionArea>
            <CardActions className={classes.cardActions}>
                {/*
                <div className={cx({
                    [classes.buttonsBanner]: true,
                    [classes.buttonsBannerH]: hover
                })}>
                    <Grid container justifyContent='center' alignItems='center' spacing={2}>
                        <Grid item xs={6}>
                            <Button>
                                <div className={classes.button}>
                                    <SVGIcon src={content.buttons.share.icon} size={20} color='black' />
                                    <Typography>{content.buttons.share.label}</Typography>
                                </div>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={() => onSave(id_magazine)} disabled={!onSave}>
                                <div className={classes.button}>
                                    {saved ?
                                        <SVGIcon src={content.buttons.save.alt_icon} size={20} color={theme.palette.secondary.main} />
                                        :
                                        <SVGIcon src={content.buttons.save.icon} size={20} colorStroke={theme.palette.secondary.main} />
                                    }
                                    <Typography>{content.buttons.save.label}</Typography>
                                </div>
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                                */}
                <div className={classes.infoContent}>
                    <Grid container>
                        <Grid item xs={12} sm>
                            <Typography className={classes.title}>
                                {title}
                            </Typography>
                            <Typography className={classes.description}>
                                {description}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardActions>
        </Card>
    )
}