import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import PageContainer from '../../../../components/Structure/Layouts/PageContainer'

const styles = makeStyles(theme => ({
    root: {
        height: '100vh',
        color: 'white',
    },
    image: {
        width: '100%',
        height: '100vh',
        objectFit: 'cover',
        filter: 'brightness(50%)',
    },
    content: {
        width: '100%',
        height: '100%',
        position: 'relative',
        transform: 'translateY(-100vh)'
    },
    center: {
        margin: 0,
        position: 'absolute',
        top: '40%',
        transform: 'translateY(-40%)',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            left: 0,
            padding: 32,
            boxSizing: 'border-box'
        }
    },
    title: {
        fontSize: 36,
        fontWeight: 400,
        '& span': {
            fontSize: 42,
            fontWeight: 600
        }
    },
    description: {
        width: '70%',
        fontSize: 24,
        fontWeight: 500,
        letterSpacing: '0.285em',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            letterSpacing: 'inherit'
        }
    }
}))

const MainBanner = ({ content }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <img src={content.banner} className={classes.image} />
            <PageContainer>
                <div className={classes.center}>
                    <Typography className={classes.title}>
                        {content.title} <span>{content.kuvik}</span>
                    </Typography>
                    <Typography className={classes.description} align='justify'>
                        {content.description}
                    </Typography>
                </div>
            </PageContainer>
        </div >
    )
}

export default MainBanner