import React, { useState, useEffect } from 'react'
import { translations as translations_esp } from '../../../texts/eng/website/masterclasses-translations'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress, Typography } from '@material-ui/core'
import { onGetErrorMessage } from '../../../shared/utility'
import queryString from 'query-string'
import Header from './sections/Header'
import NewClasses from './sections/NewClasses/NewClasses'
import { public_server } from '../../../config'
import TopClasses from './sections/TopClasses/TopClasses'
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer'
import FreeClasses from './sections/FreeClasses'
import FindClasses from './sections/FindClasses'
import ComingSoon from '../ComingSoon'
import { actionTypes } from '../../../store/actions'
import useMasterclassesView from './useMasterclassesView'

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
        marginTop: 140,
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const MasterclassesView = ({ history, onUpdateDrawerSignUp }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useMasterclassesView({ history, onUpdateDrawerSignUp })

    return (
        <ComingSoon content={content.coming_soon}/>
    )

/*
    return (
        <LoadingContainer loading={system.loading} success={system.success} error={system.error} onCloseError={actions.onCloseError} onCloseSuccess={actions.onCloseSuccess}>
            <div className={classes.content}>
                <Header history={history} content={content.main_info_section} />
                <NewClasses masterclasses={view_data.new_classes} history={history} content={content.new_classes_section} onGoToUrl={actions.onGoToUrl} />
                <TopClasses masterclasses={view_data.top_classes} history={history} content={content.top_classes_section} onGoToUrl={actions.onGoToUrl} />
                <FreeClasses history={history} content={content.free_classes_section} onUpdateDrawerSignUp={onUpdateDrawerSignUp} />
                <FindClasses
                    masterclasses={view_data.masterclasses}
                    history={history}
                    content={content.find_classes_section}
                    onUpdateClasses={actions.onUpdateClasses}
                />
            </div>
        </LoadingContainer>
    )*/
}

const mapStateToProps = (state) => {
    return {
        is_auth: state.is_auth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerSignUp: (signup_drawer) => dispatch({ type: actionTypes.DRAWER_UPDATE_SIGNUP, signup_drawer }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterclassesView)