import React from 'react';
import { translations as translations_esp } from '../../../texts/eng/website/magazines-details-translations'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import VKGallery from './sections/VKGallery'
import Artists from './sections/Artists'
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer'
import useMagazinesDetailsView from './useMagazinesDetailsView'
import { actionTypes } from '../../../store/actions'

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const MagazinesDetailsView = ({ history, match, onUpdateContactDrawerStatus, onUpdateMagazineReference }) => {

    const classes = styles()

    const { system, actions, view_data } = useMagazinesDetailsView({ match, history, onUpdateContactDrawerStatus, onUpdateMagazineReference })

    const content = translations_esp

    return (
        <LoadingContainer
            loading={system.loading}
            sending={system.sending}
            success={system.success}
            error={system.error}
            onCloseSuccess={actions.onCloseSuccess}
            onCloseError={actions.onCloseError}
        >
            <div className={classes.content}>
                <VKGallery pdfGallery={view_data.pdfGallery} iframe={view_data.iframe} subtitle={view_data.subtitle} history={history} content={content.vk_gallery_section} />
                <Artists artists={view_data.artists} history={history} content={content.artists_section} onArtistSelected={actions.onArtistSelected} />
            </div>
        </LoadingContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        is_auth: state.is_auth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateContactDrawerStatus: (contact_drawer) => dispatch({ type: actionTypes.DRAWER_CONTACT, contact_drawer }),
        onUpdateMagazineReference: (magazine_reference) => dispatch({ type: actionTypes.REFERENCE_MAGAZINE, magazine_reference }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MagazinesDetailsView)