import general_es from './eng/website/general-translations'
import front_end_errors_es from './eng/frontend-erros'



const system_translations = {
    en: {
        errors: front_end_errors_es,
        general: general_es,
        views: {

        },
        modals: {
            profile_picture: {
                title: 'Profile Picture',
                placeholder:'',
                image_selected:'',
                save_button:'Save'
            }
        },
    },
    es: {

    }
}

export default system_translations