import React, { useState, useEffect } from 'react';
import { translations as translations_esp } from '../../../texts/eng/website/exhibitions-details-translations'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { onGetErrorMessage } from '../../../shared/utility';
import MainBanner from './sections/MainBanner';
import Virtual from './sections/Virtual';
import Artwork from './sections/Artwork';
import Artists from './sections/Artists';
import Magazines from './sections/Magazines'
import VirtualMagazine from './sections/VirtualMagazine';
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer';
import { public_server } from '../../../config';
import queryString from 'query-string'
import useExhibitionsDetails from './useExhibitionsDetails';
import { actionTypes } from '../../../store/actions';

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const ExhibitionsDetailsView = ({ user, history, match, onUpdateArtworkData, onUpdateModalStatus }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useExhibitionsDetails({ user, history, match, onUpdateArtworkData, onUpdateModalStatus })

    return (
        <div>
            <LoadingContainer loading={system.loading} error={system.error} onCloseError={actions.onCloseError}>
                <div className={classes.content}>
                    <MainBanner exhibition={view_data.exhibition} history={history} content={content.main_banner_section} />
                    {view_data.artwork && view_data.artwork.length > 0 &&
                        <Artwork
                            artworks={view_data.artwork}
                            history={history}
                            content={content.artwork_section}                       
                            onOpenArtWork={actions.onOpenArtWork}
                        />
                    }
                    <Virtual exhibition={view_data.exhibition} history={history} content={content.virtual_section} />
                    <VirtualMagazine exhibition={view_data.exhibition} history={history} content={content.virtual_maganize_section} />
                    {/*artists && artists.length > 0 &&
                        <Artists artists={artists} history={history} content={content.artists_section} />
                    */}
                    {/*<Magazines history={history} content={content.magazines_section} /> */}
                    <Artists artists={view_data.artists} history={history} content={content.artists_section} />
                </div>
            </LoadingContainer>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateArtworkData: (artwork) => dispatch({ type: actionTypes.DATA_UPDATE_ARTWORK, artwork }),
        onUpdateModalStatus: (artwork_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_ARTWORK, artwork_modal }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitionsDetailsView);