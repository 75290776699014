import React, { useState } from 'react';
import { Avatar, fade, Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { CONFIG_SETTINGS } from '../../../config';
import { isValidDocument } from '../../../shared/utility';

const AvatarPicture = ({ nopicture, src, onChangePicture, can_edit, size, profile_picture }) => {
    const classes = useStyles()
    const [hover, setHover] = useState(false)

    let selectedImage = nopicture

    let size_styles = { width: 160, height: 160 }
    if (size) size_styles = { width: size, height: size }

    if (src && !profile_picture) {
        const isValid = isValidDocument(src)
        selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${src}` : nopicture
    } else {if(profile_picture) selectedImage = src}

    return (
        <div onClick={can_edit ? onChangePicture : null} className={classes.avatarContainer}
            style={size_styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
            {hover && can_edit ? (<div className={classes.overlay}>
                <div className={classes.picture}>
                    <Grid container direction='column' alignItems='center'>
                        <Grid item>
                            <Icon style={{ color: grey[300] }} fontSize='large'>photo_camera</Icon>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: grey[300] }}>Editar</Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>) : null}
            <Avatar src={selectedImage} alt='' className={classes.image} style={size_styles} />
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    image: {
        width: 160,
        height: 160,
    },
    container: {
        padding: 32,
        borderRadius: 16,
        border: `1px solid ${grey[300]}`
    },
    avatarContainer: {
        width: 160,
        height: 160,
        position: 'relative'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        background: fade('#000', 0.5),
        zIndex: 2,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    picture: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
}))

export default AvatarPicture;