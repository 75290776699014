import React from "react"
import { Box, Grid, makeStyles, SvgIcon, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MainContainer from "../../../../components/Structure/Layouts/MainContainer";


const styles = makeStyles(theme => ({
    root: {
        color:'white',
        paddingTop: 120,
        paddingBottom: 120,
        position: 'relative',
    },
    container: {
        position: 'relative',
    },
    title: {
        fontSize: 36,
        fontWeight: 600
    },
    description: {
        fontSize: 24,
        fontWeight: 400,
        letterSpacing:'0.065em',
        '& span': {
            color: theme.palette.secondary.dark
        }
    },
    banner: {
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        filter:'brightness(0.7)'
    },
    buttons: {
        width: 270,
        height: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        }
    }
}))

const KuvikBanner = ({ history, content }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <img src={content.banner} className={classes.banner} />
            <MainContainer className={classes.container}>
                <Grid container justifyContent='center' alignItems='center' spacing={4}>
                    <Grid item xs={12}>
                        <Typography className={classes.title} align='center'>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align='center' className={classes.description}>
                            {content.description}
                        </Typography>
                    </Grid>
                </Grid>
            </MainContainer>
        </div >
    )
}

export default KuvikBanner