import React from "react"
import { Box, Button, Divider, Grid, IconButton, makeStyles, SvgIcon, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { CONFIG_SETTINGS, nodata, private_server } from "../../../../config";
import { isValidDocument, updateAuthorizationHeader } from "../../../../shared/utility";
import { grey } from "@material-ui/core/colors";
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon";
import PageContainer from "../../../../components/Structure/Layouts/PageContainer";
import MainContainer from "../../../../components/Structure/Layouts/MainContainer";
import GenericImage from "../../../../components/Structure/DisplayData/GenericImage";
import axios from 'axios'
import FileSaver from 'file-saver';

const styles = makeStyles(theme => ({
    root: {
        color: 'white',
        background: 'black',
        paddingTop: 80,
        paddingBottom: 80,
    },
    discipline: {
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: '0.265em'
    },
    tweet: {
        fontSize: 22,
        fontWeight: 200,
    },
    biography: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 2.5
    },
    full: {
        color: 'white',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: '0.525em'
    },
    image: {
        width: '100%',
        height: 'auto',
        minHeight: 500
    },
    divider: {
        backgroundColor: grey[600],
        marginBottom: 24
    },
    document: {
        background: '#252525',
        width: 52,
        height: 52,
        position: 'relative',
        boxSizing: 'border-box',
        border: '0.7px solid white',
        borderRadius: '100%',
        margin: 'auto',
        marginBottom: 10
    },
    documentLabel: {
        fontSize: 14
    },
    button: {
        display: 'block',
        margin: 'auto',
        borderRadius: 12,
    },
}))

const Abstract = ({ artist, image, content, onGoToBio }) => {

    const classes = styles()
    const theme = useTheme()

    const ismobile = useMediaQuery(theme.breakpoints.down('xs'))

    const onDownloadFile = async (path, name) => {
        try {
            const fullpath = `${CONFIG_SETTINGS.S3_SERVER_URL}${path}`
            //updateAuthorizationHeader(private_server)
            delete axios.defaults.headers.common["Authorization"]
            const response = await axios.get(fullpath, { responseType: 'blob' });
            console.log(response)
            FileSaver.saveAs(response.data, `name`);
        } catch (error) {
            console.log(error)
        }
    }



    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : content.noImage

    let iscv = false
    let isportfolio = false

    if (artist) {
        iscv = isValidDocument(artist.cv)
        isportfolio = isValidDocument(artist.portfolio)
    }

    const icon = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    }

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container justifyContent='center' alignItems='stretch' spacing={5}>
                    <Grid item xs={12} sm={6}>
                        <div style={{ maxWidth: 600, margin: 'auto' }}>
                            <GenericImage src={image} ratio='3/4' />
                        </div>

                        {/*<img src={selectedImage} className={classes.image} />*/}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container justifyContent='center' alignItems='stretch' spacing={2}>
                            <Grid item xs={12}>
                                <Typography className={classes.discipline}>
                                    {artist.discipline_subject ? artist.discipline_subject : nodata}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.tweet} align='justify'>
                                    {artist.tweet}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.biography} align='justify'>
                                    {artist.statement && artist.statement.replace(/\r/gm, '').split("\n").map(text => (
                                        <p>{text}</p>
                                    ))}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ marginTop: 40 }}>
                                    <Grid container justifyContent={'center'} spacing={4}>
                                        {iscv ? <Grid item>
                                            <div style={{ cursor: 'pointer' }} onClick={() => onDownloadFile(artist.cv, 'cv')}>
                                                <div className={classes.document}>
                                                    <SVGIcon src={content.documents.cv.icon} style={{ ...icon }} />
                                                </div>
                                                <Typography className={classes.documentLabel} align='center'>
                                                    {content.documents.cv.label}
                                                </Typography>
                                            </div>
                                        </Grid>
                                            : null}
                                        {isportfolio ?
                                            <Grid item>
                                                <div style={{ cursor: 'pointer' }} onClick={() => onDownloadFile(artist.portfolio, 'portfolio')}>
                                                    <div className={classes.document}>
                                                        <SVGIcon src={content.documents.portfolio.icon} style={{ ...icon }} />
                                                    </div>
                                                    <Typography className={classes.documentLabel} align='center'>
                                                        {content.documents.portfolio.label}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            : null}
                                    </Grid>
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onGoToBio} className={classes.button}>
                            <div className={classes.button}>
                                <SVGIcon src={content.arrow} size={42} style={{ margin: 'auto' }} colorStroke='white' />
                                <Typography className={classes.full} align='center'>
                                    {content.full}
                                </Typography>
                            </div>
                        </IconButton>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>
    )
}

export default Abstract