import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { CircularProgress, Divider, Grid, Icon, makeStyles, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import SVGIcon from '../../../../components/Structure/DisplayData/SVGIcon'
import Carousel from 'react-material-ui-carousel'
import LanguageButton from '../../../../components/Actions/LanguageButton'

import CustomCarousel from './CustomCarousel'

const styles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    content: {
        color: 'white',
        position: 'relative',
        transform: 'translateY(-100vh)'
    },
    center: {
        textAlign: 'center',
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    topTitle: {
        fontSize: 24,
        fontWeight: 28,
        paddingBottom: 12,
        width: '100%'
    },
    title: {
        fontSize: 36,
        fontWeight: 400,
        paddingBottom: 12
    },
    date: {
        fontSize: 24,
        fontWeight: 400
    },
    arrow: {
        visibility: 'hidden',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(100px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
    carousel: {
        width: '100%',
        background: 'black'
    },
    language: {
        transform: 'translate(-100px,90vh)'
    },
    loading: {
        background: 'black',
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        //background:'red'
    },
    circleLoading: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%'
    }
}))

const MainBanner = ({ banners, content, onClickBanner }) => {

    const classes = styles()

    const [language, setLanguage] = useState('en')

    return (
        <div className={classes.root}>
            {banners ?
                <div className={classes.imageBanner}>
                    <CustomCarousel data={banners} onItemSelected={onClickBanner} noImage={content.noImage} />
                </div>
                :
                <div className={classes.loading}><CircularProgress size={32} color='secondary' className={classes.circleLoading} /></div>
            }
            <div className={classes.content}>
                <Grid container>
                    <Grid item xs>
                    </Grid>
                    <Grid item>
                        <div className={classes.language}>
                            <LanguageButton
                                value={language}
                                onChange={(v) => setLanguage(v)}
                                {...content.language_button}
                            />
                        </div>
                    </Grid>
                </Grid>

            </div>
        </div >
    )
}

export default MainBanner