import React, { useEffect, useState } from "react"
import { Button, Card, CardActionArea, CardContent, Divider, Grid, Icon, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import { useTheme } from "@material-ui/styles"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import CustomCarousel from "./CustomCarousel"

const styles = makeStyles(theme => ({
    root: {
        margin: '40px 0px',
        //paddingLeft: 100,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 0
        }
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    noArtist: {
        fontSize: 16,
        fontWeight: 300
    },
    carouselItem: {
        height: 650,
        marginTop: -100,
        '& li': {
            margin: 10,
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                padding: 10,
                boxSizing: 'border-box'
            }
        },
        '& ul.react-multi-carousel-dot-list': {
            top: 0,
            marginTop: 600,
            height: 'fit-content',
        }
    },
    lineButton: {
        width: 120,
        height: 1.5,
        borderRadius: 0,
        //marginLeft: 10,
        '&:hover': {
            cursor: 'pointer'
        }
    },
}))


const NewArtists = ({ history, artists, content, onArtistSelected }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const tablet = useMediaQuery(theme.breakpoints.only('md'))
    
    return (
        <div className={classes.root}>
            <MainContainer>
                <div style={{marginBottom:16}}> 
                    <Grid container alignItems='center' spacing={3}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title} align='right'>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </div>
                
            </MainContainer>
            <div>
                <CustomCarousel data={artists} onItemSelected={onArtistSelected}/>
            </div>
        </div >
    )
}

export default NewArtists