import React from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 100,
        paddingBottom: 100,
        margin: 'auto',
        boxSizing: 'border-box',
        [theme.breakpoints.up('xl')]: {
            width: 1600
        },
        [theme.breakpoints.down('lg')]: {
            width: 1200,
            //margin:'auto'
        },
        [theme.breakpoints.down('md')]: {
            padding: 100,
            width: '100%'
        },
        [theme.breakpoints.only('xs')]: {
            padding: 32
        }
    }
}))

const PageContainer = props => {

    const classes = styles()

    return (
        <div className={classNames(classes.root, props.className)}>
            {props.children}
        </div>
    )
}

export default PageContainer