import { makeStyles, Checkbox, Grid, Typography, Slide } from '@material-ui/core'
import React from 'react'
import RoundedButton from '../../../../../../components/Actions/RoundedButton'
import InputForm from '../../../../../../components/Forms/InputFormW'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { grey } from '@material-ui/core/colors';

const styles = makeStyles(theme => ({
    root: {
        bottom: 0,
        width: '100%',
        position:'absolute',
        [theme.breakpoints.only('xs')]: {
            position:'relative'
        }
    },
    title: {
        fontSize: 48,
        fontWeight: 600,
        '@global span': {
            color: theme.palette.secondary.main,
            fontWeight: 700,
        }
    },
    subtitle: {
        fontSize: 26,
        fontWeight: 600
    },
    input: {
        backgroundColor: '#F4F4F4',
        padding: '10px 24px 10px 24px',
        borderRadius: 10
    },
}))

const Step2 = ({ history, checked, form, onChange, reference }) => {

    const classes = styles()

    return (
        <Slide direction={'right'} in={checked} mountOnEnter unmountOnExit container={reference}>
            <div className={classes.root}>
                <div className={classes.input}>
                    <InputForm data={form.first_name} variant='standard' onChange={onChange} />
                </div>
            </div>
        </Slide>
    )
}

export default Step2