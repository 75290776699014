import React from "react"
import { alpha, Grid, makeStyles, Typography } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"

const styles = makeStyles(theme => ({
    root: {
        padding:100,
        paddingTop:150,
        paddingBottom: 42,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 32,
            paddingRight: 32,
            paddingBottom: 64,
        }
    },
    title: {
        //fontSize: 48,
        fontWeight: 600,
        textTransform: 'uppercase',
        color: alpha('#BD8B40',0.5),
        letterSpacing: '0.065em',
        [theme.breakpoints.only('xs')]: {
            fontSize: 40
        }
    },
    subtitle: {
        color: grey[900],
        fontSize: 16,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    }
}))

const Header = ({ history, content }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <Grid container spacing={6} justifyContent='center'>
                <Grid item xs={12}>
                    <Typography variant='h4' className={classes.title} align='right'>
                        {content.title}
                    </Typography>
                    <Typography className={classes.subtitle} align='right'>
                        {content.caption}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Header