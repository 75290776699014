import React from "react"
import { Card, CardActionArea, CardContent, CardMedia, Divider, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from "@material-ui/styles"
import { grey } from "@material-ui/core/colors"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"

const styles = makeStyles(theme => ({
    root: {
        //minHeight: '80vh',
        //paddingBottom:100,
        marginTop:80
    },
    content: {
        //padding: 100,
        position: 'relative',
        //[theme.breakpoints.only('xs')]: {
        //    paddingLeft: 32,
        //    paddingRight: 32,
        //}
    },
    center: {
        minWidth: 220,
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%,-50%)'
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    image: {
        width: '100%',
        height: 'auto',
        filter: 'brightness(50%)'
    },
    imageContainer: {
        position: 'relative'
    },

}))

const Magazines = ({ history, content }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div className={classes.root}>
            <MainContainer>
                <div className={classes.content}>
                    <Grid container justifyContent='center' spacing={8}>
                        <Grid item xs={12}>
                            <Grid container alignItems='center' spacing={3}>
                                <Grid item xs>
                                    <Divider className={classes.divider} />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} align='right'>
                                        {content.title}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Divider className={classes.divider} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {content.gallery.map((magazine, key) => (
                            <Grid item xs={12} sm={4} key={`magazine.exhibition${key}`}>
                                <MagazineCard {...magazine} />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </MainContainer>
            
        </div>
    )
}

export default Magazines

const stylesCard = makeStyles(theme => ({
    root: {
        maxWidth: 376,
        boxShadow:'none',
        [theme.breakpoints.only('xs')]:{
            width:'100%'
        },
        '&:hover':{
            cursor:'pointer'
        }
    },
    media: {
        height:'100%',
        minHeight: 537,
    },
    title: {
        fontSize: 26,
        fontWeight: 600
    },
    subtitle: {
        fontSize: 24,
        fontWeight: 400
    }
}))

const MagazineCard = ({ title, subtitle, image, url }) => {

    const classes = stylesCard()

    return (
        <Card className={classes.root} onClick={() => window.open(url)}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={image}
                    title={"Magazine Exhibition"}
                />
                <CardContent>
                    <Typography gutterBottom className={classes.title}>
                        {title}
                    </Typography>
                    <Typography className={classes.subtitle}>
                        {subtitle}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}