import React from "react"
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import RoundedButton from "../../../../components/Actions/RoundedButton"

const styles = makeStyles(theme => ({
    root: {
        background: 'black',
        color: 'white',
        paddingTop: 80,
        paddingBottom: 80,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        },
    },
    title: {
        fontSize: 36,
        fontWeight: 600,
        letterSpacing: '0.065em',
        '& span': {
            color: theme.palette.secondary.main,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 32
        }
    },
    itemList: {
        fontSize: 20,
        fontWeight: 500,
    },
    image: {
        width: '100%',
        height: '100%',
        maxHeight: 600,
        maxWidth: 500,
        objectFit: 'cover',
        objectPosition: 'top',
        boxShadow: `25px 31px 0px -1px ${theme.palette.secondary.dark}`
    }
}))

const FreeClasses = ({ history, content, onUpdateDrawerSignUp }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container spacing={6} justifyContent='center'>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography className={classes.title}>
                                    {content.title[0]} <span>{content.title[1]}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {content.list.map(item => (
                                    <Typography className={classes.itemList}>
                                        - {item}
                                    </Typography>
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                <RoundedButton color='primary' onClick={() => onUpdateDrawerSignUp(true)}>
                                    {content.button}
                                </RoundedButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <img src={content.image} className={classes.image} />
                    </Grid>
                </Grid>
            </MainContainer>
        </div>
    )
}

export default FreeClasses