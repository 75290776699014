import React from 'react'
import { Grid, IconButton, makeStyles, MenuItem, Select, SvgIcon } from '@material-ui/core'
import arrowIcon from '../../../assets/icons/arrow_down.svg'
import SVGIcon from '../DisplayData/SVGIcon'


const styles = makeStyles(theme => ({
    select_root: {
        padding: 5,
        paddingLeft: 10,
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        background: 'white',
    },
    select_filled: {
        borderRadius: 2,
        boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.09)',
    },
    select_icon: {
        top: 0,
        right: 0,
        padding: 10,
    }
}))


const ActionTopBar = ({ filter, filterValue, onFilter, search, searchValue, onSearch, add, onAdd }) => {

    const classes = styles()

    const handleChange = (event) => {
        onFilter(event.target.value)
    }

    return (
        <Grid container spacing={2}>
            {filter &&
                <Grid item>
                    <Select
                        autoWidth
                        disableUnderline
                        variant="filled"
                        value={filterValue}
                        onChange={handleChange}
                        IconComponent={(props) => (
                            <IconButton {...props}>
                                <SVGIcon src={arrowIcon} size={12} colorStroke='black' />
                            </IconButton>
                        )}
                        classes={{
                            root: classes.select_root,
                            filled: classes.select_filled,
                            icon: classes.select_icon
                        }}
                    >
                        {filter.map(item => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                        ))}
                    </Select>
                </Grid>
            }
            {
                search &&
                <Grid item>
                    SEARCH INPUT HERE
                </Grid>
            }
        </Grid >
    )
}

export default ActionTopBar