import React, { useState } from "react"
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, Grid, IconButton, makeStyles, Tooltip, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from "@material-ui/styles"
import { grey } from "@material-ui/core/colors"
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { CONFIG_SETTINGS } from "../../../config"
import { isValidDocument, onGetCurrencyValue, onValidateURL } from "../../../shared/utility"
import RoundedButton from "../../../components/Actions/RoundedButton"
import SVGIcon from "../../../components/Structure/DisplayData/SVGIcon"
import cx from 'classnames'
import GenericImage from "../../../components/Structure/DisplayData/GenericImage"
import ethereumIcon from '../../../assets/icons/eth_white.svg'

const stylesCard = makeStyles(theme => ({
    root: {
        //maxWidth: 450,
        boxShadow: 'none',
        //margin: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        }
    },
    cardActionArea: {
        //pointerEvents: 'none',
        position: 'relative'
    },
    cardContent: {
        width: '100%',
        display: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    showCardContent: {
        display: 'block'
    },
    media: {
        background: 'grey',
        paddingTop: '100%',
        width: '100%',
        height: '100%',
        display: 'block',
        borderRadius: 6,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    mediaH: {
        filter: 'brightness(0.5)'
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
        marginBottom: 0
    },
    technique: {
        fontSize: 18,
        fontWeight: 400,
    },
    dimensions: {
        fontSize: 16,
        fontWeight: 600,
    },
    nft_button: {
        background: theme.palette.secondary.main,
        width: 48, height: 48,
        position: 'relative',
        boxSizing: 'border-box',
        cursor: 'pointer',
        borderRadius: '100%',
        padding: 10,
        '&:hover': {
            background: theme.palette.secondary.dark
        }
    },
    nft_button_grey: {
        background: grey[500],
        cursor: 'default',
        '&:hover': {
            background: grey[500],
        }
    },
    soldButton: {
        textTransform: 'uppercase'
    },
    buyButton: {
        textTransform: 'capitalize'
    },
    tooltip_arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}))

const ArtworkCard = ({ content, title, technique, dimensions, price, sold, nft, nft_sold, likes,
    external_url, ethereum, web_image, mobile_image, noImage, onBuy, onOpenArtWork }) => {

    const classes = stylesCard()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [hover, setHover] = useState(false)
    const [buttonHover, setButtonHover] = useState(false)
    const [showEthereum, setShowEthereum] = useState(false)

    const _image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(_image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : noImage

    const onOpenNFT = async () => {
        const isValidLink = onValidateURL(external_url)
        if (isValidLink)
            window.open(external_url)
        else
            onOpenArtWork()

    }

    return (
        <Card className={classes.root}>
            <CardActionArea
                className={classes.cardActionArea}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={onOpenArtWork}
            >
                <GenericImage src={_image} style={{ background: 'black' }} lowBrightness={hover} />
                <CardContent className={cx({
                    [classes.cardContent]: true,
                    //    [classes.showCardContent]: hover
                })}>
                    <Grid container justifyContent='center' alignContent='center' spacing={2}>
                        <Grid item>
                            <ActionButton {...content.buttons.share} />
                        </Grid>
                        <Grid item>
                            <ActionButton icon={content.buttons.like.icon} label={likes} />
                        </Grid>
                        <Grid item>
                            <ActionButton {...content.buttons.scan} />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ background: 'transparent', padding: 0, paddingTop: 12 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm>
                        <Typography gutterBottom className={classes.title}>
                            {title}
                        </Typography>
                        <Typography className={classes.technique}>
                            {technique}
                        </Typography>
                        <Typography className={classes.dimensions}>
                            {dimensions}
                        </Typography>
                    </Grid>
                    {Boolean(Number(nft)) &&
                        <Grid item>
                            <Tooltip
                                arrow
                                classes={{
                                    tooltip: classes.tooltip,
                                    arrow: classes.tooltip_arrow
                                }}
                                title={
                                    <Grid container justifyContent='center' alignItems='center'>
                                        <Grid item><SVGIcon src={ethereumIcon} size={15} /></Grid>
                                        <Grid item><Typography style={{ color: 'white' }}>{ethereum}</Typography></Grid>
                                    </Grid>
                                }
                                placement="top"
                            >
                                <IconButton
                                    className={cx({
                                        [classes.nft_button]: true,
                                        [classes.nft_button_grey]: nft_sold > 0
                                    })}
                                    onClick={onOpenNFT}
                                //onClick={() => window.open(external_url, '_target')}
                                >
                                    <SVGIcon src={content.nft} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    }
                    <Grid item>
                        {sold ?
                            <RoundedButton color='black'>
                                <Typography className={classes.soldButton}>
                                    {content.sold}
                                </Typography>
                            </RoundedButton>
                            :
                            <RoundedButton
                                color='transparent_primary'
                                onMouseEnter={() => setButtonHover(true)}
                                onMouseLeave={() => setButtonHover(false)}
                                style={{ minWidth: 150 }}
                                onClick={onBuy}
                            >
                                <Typography className={classes.buyButton}>
                                    {buttonHover ? content.buy : onGetCurrencyValue(price ? price : '', 0, '€ ')}
                                </Typography>
                            </RoundedButton>
                        }
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}

export default ArtworkCard

const ActionButton = ({ label, icon, onClick }) => (
    <React.Fragment>
        <IconButton onClick={onClick}>
            <SVGIcon src={icon} size={32} color='white' style={{ margin: 'auto' }} />
        </IconButton>
        <Typography align='center' style={{ color: 'white', fontSize: 14, fontWeight: 500 }}>
            {label}
        </Typography>
    </React.Fragment>
)