import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = makeStyles(theme => ({
    card: {
        position: 'relative',
        witdth: '100%',
        height: 'auto',
        padding: 32,
        minHeight: 15,
        background: 'rgba(189, 139, 64, 0.24)',
        borderRadius: '11px 11px 0px 0px'
    },
    title: {
        fontSize: 32,
        fontWeight: 700,
        textTransform: 'uppercase',
        [theme.breakpoints.only('xs')]: {
            fontSize: 28
        }
    },
    imageContainer:{
        width:'100%',
        textAlign:'center'
    },
    image: {
        width: 'auto',
        maxHeight: 200,
    }
}))

const Step4 = ({ title, description, image }) => {

    const classes = styles()

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            4. {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {description.map(text => (
                                <Grid item xs={12}>
                                    <Typography variant='body2' align='justify'>
                                        {text}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div className={classes.imageContainer}>
                    <img src={image} className={classes.image} />
                </div>
            </Grid>
        </Grid>
    )
}

export default Step4