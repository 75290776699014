import React from "react"
import { Button, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import "react-multi-carousel/lib/styles.css"
import { useTheme } from "@material-ui/styles"
import { CONFIG_SETTINGS } from "../../../../config"
import { isValidDocument } from "../../../../shared/utility"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import StopIcon from '@material-ui/icons/Stop'
import StopOutlinedIcon from '@material-ui/icons/StopOutlined'
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"

const styles = makeStyles(theme => ({
    root: {
        color: 'white',
        width: '100%',
        paddingTop: '56%',
        //paddingBottom: 80,
        position: 'relative',
        [theme.breakpoints.only('xs')]: {
            paddingTop: '0%',
            height: '90vh'
        }
    },
    image: {
        background: 'black',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    content: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        padding: 32
    },
    artist: {
        fontSize: 32,
        fontWeight: 600,
        textTransform: 'capitalize',
        [theme.breakpoints.only('xs')]: {
            fontSize: 20
        }
    },
    title: {
        fontSize: 24,
        fontWeight: 400,
        letterSpacing: '0.265em',
        [theme.breakpoints.only('xs')]: {
            fontSize: 16
        }
    },
    center: {
        width: '100%',
        boxSizing: 'border-box',
        position: 'absolute',
        top: '50%',
        left: 0,
        padding: 32,
        textAlign: 'center',
        transform: 'translateY(-50%)'
    },
    bottom: {
        width: '100%',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: 0,
        left: 0,
        padding: 32,
    },
    level: {
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: '0.165em',
        [theme.breakpoints.only('xs')]: {
            fontSize: 14,
        }
    },
    levelInd: {
        fontSize: 12
    },
    noClasses: {
        fontSize: 16,
        fontWeight: 300
    },
    shareButton: {
        fontSize: 16,
        color: 'white',
        textTransform: 'none',
        padding: 10,
        textAlign: 'center',
        borderRadius: 12,
        '&:hover': {
            background: 'rgba(0,0,0,0.2)'
        }
    }
}))

const MainBanner = ({ history, masterclass, content, onGoToUrl }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))

    const { artist, title, url, masterclass_level, masterclass_level_id,
        web_image, mobile_image, duration } = masterclass

    const image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : content.noImage

    let level = []
    for (let i = 0; i < 5; i++) {
        level.push(i < masterclass_level_id ?
            <StopIcon className={classes.levelInd} /> :
            <StopOutlinedIcon className={classes.levelInd} />)
    }

    return (
        <div className={classes.root}>
            {masterclass ?
                <div>
                    <img src={selectedImage} className={classes.image} />
                    <div className={classes.content}>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item>
                                <Button
                                    className={classes.shareButton}
                                >
                                    <Grid container justifyContent='center' alignItems='center' spacing={1}>
                                        <Grid item>
                                            {content.share.label}
                                        </Grid>
                                        <Grid item>
                                            <SVGIcon src={content.share.icon} size={20} color={'white'} />
                                        </Grid>
                                    </Grid>
                                </Button>

                            </Grid>
                            <Grid item>
                                <Typography className={classes.level}>
                                    {masterclass_level}
                                </Typography>
                                {level}
                            </Grid>
                        </Grid>
                        <div style={{ height: '100%' }}>
                            <div className={classes.center}>
                                <Grid container justifyContent='center' spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.artist} align='center'>
                                            {artist}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.title} align='center'>
                                            {title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <StopIcon fontSize='small' />
                                    </Grid>
                                    <Grid item>
                                        <RoundedButton>
                                            {content.getItFree}
                                        </RoundedButton>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={classes.bottom}>
                                <Grid container justifyContent='flex-end' spacing={1}>
                                    <Grid item>
                                        <Typography variant="body2" align='center'>
                                            {duration} mins
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <SVGIcon src={content.clock} size={20} color={'white'} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div >
                :
                <Typography className={classes.noClasses} align='center'>
                    {content.noContent}
                </Typography>
            }
        </div >
    )
}

export default MainBanner