import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import InputFormW from "../../../components/Forms/InputFormW"
import RoundedButton from "../../../components/Actions/RoundedButton"
import SVGIcon from "../../../components/Structure/DisplayData/SVGIcon"

const styles = makeStyles(theme => ({
    root: {
        marginTop: 50
    },
    title: {
        fontSize: 27,
        fontWeight: 700
    },
    description: {
        fontSize: 24,
        fontWeight: 400,
        '& span': {
            fontWeight: 600
        }
    },
    emailImage: {
        width: 150,
        height: 150,
        background: 'linear-gradient(90deg, #59411e 50%, #6f5226 50%)',
        borderRadius: '100%',
        margin: 'auto'
    }
}))

const Completed = ({ user, content, actions }) => {
    const classes = styles()

    return (
        <div className={classes.root}>
            <Grid container justifyContent='center' alignItems='center' spacing={5}>
                <Grid item xs={12}>
                    <Typography className={classes.title} align='center'>{content.title}</Typography>
                </Grid>
                <Grid item>
                    <div className={classes.emailImage}>
                        <SVGIcon src={content.image} size={80} style={{ margin: 'auto', transform: 'translateY(50%)' }} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.description} align='center'>
                        {content.description[0]} <span>{user ? user.email : '-'}</span> {content.description[1]}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.description} align='center'>
                        {content.description[2]} <span>{content.description[3]} </span>
                    </Typography>
                </Grid>
                <Grid item>
                    <RoundedButton onClick={actions.onClose}>
                        {content.button}
                    </RoundedButton>
                </Grid>
            </Grid>
        </div>
    )
}

export default Completed