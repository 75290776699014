import bannerImage from '../../../assets/community/banner.jpeg'

import showYourTalentImage from '../../../assets/community/show-your-talent.png'

import documentIcon from '../../../assets/icons/file.svg'
import clickingIcon from '../../../assets/icons/clicking.svg'
import virtualIcon from '../../../assets/icons/virtual.svg'
import mobile360Icon from '../../../assets/icons/mobile.svg'

import membership1 from '../../../assets/community/membership1.jpg'
import membership2 from '../../../assets/community/membership2.jpg'
import membership3 from '../../../assets/community/membership3.jpg'
import membership4 from '../../../assets/community/membership4.jpg'

import deliveryIcon from '../../../assets/icons/delivery.svg'
import checkIcon from '../../../assets/icons/check_step.svg'
import mailIcon from '../../../assets/icons/envelope.svg'
import welcomeIcon from '../../../assets/icons/welcome.svg'

import kuvikArt from '../../../assets/community/kuvik_art.png'

export const translations = {
    main_info_section: {
        title: 'Be part of',
        kuvik: 'kuvik galerie',
        description: 'The revolutionary Art-Tech Community',
        banner: bannerImage
    },
    show_your_talent_section: {
        title: 'SHOW YOUR TALENT',
        titleS: ['Who', 'you are?'],
        image: showYourTalentImage,
        art: 'art',
        description: 'We are looking forward to meeting you',
        list: [
            'Artists with unique, innovative, and inspiring art proposals',
            'Artists from different artistic backgrounds - Fine arts, sculpture, photograph, drawing & more ...',
            'Artists looking for a tech partner to promote their work and share their talent internationally.',
            'Artists interested in digitizing, and/or selling  their artworks'
        ],
        button: 'Join us',
    },
    member_benefits_section: {
        title: 'MEMBER BENEFITS',
        description: 'Enjoy the benefits of being part of our distinguished Artistic Community and take advantage of the resources at your disposal to boost your career.',
        options: [
            {
                icon: documentIcon,
                image: membership1,
                title: 'Art Profile',
                description: ['All KUVIK member, have their own space within our platform'],
                list: [
                    'Create your personal art space',
                    'Publish your Biography and artist statement ',
                    'Post and share your artworks ',
                    'Sell art through our marketplace'
                ]
            },
            {
                icon: virtualIcon,
                image: membership2,
                title: 'Virtual exhibitions',
                list: [
                    'Free applicaiton to open calls',
                    "Special discounts for virtual events",
                    'Show your artworks in a global scale'
                ],
            },
            {
                icon: mobile360Icon,
                image: membership3,
                title: 'Tech resources',
                list: [
                    "Artworks available in augmented reality.",
                    "Creation and sell of NFT artworks.",
                    "Analytics performance to improve your exposure."
                ],
            },
            {
                icon: clickingIcon,
                image: membership4,
                title: 'Cultural managment',
                list: [
                    "Artistic representation.",
                    "Market positioning",
                    "Artist professional portfolio.",
                ],
            }
        ]
    },
    member_costs_section: {
        title: 'MEMBERSHIP COSTS',
        conditions: 'Prices are subject to change without notice ',
        payment_plans: [
            {
                title: 'Basic',
                price: '$35 / month',
                time: '6 months Contract *',
                benefits: {
                    active: [
                        'Art Profile',
                        'Publish up to 20 artworks',
                        'Free Application to any open call',
                        '10% Discount in all Services (Exhibitions, Magazines and Private Events)',
                    ],
                    unactive: [
                        'Free Access to Masterclasses',
                        '1 Free Collective Magazine'
                    ]
                }
            },
            {
                title: 'Pro',
                price: '$45 / month',
                time: '1 Year Contract *',
                benefits: {
                    active: [
                        'Art Profile',
                        'Publish up to 20 artworks',
                        'Free Application to any open call',
                        '10% Discount in all Services (Exhibitions, Magazines and Private Events)',
                        'Free Access to Masterclasses',
                        '1 Free Collective Magazine'
                    ],
                    unactive: []
                }
            }
        ]
    },
    services_section: {
        title: 'SERVICES',
        services: [
            {
                title: 'Virtual exhibitions',
                description: 'Immersive, vibrant and inspiring virtual exhibitions to share artworks around the world.',
                price: {
                    from: 'From',
                    cost: '$65',
                    currency: 'usd'
                }
            },
            {
                title: 'Magazines',
                description: 'Sharing inspiring stories and revolutionary artworks in KUVIK Magazine.',
                price: {
                    from: 'From',
                    cost: '$50',
                    currency: 'usd'
                }
            }
        ],
        button: 'Contact us'
    },
    application_section: {
        title: 'APPLICATION PROCESS',
        header: [
            {
                icon: mailIcon,
                title: 'APPLICATION',
            },
            {
                icon: checkIcon,
                title: 'ASSESSMENT',
            },
            {
                icon: deliveryIcon,
                title: 'DELIVERY',
            },
            {
                icon: welcomeIcon,
                title: 'WELCOME',
            },
        ],
        steps: [
            {
                title: 'APPLICATION',
                description: [
                    "To become a Kuviks Galerie member, all artists must go through an application process where a holistic evaluation is performed by an international multi-disciplinary committee. The application process ensures that the artist and the institution are aligned in interests, with the aim of creating a fruitful and lasting relationship.",
                    "In case of having any question regarding the application process, you can always contact us and any of our agents will be happy to support you along the process."
                ],
                stepCards: ['ART LOVER', 'ARTIST', 'GALLERY', 'COLLECTOR']
            },
            {
                title: 'ASSESSMENT',
                description: [
                    "Artists are evaluated based on their artistic statement, artworks, background, and achievements in order to reach a verdict.",
                    "We ask all applicant to submit their documents in our platform in order to guarantee a smooth and transparent process.",
                    "We encourage artists to complete their applications in the best possible way. For Kuvik Galerie is important to understand what the art intention is, who’s the creative mind behind each piece and how we could positively impact your career, therefore feel free to add any complementary information that might be relevant to your application.",
                    "Beyond the artworks we are looking for highly passionate, creative, and inspiring persons."
                ],
                stepCards: ['PERSONAL INFO', 'ARTWORK', 'ABOUT YOU']
            },
            {
                title: 'Delivery',
                description: [
                    "The evaluation process takes between 2 and 4 weeks. During this period of time, no action is required from the applicant.",
                    "Should the committee require additional information, the gallery will contact the artist via the “contact information” provided in the application form.",
                    "Once the committee has reached a verdict, the results will be sent to the corresponding email address. In case of acceptance, the artist will receive the welcome email and the contract that celebrates the collaboration between the artist and the gallery."
                ],
                stepCards: ['WELCOME MAIL', 'CONTRACT', 'INTRODUCTION']
            },
            {
                title: 'Welcome',
                description: [
                    "Once the contract has been signed and sent, a Kuvik agent will contact the artist to indicate the next steps to start the exciting journey awaiting ahead.",
                    "We look forward to having you on board and start shaping the future of art."
                ],
                image: kuvikArt
            }
        ]
    },
    register_banner_section: {
        title: 'An inclusive artspace',
        description: 'We offer full and partial scholaships to support the career development of our artists.',
        button: 'Register now',
    },
}