import React, { useState } from 'react';
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { grey } from '@material-ui/core/colors';
import general_translations from '../../../../texts/eng/website/general-translations'
import SVGIcon from '../../../../components/Structure/DisplayData/SVGIcon';
import LanguageButton from '../../../../components/Actions/LanguageButton';
import Welcome from './Welcome/Welcome';
import MainContainer from '../../../../components/Structure/Layouts/MainContainer';

const Footer = ({ history, language, onUpdateLanguage, onUpdateContactModal }) => {

    const classes = useStyles()

    //const [language, setLanguage] = useState('en')
    const content = general_translations.footer

    return (
        <React.Fragment>

            <Welcome language={language} history={history} content={content.welcome} />
            <div style={{ background: 'black' }}>
                <MainContainer>
                    <div className={classes.root}>
                        <Grid container justifyContent='center' spacing={6}>

                            <Grid item xs={12} sm={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' className={classes.subtitle}>
                                            {content.explore.title}
                                        </Typography>
                                    </Grid>
                                    {content.explore.links.map(link => (
                                        <Grid item xs={12} key={`exp-${link.id}`}>
                                            <Link
                                                key={`explore-${link.id}`}
                                                className={classes.link}
                                                onClick={() => history.push(link.url)}
                                            >
                                                {link.label}
                                            </Link>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' className={classes.subtitle}>
                                            {content.about.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} key={`ab-${content.about.links[0].id}`}>
                                        <Link
                                            key={`about-${content.about.links[0].id}`}
                                            className={classes.link}
                                            onClick={() => onUpdateContactModal(true)}
                                        >
                                            {content.about.links[0].label}
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} key={`ab-${content.about.links[1].id}`}>
                                        <Link
                                            key={`about-${content.about.links[1].id}`}
                                            className={classes.link}
                                            onClick={() => history.push(content.about.links[1].url)}
                                        >
                                            {content.about.links[1].label}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Grid container justifyContent='center' spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography align='center' className={classes.subtitle2}>
                                            {content.app.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton className={classes.fileIcon}>
                                            <a href={content.app.document} download>
                                                Download <SVGIcon src={content.app.icon} size={24} style={{ marginLeft: 10 }} />
                                            </a>
                                        </IconButton>
                                    </Grid>
                                    {/* <Grid item>
                                        <img src={content.app.ios} className={classes.donwloadImage} />
                                    </Grid>
                                    <Grid item>
                                        <img src={content.app.android} className={classes.donwloadImage} />
                                </Grid>*/}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Grid container justifyContent='center' spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6' className={classes.subtitle2}>
                                            {content.social_media.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            {content.social_media.media.map((media, key) => (
                                                <Grid item key={`media-k.${key}`}>
                                                    <IconButton onClick={() => window.open(media.url, media.target)}>
                                                        <SVGIcon src={media.icon} color='white' />
                                                    </IconButton>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent='space-between' alignItems='flex-end'>
                                    <Grid item>
                                        <Grid container justifyContent='center' alignItems='flex-end' spacing={2}>
                                            <Grid item>
                                                <img src={content.logo} className={classes.logo} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <SVGIcon src={content.secure.icon} size={16} color='white' />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2' className={classes.small_text}>
                                                    {content.secure.title[0]} <span>{content.secure.title[1]}</span>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='body2' className={classes.small_text}>
                                            {content.brand}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <LanguageButton
                                            value={language}
                                            onChange={(v) => onUpdateLanguage(v)}
                                            {...content.language_button}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </MainContainer>
            </div>


        </React.Fragment>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'black',
        color: 'white',
        padding: '60px 0px',
        minHeight: 200,
        color: 'black'
    },
    subtitle: {
        color: '#A0A0A0',
        fontSize: 21,
        fontWeight: 700
    },
    subtitle2: {
        fontSize: 18,
        color: 'white',
        letterSpacing: '0.14em'
    },
    fileIcon: {
        fontSize: 14,
        border: `1px solid white`,
        borderRadius: 12,
        '& a': {
            display: 'contents',
            color: 'white',
            textDecoration: 'none',
        },
        '&:hover': {
            background: `${theme.palette.secondary.main}40`,
            border: `2px solid ${theme.palette.secondary.main}`,
            cursor: 'pointer'
        }
    },
    text: {
        fontSize: 16,
        fontWeight: 700
    },
    small_text: {
        color: 'white',
        fontSize: 10,
        '& span': {
            fontWeight: 600,
            fontSize: 12
        }
    },
    links: {
        display: 'flex',
        color: 'white',
        fontFamily: theme.typography.fontFamily,
        color: 'white',
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    link: {
        marginRight: 8,
        //fontWeight:500,
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    media_icon: {
        width: 40
    },
    donwloadImage: {
        width: 200,
        height: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        }
    },
    logo: {
        width: 45,
        heigth: 'auto'
    }
}))

export default Footer;