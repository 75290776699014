import bannerImage from '../../../assets/artist/banner.png'
import noImage from '../../../assets/artist/noImage.png'
import arrowDownIcon from '../../../assets/icons/arrow_down.svg'

import documentIcon from '../../../assets/icons/file.svg'
import portfolioIcon from '../../../assets/icons/portfolio.svg'
import nftIcon from '../../../assets/icons/NFT.svg'

import shareIcon from '../../../assets/icons/share.svg'
import scanIcon from '../../../assets/icons/scan.svg'
import likeIcon from '../../../assets/icons/full_heart.svg'

import FB from '../../../assets/icons/facebookWhite.svg'
import IG from '../../../assets/icons/instagramWhite.svg'
import TW from '../../../assets/icons/twitterWhite.svg'
import TT from '../../../assets/icons/tiktok.svg'
import PT from '../../../assets/icons/pinterest.svg'
import YT from '../../../assets/icons/youtube.svg'
import WE from '../../../assets/icons/emblemweb.svg'
import OP from '../../../assets/icons/opeansea.svg'
import VM from '../../../assets/icons/vimeo.svg'

export const translations = {
    main_info_section: {
        title: 'Vanessa Vilchis García',
        subtitle: 'MXN',
        banner: bannerImage
    },
    abstract_section: {
        title: 'Why us',
        noImage: noImage,
        arrow: arrowDownIcon,
        full: 'Know more',
        documents: {
            cv: {
                label: 'CV',
                icon: documentIcon
            },
            portfolio: {
                label: 'Portafolio',
                icon: portfolioIcon
            }
        }
    },
    artwork_section: {
        title: 'Artwork',
        noImage: noImage,
        noData: "This artist don't have any artwork yet.",
        sold: 'Sold',
        buy: 'buy',
        nft: nftIcon,
        buttons: {
            share: {
                label: 'Share',
                icon: shareIcon,
            },
            like: {
                icon: likeIcon
            },
            scan: {
                label: 'AR',
                icon: scanIcon
            }
        }
    },
    virtual_section: {
        title: 'Virtual exhibitions',
        noImage: noImage,
        noContent: 'Sorry! There are not virtual exhibitions yet.',
        document: 'Document',
        heart: likeIcon,
        visit:'Visit'
    },
    magazines_section: {
        title: 'Magazines',
        noImage: noImage,
        noContent: 'Sorry! There are not magazines yet.',
        open: 'Open',
        
    },
    masterclasses_section: {
        title: 'Masterclasses',
        noImage: noImage,
        noContent: 'Sorry! There are not magazines yet.',
        public: 'Public'
    },
    biography_section: {
        title: 'Biography',
        noImage: noImage,
        arrow: arrowDownIcon,
        readMore: 'Read more',
        //full: 'Read full bio',
        media: [
            { id: 1, key: 'facebook', icon: FB, target: '_blank' },
            { id: 2, key: 'instagram', icon: IG, target: '_blank' },
            { id: 3, key: 'twitter', icon: TW, target: '_blank' },
            { id: 4, key: 'tiktok', icon: TT, target: '_blank' },
            { id: 5, key: 'pinterest', icon: PT, target: '_blank' },
            { id: 6, key: 'youtube', icon: YT, target: '_blank' },
            { id: 7, key: 'web', icon: WE, target: '_blank' },
            { id: 8, key: 'opensea', icon: OP, target: '_blank' },
            { id: 9, key: 'vimeo', icon: VM, target: '_blank' },
        ],
    },
}