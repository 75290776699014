import React, { useState, useEffect } from 'react'
import { translations as translations_esp } from '../../../texts/eng/website/masterclasses-translations'
import { onGetErrorMessage, onValidateURL } from '../../../shared/utility'
import queryString from 'query-string'
import { public_server } from '../../../config'

const useMasterclassesView = ({ history, onUpdateDrawerSignUp }) => {

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [top_classes, setTopClasses] = useState([])
    const [new_classes, setNewClasses] = useState([])
    const [masterclasses, setClasses] = useState([])

    useEffect(() => {
        actions.initModule()
    }, [])

    const actions = {
        initModule: async () => {
            window.scrollTo(0, 0)
            try {
                setLoading(true)

                //NEW CLASSES
                let filterArgs = {
                    AND: [
                        { field: "new", operator: "=", value: 1 },
                        { field: "masterclass_status_id", operator: "=", value: 4 },
                    ]
                }

                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                let params2send = { limit: 5, offset: 0, order: 1, order_by: 'id_masterclass' }
                let params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`

                const _new_classes = await public_server.get(`/masterclass/all/ns${params}`)
                setNewClasses([..._new_classes.data.data.masterclasses])

                //TOP CLASSES
                filterArgs = {
                    AND: [
                        { field: "top", operator: "=", value: 1 },
                        { field: "masterclass_status_id", operator: "=", value: 4 },
                    ]
                }

                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                params2send = { offset: 0, order: 1, order_by: 'id_masterclass' }
                params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
                const _top_classes = await public_server.get(`/masterclass/all/ns${params}`)
                setTopClasses([..._top_classes.data.data.masterclasses])

                //MASTER CLASSES
               /* filterArgs = {
                    AND: [
                        { field: "masterclass_status_id", operator: "=", value: 5 },
                    ]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))*/
                params2send = { offset: 0, order: 1, order_by: 'id_masterclass' }
                params = `?${queryString.stringify(params2send)}`//&filter=${filterArgs}`
                const _masterclasses = await public_server.get(`/masterclass/all/ns${params}`)
                setClasses([..._masterclasses.data.data.masterclasses])
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseError: () => setError(null),
        onCloseSuccess: () => setSuccess(false),
        onUpdateClasses: async (form) => {
            try {
                //setSending(true)
                let filterArgs = {
                    AND: []
                }
                /* let filterArgs = {
                     AND: [
                         { field: "masterclass_status_id", operator: "=", value: 5 },
                     ]
                 }
 */
                Object.keys(form).map(id => {
                    if (form[id].value)
                        filterArgs.AND.push({ "field": "masterclass_category_id", "operator": "=", "value": `${form[id].config.value}` })
                })

                const params2send = { offset: 0, order: 0, order_by: 'id_masterclass' }
                let params = null

                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                params = filterArgs.AND.length > 0 ? `?${queryString.stringify(params2send)}&filter=${filterArgs}` : `?${queryString.stringify(params2send)}`


                const _masterclasses = await public_server.get(`/masterclass/all/ns${params}`)
                //const _top_classes = await request_artist_info(params)
                setClasses([..._masterclasses.data.data.masterclasses])
                // setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onGoToUrl: (url) => {
            if (url)
                history.push(`/masterclass/${url}`)
        }
    }

    const system = { loading, success, error }
    const view_data = { top_classes, new_classes, masterclasses }

    return { system, view_data, actions }
}

export default useMasterclassesView