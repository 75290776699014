import React, { useState } from "react"
import { Button, Divider, Grid, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import { useTheme } from "@material-ui/styles"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import { grey } from "@material-ui/core/colors"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import { CONFIG_SETTINGS } from "../../../../config"
import { isValidDocument } from "../../../../shared/utility"
import cx from 'classnames'

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
        //backgroundColor: '#FCFCFC'
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    noContent: {
        fontSize: 16,
        fontWeight: 300
    },
    seeMoreButton: {
        display: 'block',
        margin: 'auto',
        borderRadius: 12
    },
    more: {
        color: theme.palette.secondary.main,
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: '0.525em'
    },
}))

const Current = ({ history, content, exhibitionsData, disabled,
    onVisitExhibition, onSaveExhibition, onDownloadExhibition }) => {

    const classes = styles()
    const theme = useTheme()

    const { count, exhibitions } = exhibitionsData
    const [seeMore, setSeeMore] = useState(false)

    const more = count > 2
    const _exhibitions = (!seeMore && more) ? exhibitions.slice(0, 2) : exhibitions

    const seeMoreButton = (
        <Grid item>
            <IconButton onClick={() => setSeeMore(prev => !prev)} className={classes.seeMoreButton}>
                <div className={classes.seeMoreButton}>
                    {seeMore ?
                        <SVGIcon
                            size={54}
                            src={content.moreIcon}
                            colorStroke={theme.palette.secondary.main}
                            style={{ margin: 'auto', transform: 'rotate(180deg)' }}
                        />
                        :
                        <SVGIcon
                            size={54}
                            src={content.moreIcon}
                            colorStroke={theme.palette.secondary.main}
                            style={{ margin: 'auto' }}
                        />
                    }
                    <Typography className={classes.more} align='center'>
                        {seeMore ? content.see.less : content.see.more}
                    </Typography>
                </div>
            </IconButton>
        </Grid>
    )

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container justifyContent='center' spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title} align='right'>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {count > 0 ?
                        _exhibitions.map((exhibition, key) => (
                            <Grid item xs={12}>
                                <ExhibitionCard
                                    {...exhibition}
                                    odd={key % 2 === 0}
                                    disabled={disabled}
                                    content={content}
                                    onVisit={onVisitExhibition}
                                    onSave={onSaveExhibition}
                                    onDownload={onDownloadExhibition}
                                />
                            </Grid>
                        ))
                        :
                        <Grid item xs={12}>
                            <Typography className={classes.noContent} align='center'>
                                {content.noContent}
                            </Typography>
                        </Grid>
                    }
                    {more && seeMoreButton}
                </Grid>
            </MainContainer>
        </div>
    )
}

export default Current

const styles_card = makeStyles(theme => ({
    title: {
        fontSize: 32,
        fontWeight: 600,
    },
    imageContainer: {
        background: '#FCFCFC',
        position: 'relative',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        borderRadius: 10,
        margin: 'auto',
        [theme.breakpoints.up(450)]: {
            width: 300,
            height: 300,
        },
        [theme.breakpoints.up(700)]: {
            width: 400,
            height: 400,
        },
        [theme.breakpoints.up(900)]: {
            width: 480,
            height: 480,
        },
        [theme.breakpoints.between(960, 1250)]: {
            width: 300,
            height: 300,
        },
        [theme.breakpoints.up(1600)]: {
            width: 600,
            height: 600,
        }
    },
    image: {
        top: 0,
        left: 0,
        position: 'absolute',
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        borderRadius: 10,
        cursor: 'initial',
        [theme.breakpoints.only('xs')]: {
            position: 'inherit'
        }
    },
    imageH: {
        filter: 'brightness(0.5)',
        cursor: 'pointer'
    },
    date: {
        fontSize: 18,
        fontWeight: 500,
        '& span': {
            fontSize: 24,
            fontWeight: 400,
        }
    },
    description: {
        color: '#A5A5A5',
        fontSize: 16,
        fontWeight: 600,
        [theme.breakpoints.up('md')]: {
            lineHeight: '1.8em'
        }
    },
    content: {
        position: 'relative',
        height: '100%'
    },
    buttons: {
        width: '100%',
        position: 'inherit',
        bottom: 0,
        marginTop: 20
    },
    likeContainer: {
        color: 'white',
        display: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    likeContainerH: {
        display: 'block'
    },
    featuredTag: {
        top: 0,
        position: 'absolute',
        right: 20
    }
}))

const ExhibitionCard = ({ content, odd, id_exhibition, url, title, date_text,
    medium_description, web_image, mobile_image, saved, brochure, featured,
    disabled, onVisit, onSave, onDownload }) => {

    const classes = styles_card()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [hover, setHover] = useState(false)

    const validBrochure = isValidDocument(brochure)

    const image = ismobile ? mobile_image : web_image
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : content.noImage

    const imageContent = (
        <Grid item xs={12} md={6}>
            <div
                className={classes.imageContainer}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => onVisit(url)}
            >
                <img src={selectedImage} className={cx({
                    [classes.image]: true,
                    [classes.imageH]: hover,
                })} />
                {featured && featured > 0 ?
                    <div className={classes.featuredTag}>
                        <SVGIcon src={content.featuredIcon} color={hover ? 'white' : theme.palette.secondary.main} size={42} />
                    </div>
                    : null}
                <div className={cx({
                    [classes.likeContainer]: true,
                    //[classes.likeContainerH]: hover
                })}>
                    <Grid container justifyContent='center' alignItems='center' direction='column'>
                        <Grid item>
                            {disabled ?
                                <React.Fragment>
                                    {saved ?
                                        <SVGIcon src={content.buttons.save.full} size={64} color={'white'} />
                                        :
                                        <SVGIcon src={content.buttons.save.empty} size={64} color={'white'} />
                                    }
                                </React.Fragment>
                                :
                                <Button onClick={() => onSave(id_exhibition, saved)}>
                                    {saved ?
                                        <SVGIcon src={content.buttons.save.full} size={64} color={'white'} />
                                        :
                                        <SVGIcon src={content.buttons.save.empty} size={64} color={'white'} />
                                    }
                                </Button>
                            }
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle1'>{saved}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Grid>
    )

    return (
        <Grid container justifyContent='center' spacing={4}>
            {(ismobile || odd) && imageContent}
            <Grid item xs={12} md={6}>
                <div className={classes.content}>
                    <Grid container justifyContent='space-between' alignItems='stretch' direction='column' style={{ height: '100%' }}>
                        <Grid item>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography className={classes.title}>
                                        {title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.date}>
                                        {date_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.description} align='justify'>
                                {medium_description}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <div className={classes.buttons}>
                                {ismobile ?
                                    <Grid container justifyContent='center' spacing={2}>
                                        {validBrochure &&
                                            <Grid item xs={12}>
                                                <RoundedButton
                                                    color='transparent_secondary'
                                                    fullWidth
                                                    onClick={() => onDownload(brochure, `${title}_current`)}
                                                >
                                                    {content.buttons.download}
                                                </RoundedButton>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <RoundedButton fullWidth onClick={() => onVisit(url)}>
                                                {content.buttons.visit}
                                            </RoundedButton>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container justifyContent='center' spacing={6}>
                                        {validBrochure &&
                                            <Grid item>
                                                <RoundedButton
                                                    color='transparent_secondary'
                                                    onClick={() => onDownload(brochure)}
                                                >
                                                    {content.buttons.download}
                                                </RoundedButton>
                                            </Grid>
                                        }
                                        <Grid item>
                                            <RoundedButton onClick={() => onVisit(url)}>
                                                {content.buttons.visit}
                                            </RoundedButton>
                                        </Grid>
                                    </Grid>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            {!ismobile && !odd && imageContent}
        </Grid >
    )
}