import React, { useState } from "react"
import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import { useTheme, withStyles } from "@material-ui/styles"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import StopIcon from '@material-ui/icons/Stop'
import StopOutlinedIcon from '@material-ui/icons/StopOutlined'
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import { CONFIG_SETTINGS } from "../../../../config"
import { isValidDocument } from "../../../../shared/utility"
import ReactPlayer from 'react-player'


const styles = makeStyles(theme => ({
    root: {
        padding: 80,
        background: '#FCFCFC',
        [theme.breakpoints.down('sm')]: {
            padding: 32
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
    },
    divider: {
        backgroundColor: grey[600]
    },
    noClasses: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 16,
        fontWeight: 300
    },
    level: {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '0.165em',
        color: theme.palette.secondary.main,
        [theme.breakpoints.only('xs')]: {
            fontSize: 14,
        }
    },
    levelInd: {
        color: theme.palette.secondary.main,
        fontSize: 12
    },
    master_content: {
        fontSize: 16,
        fontWeight: 700
    },
    heading: {
        fontSize: 18,
        fontWeight: 600,
        letterSpacing: '0.191em'
    },
    secondaryHeading: {
        fontSize: 14,
        fontWeight: 600,
        color: grey[600],
    },
    details: {
        fontSize: 12,
        fontWeight: 400,
        color: grey[600],
    },
    media: {
        background: 'rgb(0 0 0 / 42%)',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        border: 'none',
        '& video': {
            width: '100%',
            height: '100%',
            objectFit: 'fill',
        },
    },
    media_container: {
        position: 'relative',
        width: 250,
        height: 0,
        paddingTop: '75%',
    },
    duration: {
        fontSize: 12,
    },
    masterclass_card: {
        padding: 24,
        boxSizing: 'border-box',
        boxShadow: '0px 4px 37px rgba(0, 0, 0, 0.1)',
        borderRadius: 5
    },
    content: {
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    levelContainer: {
        bottom: 0,
        right: 0,
        position: 'absolute',
        textAlign: 'right'
    }
}))

const NextMasterclass = ({ masterclass, content, onOpenLogIn, onOpenSignUp }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))

    const [play, setPlay] = useState(false)

    const { video, subsequent_masterclass_title, subsequent_masterclass_artist, masterclass_level_id,
        subsequent_masterclass_level, subsequent_masterclass_level_id, web_image, mobile_image, duration } = masterclass

    const image = mobile ? mobile_image : web_image
    let isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : content.noImage

    isValid = isValidDocument(video)
    const _video = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${video}` : null

    let level = []
    for (let i = 0; i < 5; i++) {
        level.push(i < subsequent_masterclass_level_id ?
            <StopIcon className={classes.levelInd} /> :
            <StopOutlinedIcon className={classes.levelInd} />)
    }

    const arrowIcon = <SVGIcon src={content.arrow} size={15} colorStroke={'black'} />
    const expandedIcon = <SVGIcon src={content.arrow} size={15} colorStroke={theme.palette.secondary.main} />


    return (
        <MainContainer className={classes.root}>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        {content.title}
                    </Typography>
                </Grid>
                <Grid item>
                    <div className={classes.masterclass_card}>
                        <Grid container spacing={2} alignItems='stretch' justifyContent="center">
                            <Grid item>
                                <div className={classes.media_container}>
                                    {_video ?
                                        <ReactPlayer
                                            url={_video}
                                            playing={play}
                                            className={classes.media}
                                            light
                                            controls
                                            width='100%' height='100%'
                                            onClickPreview={() => setPlay(true)}
                                        />
                                        :
                                        <Typography className={classes.noClasses} align='center'>
                                            {content.noContent}
                                        </Typography>
                                    }
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={classes.content}>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.heading}>
                                                {subsequent_masterclass_title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.secondaryHeading}>
                                                {subsequent_masterclass_artist}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.duration}>
                                                {duration} {content.minutes}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <SVGIcon src={content.clock} size={14} color={grey[600]} />
                                        </Grid>
                                    </Grid>
                                    <div className={classes.levelContainer}>
                                        <Typography className={classes.level} align='right'>
                                            {subsequent_masterclass_level}
                                        </Typography>
                                        {level}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid >
        </MainContainer >
    )
}

export default NextMasterclass

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {
        background: 'rgba(189, 139, 64, 0.07)',
    },
})(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
    root: {
        borderBottom: 'none',
        minHeight: 56,
        paddingLeft: 20,
        paddingRight: 20,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
            background: 'rgba(189, 139, 64, 0.07)',
        },
    },
}))(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        paddingTop: 0,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 32,
    },
}))(MuiAccordionDetails);