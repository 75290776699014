import { makeStyles, Checkbox, Grid, Typography, Slide } from '@material-ui/core'
import React from 'react'
import InputForm from '../../../../../../components/Forms/InputFormW'
import { grey } from '@material-ui/core/colors';
import SVGIcon from '../../../../../../components/Structure/DisplayData/SVGIcon';

const styles = makeStyles(theme => ({
    root: {
        padding: 25,
        height: '100%',
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 32,
            paddingRight: 32,
        }
    }
}))

const Joined = ({ history, checked, content }) => {

    const classes = styles()

    return (
        <Slide direction={'left'} in={checked} mountOnEnter unmountOnExit>
            <div className={classes.root}>
                <Grid container justifyContent='center'>
                    <Grid item>
                        <SVGIcon src={content.logo} size={130} />
                    </Grid>
                </Grid>
            </div>
        </Slide>
    )
}

export default Joined