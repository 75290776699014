import { Button, Dialog, Grid, Grow, Icon, makeStyles, Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors';
import React from 'react'

const Transition = React.forwardRef(function Transition(props, ref) { return <Grow ref={ref} {...props} timeout={500} />; });

const useStyles = makeStyles(theme => ({
    paper: {
        width: 400,
        height:'auto',
        boxSizing: 'border-box',
        borderRadius: 5,
        padding: theme.spacing(5)
    },
    root: {
        paddingTop: 32,
        paddingBottom: 0
    },
    icon: {
        color: 'white',
        background: 'linear-gradient(90deg, rgba(211,177,127,1) 0%, rgba(211,177,127,1) 50%, rgba(189,139,64,1) 50%, rgba(189,139,64,1) 100%)',
        borderRadius: '50%',
        fontSize: 36,
        padding: 8
    },
    title: {
        fontSize: 18,
        fontWeight: 700,
        paddingTop: 8
    },
    message: {
        fontSize: 16,
        fontWeight: 500,
        color: '#989898',
        paddingTop: 16,
        paddingBottom: 16,
        maxWidth: 300
    },
    button: {
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 700,
        textTransform: 'capitalize'
    }
}))

const ErrorModal = (props) => {

    const classes = useStyles()
    const { open, message, onClose } = props

    return (
        <Dialog open={open} maxWidth='xs' fullWidth classes={{ paper: classes.paper }} className={classes.root}
            TransitionComponent={Transition}>
            <div className={classes.root}>
                <Grid container direction='column' justifyContent='center' alignItems='center' spacing={0}>
                    <Grid item>
                        <div className={classes.iconContainer}>
                            <Icon className={classes.icon}>close</Icon>
                        </div>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title}>Oops...</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title}>There was an error</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.message} align='center'>{message}</Typography>
                    </Grid>
                    <Grid item>
                        <Button size='large' onClick={onClose} className={classes.button}>Continue</Button>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    )
}

export default ErrorModal
