import React, { useEffect, useRef, useState } from 'react';
import { onGetErrorMessage, onValidateURL } from '../../../shared/utility';
import { private_server, public_server } from '../../../config';
import queryString from 'query-string'
import axios from 'axios'
import FileSaver from 'file-saver'

const useArtistProfileView = ({ match, history, user, onUpdateArtworkData, onUpdateModalStatus, onUpdatePurchaseDrawerStatus }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const [page, setCurrentPage] = useState(1)

    const [artistID, setArtistID] = useState(null)
    const [artist, setArtist] = useState([])
    const [mainImage, setMainImage] = useState(null)
    const [profesionalImages, setProfesionalImages] = useState([])
    const [artwork, setArtwork] = useState([])
    const [exhibitions, setExhibitions] = useState([])
    const [masterclasses, setMasterclasses] = useState([])
    const [magazines, setMagazines] = useState([])

    const url = match.params.url

    const bioRef = useRef()

    useEffect(() => {
        actions.initModule()
    }, [])


    const actions = {
        initModule: async () => {
            window.scrollTo(0, 0)
            try {
                setLoading(true)

                //ARTIST
                const _artist = await public_server.get(`artist/url/ns?url=${url}`)

                setArtist({ ..._artist.data.data.artist })
                setMainImage(_artist.data.data.artist.professional_image)
                setArtistID(_artist.data.data.artist.id_user)

                const _artistID = _artist.data.data.artist.id_user

                //ARTIST IMAGES
                let _filter = { offset: 0, order_by: 'id_professional_image', order: 0 }
                //let filterArgs
                //let params
                let filterArgs = {
                    "AND": [{ "field": "user_id", "operator": "=", "value": `${_artistID}` },]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                let params = `?filter=${filterArgs}`
                let response_images = await public_server.get(`professionalimage/all/ns${params}`)

                let _profesionalImages = [...response_images.data.data.professional_images]
                setProfesionalImages(_profesionalImages)
                //console.log(_profesionalImages.data.data.professional_images)
                //if (_profesionalImages.length() > 1) {
                //    setMainImage(_profesionalImages.shift().image)
                //}
                //setProfesionalImages([..._profesionalImages])
                //
                _filter = { offset: 0, order_by: 'id_professional_image', order: 0 }
                ///ARTWORK
                let filter = { offset: 0, order_by: 'id_artwork', order: 0 }
                filterArgs = {
                    "AND": [{ "field": "artist_id", "operator": "=", "value": `${_artistID}` },]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                params = `?${queryString.stringify(filter)}&filter=${filterArgs}`
                let _artwork = await public_server.get(`artwork/all/ns${params}`)
                _artwork = _artwork.data.data.artworks
                const result = new Array(Math.ceil(_artwork.length / 6))
                    .fill()
                    .map(_ => _artwork.splice(0, 6))

                setArtwork([...result])

                ///EXHIBITIONS
                filterArgs = {
                    "AND": [{ "field": "artist_id", "operator": "=", "value": `${_artistID}` },]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                _filter = { offset: 0, order_by: 'id_exhibition_artist', order: 1 }
                params = `?${queryString.stringify(_filter)}&filter=${filterArgs}`
                const _exhibitions = await public_server.get(`exhibitionartist/all/ns${params}`)
                //const _exhibitions = await public_server.get(`exhibitionartist/all/ns`)
                setExhibitions([..._exhibitions.data.data.exhibition_artists])

                ///MASTERCLASSES
                filterArgs = {
                    "AND": [{ "field": "artist_id", "operator": "=", "value": `${_artistID}` },]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                _filter = { offset: 0, order_by: 'id_masterclass_artist', order: 1 }
                params = `?${queryString.stringify(_filter)}&filter=${filterArgs}`
                const _masterclasses = await public_server.get(`masterclassartist/all/ns${params}`)
                setMasterclasses([..._masterclasses.data.data.masterclass_artists])

                ///MAGAZINES
                filterArgs = {
                    "AND": [{ "field": "artist_id", "operator": "=", "value": `${_artistID}` },]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                _filter = { offset: 0, order_by: 'id_magazine_artist', order: 1 }
                params = `?${queryString.stringify(_filter)}&filter=${filterArgs}`
                const _magazines = await public_server.get(`magazineartist/all/ns${params}`)
                setMagazines([..._magazines.data.data.magazine_artists])


            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onGoToBio: () => {
            console.log(bioRef)
            if (bioRef && bioRef.current)
                bioRef.current.scrollIntoView({ behavior: 'smooth' })
        },
        onOpenArtWork: async (data) => {
            console.log('OPEN')
            await onUpdateArtworkData(data)
            await onUpdateModalStatus(true)
        },
        onBuy: async (data) => {
            console.log('BUY')
            await onUpdateArtworkData(data)
            await onUpdatePurchaseDrawerStatus(true)
        },
        onChangePage: (event, page) => setCurrentPage(page),
        onSaveExhibition: async (exhibitionID, saved) => {
            try {
                setSending(true)
                const data = { exhibition_id: exhibitionID }
                let filter = {
                    AND: [
                        { field: "exhibition_id", operator: "=", value: exhibitionID },
                    ]
                }
                filter = JSON.stringify(filter)

                //SI YA ESTA GUARDADA LA QUITAMOS
                if (saved) {
                    const saved_ex = await private_server.get(`savedexhibition/all/me?filter=${filter}`)
                    const saved_exhibition_id = saved_ex.data.data.saved_exhibitions[0].id_saved_exhibition
                    await private_server.delete(`savedexhibition/${saved_exhibition_id}/me`)
                } else
                    await private_server.post(`savedexhibition/me`, data)


                let filterArgs = {
                    "AND": [{ "field": "created_by", "operator": "=", "value": `${artistID}` },]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                let _filter = { offset: 0, order_by: 'id_exhibition', order: 1 }
                let params = `?${queryString.stringify(_filter)}&filter=${filterArgs}`
                const _exhibitions = await public_server.get(`exhibition/all/ns${params}`)
                setExhibitions([..._exhibitions.data.data.exhibitions])
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onDownloadDocument: async (file) => {
            try {
                setSending(true)
                let response = await axios.get(file, { responseType: 'blob' })
                FileSaver.saveAs(response.data, `document`)
                setSending(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onVisitExhibition: (url) => {
            history.push(`/exhibitions/${url}`)
        },
        onCloseError: () => setError(null),
        onCloseSuccess: () => setSuccess(false)

    }

    return {
        user, loading, sending, success, error, actions,
        page, artist, mainImage, profesionalImages, artwork,
        masterclasses, magazines, exhibitions, bioRef
    }
}

export default useArtistProfileView