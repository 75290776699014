import React from "react"
import { Box, Grid, makeStyles, SvgIcon, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import PageContainer from "../../../../components/Structure/Layouts/PageContainer"

const styles = makeStyles(theme => ({
    root: {
        background: '#F0DCBF',
        position: 'relative',
    },
    title: {
        fontSize: 36,
        fontWeight: 700,
        letterSpacing: '0.245em'
    },
    description: {
        fontSize: 20,
        fontWeight: 500,
    },
    buttons: {
        width: 270,
        height: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        }
    }
}))

const RegisterBanner = ({ history, content, openSignUpDrawer }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <PageContainer>
                <Grid container justifyContent='center' alignItems='center' spacing={4}>
                    <Grid item xs={12}>
                        <Typography className={classes.title} align='center'>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align='center' className={classes.description}>
                            {content.description}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <RoundedButton onClick={openSignUpDrawer}>
                            {content.button}
                        </RoundedButton>
                    </Grid>
                </Grid>
            </PageContainer>
        </div >
    )
}

export default RegisterBanner