import React from "react"
import { Card, CardContent, Checkbox, FormControlLabel, Grid, makeStyles, Typography } from "@material-ui/core"
import InputFormW from "../../../components/Forms/InputFormW"
import RoundedButton from "../../../components/Actions/RoundedButton"
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import CheckboxForm from "../../../components/Forms/CheckboxForm"
import RadioForm from "../../../components/Forms/RadioForm"
import { useState } from "react"

const styles = makeStyles(theme => ({
    title: {
        fontSize: 22,
        fontWeight: 500
    },
    helperText: {
        fontSize: 12,
        fontWeight: 500,
        color: theme.palette.secondary.main
    },
    areaCard: {
        width: 64,
        height: 64,
        margin: 'auto',
        boxSizing: 'border-box',
        background: 'transparent',
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.secondary.dark,
            background: '#ffffffbb',
            border: '5px solid'
        }
    },
    cardContent: {
        padding: 0,
        width: '100%',
        height: '100%',
    },
    termsAndPrivacity: {
        '& a': {
            color: 'white'
        }
    }
}))

const Step4 = ({ form, files, content, actions }) => {

    const classes = styles()
    const [checked, setChecked] = useState(false)

    const handleChange = (event) => {
        setChecked(event.target.checked)
    }

    const termsAndPrivacity = (
        <Typography className={classes.termsAndPrivacity}>
            {content.terms.label[0]} <a href={content.terms.termsLink}>{content.terms.label[1]}</a>
            {content.terms.label[2]} <a href={content.terms.privacityLink}>{content.terms.label[3]}</a>
        </Typography>
    )

    return (
        <Grid container justifyContent='center' spacing={5}>
            <Grid item xs={12}>
                <Grid container justifyContent='center' alignItems='center' spacing={8}>
                    <Grid item>
                        <Typography className={classes.title}>{content.subform.optional_documents.label}:</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent='center'>
                            {Object.keys(content.files).map((document, key) => (
                                <Grid item>
                                    <Grid container justifyContent='center'>
                                        <Grid item xs={12}>
                                            <Card
                                                variant="outlined"
                                                className={classes.areaCard}
                                                onClick={() => actions.onOpenDocumentModal(files[document])}
                                            >
                                                <CardContent className={classes.cardContent}>
                                                    <SVGIcon src={content.upload_icon} size={43} style={{ margin: 'auto', transform: 'translateY(25%)' }} />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body2' align='center' className={classes.areaTitle}>
                                                {content.files[document].label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Typography className={classes.helperText} align='justify'>* {content.subform.optional_documents.helperText}</Typography>
                    </Grid>
                    {/*///////////////////////////////////////////*/}
                    <Grid item xs={12}>
                        <Grid container justifyContent='center' alignItems='center' spacing={4}>
                            <Grid item>
                                <Typography className={classes.title}>{content.subform.sponsorship.label}:</Typography>
                            </Grid>
                            <Grid item>
                                <RadioForm data={form.sponsorship} onChange={actions.onChange} />
                            </Grid>

                            <Grid item xs={12} sm>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.helperText} align='justify'>* {content.subform.sponsorship.helperText[0]}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.helperText} align='justify'>* {content.subform.sponsorship.helperText[1]}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*///////////////////////////////////////////*/}
                    <Grid item xs={12}>
                        <Grid container justifyContent='center' alignItems='center' spacing={2}>
                            <Grid item xs>
                                <Typography className={classes.title}>{content.subform.choosing_reason.label}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='caption'>(max. {form.choosing_reason.rules.max} {content.words})</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <InputFormW data={form.choosing_reason} onChange={actions.onChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label={termsAndPrivacity}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <RoundedButton disabled={!checked} onClick={actions.onNextStep}>
                    {content.button}
                </RoundedButton>
            </Grid>
        </Grid>
    )
}

export default Step4