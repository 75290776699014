import noImage from '../../../assets/artist/noImage.png'

import mini_world from '../../../assets/icons/net.svg'

import video from '../../../assets/main-video.mp4'

import rotation from '../../../assets/icons/rotation.svg'
import magazine from '../../../assets/icons/magazine.svg'
import marketplace from '../../../assets/icons/market_place.svg'
import master_classes from '../../../assets/icons/master_classes.svg'
import clicking from '../../../assets/icons/clicking.svg'
import digital_marketing from '../../../assets/icons/digital_marketing.svg'

import download_banner from '../../../assets/banners/download_app.png'
import phone_download from '../../../assets/banners/iphone_download.png'
import app_store_button from '../../../assets/apple_button.png'
import play_store_button from '../../../assets/google_button.png'

export const translations = {
    main_banner_section: {
        noImage: noImage,
        language_button: {
            icon: mini_world,
            options: [
                { id: 1, value: 'en', label: 'EN' },
                //{ id: 2, value: 'es', label: 'ES' }
            ]
        }
    },
    abstract_section: {
        title: 'Virtuelle Kunst',
        subtitle: 'Für Alle',
        description: [
            [
                {
                    text: '¡¡ Herzlich Willkommen in ',
                },
                {
                    text: 'KUVIK',
                    bold: true
                },
                {
                    text: ' !!'
                }
            ],
            [
                {
                    text: 'A vibrant Virtual Gallery based in Germany, commited to discover and promote talented artists  from all around the world.'
                }
            ]
        ],
        video: video,
        comunity: {
            title: 'Join our community!',
            description: 'Are you an artist? Prepare for this journey',
            join_button: 'Join us',
        }
    },
    platform_section: {
        title: 'Our Platform',
        categories: [
            {
                title: 'Art',
                bgcolor: 'black'
            },
            {
                title: 'Technology',
                bgcolor: 'primary.main'
            },
            {
                title: 'Innovation',
                bgcolor: 'secondary.main'
            }
        ],
        description: 'Leveraging the expertise of talented artists supported by innovative digital technologies, we manage to offer a centralized platform to share, inspire & impact the Art Community in an easy, interactive and immersive way.',
        noImage: noImage,
        gal_first_colum: [
            { id: 1, value: 1, label: 'Artist' },
            { id: 4, value: 4, label: 'NFT' },
        ],
        gal_second_colum: [
            { id: 2, value: 2, label: 'Market Place', down: true },
            { id: 5, value: 5, label: 'Masterclass', down: true },
        ],
        gal_thrid_colum: [
            { id: 3, value: 3, label: 'Exhibitions', },
            { id: 6, value: 6, label: 'Magazines' }
        ],
        gallery: [






        ]
    },
    exhibitions_section: {
        title: 'Virtual Exhibitions',
        noContent: 'Sorry! There are not exhibitions to show yet.',
        subtitle: ['Exhibit your art', 'everywhere.'],
        description: "Every year, Kuvik Galerie hosts several virtual exhibitions to which artists are invited to participate through open calls. For each exhibition, an edition of the Kuvik's e-magazine is published, profiling the winning artists as well as their inspiring artworks.",
        noImage: noImage,
        bottom_text: 'Are you intereseted on applying for a virtual exhibition?',
        apply_button: {
            title: 'Apply',
        }
    },
    about_us_section: {
        title: 'What do we do?',
        description: 'We simplify and revolutionise art through innovative digital technologies.',
        cards: [
            {
                title: 'Virtual Exhibition',
                description: 'Art digital spaces to connect your creations with all the globe. ',
                icon: rotation
            },
            {
                title: 'Magazines',
                description: 'Share your artworks and the inspiring stories behind them.',
                icon: magazine
            },
            {
                title: 'Market place',
                description: 'Promote and sell your artworks physically and/or digitally.',
                icon: marketplace,
                stroke: true,
            },
            {
                title: 'Masterclasses',
                description: 'Upskill and stay relevant to keep creating inspiring art proposals.',
                icon: master_classes
            },
            {
                title: 'Cultural Management',
                description: 'Art consultancy, artistic representation and market positioning. to move your career forward.',
                icon: clicking
            },
            {
                title: 'Digital Promotion',
                description: 'Dedicated digital tools to simplify efforts and increase artists exposure.',
                icon: digital_marketing
            }
        ],
        bottom_text: 'Are you an artist?',
        request_button: 'Contact'
    },
    download_app_section: {
        title: 'Download our App',
        description: [
            'All the virtual art',
            'on your hand.'
        ],
        banner: {
            bg: download_banner,
            phone: phone_download
        },
        store_buttons: {
            ios: app_store_button,
            android: play_store_button
        }
    },
}