import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { AppBar, Card, CardContent, Dialog, Grid, IconButton, Slide, Step, StepConnector, StepLabel, Stepper, Toolbar, Typography } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { actionTypes } from "../../store/actions";
import { connect } from "react-redux";
import general_translations from "../../texts/eng/pro/general-translations"
import useArtistRegisterDrawer from "./useArtistRegisterDrawer";
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer'
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import DocumentModal from "./modals/DocumentModal";
import Step5 from "./Steps/Step5";
import Completed from "./Steps/Completed";

const styles = makeStyles(theme => ({
    root: {
        color: 'white',
    },
    paper: {
        background: 'rgba(0,0,0,0.8)'
    },
    content: {
        color: 'white',
        width: '60%',
        height: 'auto',
        margin: 'auto',
        marginTop: 30,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 64,
            boxSizing: 'border-box'
        },
        [theme.breakpoints.only('xs')]: {
            padding: 32,
        }
    },
    appBar: {
        position: 'relative',
        background: 'transparent',
        padding: 32,
        boxSizing: 'border-box',
        boxShadow: 'none',
        borderBottom: '1px solid #5A5A5A'
    },
    stepperContainer: {
        width: 300,
        paddingLeft: 64,
        paddingTop: 24,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            boxSizing: 'border-box',
            padding: 32
        }
    },
    stepper: {
        background: 'transparent',
        padding: 0,
        paddingTop: 12,
    },
    stepLabel: {
        '& svg': {
            width: 32,
            height: 32,
            fontSize: 26,
            fontWeight: 500,
            color: `${theme.palette.secondary.main}55 !important`,
            '& .MuiStepIcon-text': {
                fill: 'darkgrey'
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },
        '& .MuiStepIcon-active': {
            color: `${theme.palette.secondary.main}55`,
            border: `solid 2px ${theme.palette.secondary.main}`,
            borderRadius: '100%',
            '& .MuiStepIcon-text': {
                fill: 'white'
            }
        }
    },
    stepperLabel: {
        color: theme.palette.secondary.main,
        textTransform: 'uppercase',
        letterSpacing: '0.37em',
        fontSize: 14,
        paddingLeft: 18
    },
    title: {
        fontSize: 30,
        fontWeight: 600,
        '& span': {
            color: theme.palette.secondary.main
        }
    },
    subtitle: {
        fontSize: 18,
        fontWeight: 600,
        '& span': {
            fontSize: 24,
            fontWeight: 500,
            color: theme.palette.secondary.main
        }
    },
    link: {
        fontSize: 18,
        fontWeight: 700,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    iconPassword: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    areaCard: {
        width: 200,
        height: 200,
        background: 'transparent',
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.secondary.dark,
            background: '#ffffffbb',
            border: '5px solid'
        }
    },
    areaTitle: {
        fontSize: 24,
        fontWeight: 600,
        marginTop: '40%',
        textTransform: 'uppercase',
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ArtistSignUp = ({ open, user, language, onUpdateProfilePicture, onUpdateDrawerStatus,
    onUpdateUserData, onUpdateModalStatus, onUpdateProMode }) => {

    const classes = styles()
    const content = general_translations.artist_register_drawer

    const { loading, sending, success, error, document_modal, form, files, selected_document,
        maxStep, step, gallery, actions } = useArtistRegisterDrawer({
            language, content, user, onUpdateModalStatus, onUpdateDrawerStatus,
            onUpdateUserData, onUpdateProfilePicture, onUpdateProMode
        })

    useEffect(() => {
        if (open)
            actions.onInitModule()
    }, [])


    let contentView = null

    switch (step) {
        case 0:
            contentView = <Step1 actions={actions} form={form} content={content.steps[0]} />
            break;
        case 1:
            contentView = <Step2 actions={actions} gallery={gallery} content={content.steps[1]} />
            break;
        case 2:
            contentView = <Step3 actions={actions} form={form} content={content.steps[2]} />
            break;
        case 3:
            contentView = <Step4 actions={actions} form={form} files={files} content={content.steps[3]} />
            break;
        case 4:
            contentView = <Step5 actions={actions} form={form} files={files} content={content.steps[4]} />
            break;
        case 5:
            contentView = <Completed actions={actions} user={user} files={files} content={content.finish} />
            break;
    }

    return (
        <LoadingContainer loading={loading} sending={sending} success={success} error={error} onCloseError={actions.onCloseError} onCloseSuccess={actions.onCloseSuccess}>
            <div className={classes.root}>
                <DocumentModal open={document_modal} onUpdateDocument={actions.onUpdateDocument} loading={sending} error={error} onClose={actions.onCloseDocumentModal} />
                <Dialog fullScreen classes={{ paperFullScreen: classes.paper }} open={open} onClose={actions.onClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    <img src={content.logo} style={{ width: 47, marginLeft: 10 }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant='body2' align='center' className={classes.title}>
                                        {content.title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton edge="end" color="inherit" onClick={actions.onClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    {step < 5 &&
                        <div className={classes.stepperContainer}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography className={classes.stepperLabel}>{content.steps[step].title}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stepper
                                        activeStep={step}
                                        className={classes.stepper}
                                        alternativeLabel
                                        connector={<StepConnector style={{ top: '50%' }} />}
                                    >
                                        {content.steps.map((stepInfo, key) => (
                                            <Step key={`stepper_signup.${key}`} disabled={maxStep < key} onClick={key < maxStep ? () => actions.onUpdateStep(key) : null}>
                                                <StepLabel className={classes.stepLabel}></StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Grid>
                            </Grid>
                        </div>
                    }
                    <div className={classes.content}>
                        {contentView}
                    </div>
                </Dialog>
            </div>
        </LoadingContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        open: state.artist_register_drawer,
        user: state.user,
        profile_picture: state.profile_picture
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (artist_register_drawer) => dispatch({ type: actionTypes.DRAWER_ARTIST_REGISTER, artist_register_drawer }),
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateModalStatus: (profile_picture_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_PROFILE_PICTURE, profile_picture_modal }),
        onUpdateProfilePicture: (profile_picture) => dispatch({ type: actionTypes.SYS_UPDATE_PROFILE_PICTURE, profile_picture }),
        onUpdateProMode: (pro_mode) => dispatch({ type: actionTypes.SYS_PRO_MODE, pro_mode }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistSignUp)