import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import cx from 'classnames'

const styles = makeStyles(theme => ({
    card: {
        position: 'relative',
        witdth: '100%',
        height: 'auto',
        padding: 32,
        border: `1px solid ${theme.palette.secondary.main}`,
        [theme.breakpoints.up('md')]: {
            minHeight: 80,
        }
    },
    cardB: {
        background: `rgba(189, 139, 64, 0.14)`,
    },
    cardTitle: {
        top: '50%',
        left: '50%',
        fontSize: 18,
        fontWeight: 400,
        position: 'absolute',
        textTransform: 'uppercase',
        transform: 'translate(-50%,-50%)',
        color: theme.palette.secondary.main,
    },
    title: {
        fontSize: 32,
        fontWeight: 700,
        textTransform: 'uppercase',
        [theme.breakpoints.only('xs')]: {
            fontSize: 28
        }
    }
}))

const Step1 = ({ title, description, stepCards }) => {

    const classes = styles()

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            1. {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {description.map(text => (
                                <Grid item xs={12}>
                                    <Typography variant='body2' align='justify'>
                                        {text}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container justifyContent={'center'} alignItems='center' spacing={4}>
                    {stepCards.map((text, key) => (
                        <Grid item xs={12} md={6} key={`step1.${key}`}>
                            <div className={cx({
                                [classes.card]: true,
                                [classes.cardB]: key === 0 || key === 3
                            })}>
                                <Typography className={classes.cardTitle} align='center'>
                                    {text}
                                </Typography>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Step1