import React, { useEffect, useState, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { makeStyles, Typography, useMediaQuery, useScrollTrigger, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import WebsiteRouter from '../../routes/website-router';
import { actionTypes } from '../../store/actions';
import Topbar from './components/Topbar';
import SVGIcon from '../../components/Structure/DisplayData/SVGIcon';
import cx from 'classnames'
import Footer from './components/Footer/Footer';
import useWebsiteLayout from './useWebsiteLayout';
import LogInDrawer from '../LogInDrawer/LogInDrawer'
import SignUpDrawer from '../SignUpDrawer/SignUpDrawer'
import ConfirmationDrawer from '../ConfirmationDrawer/ConfirmationDrawer'
import ProfilePictureModal from '../../modals/ProfilePictureModal/ProfilePictureModal'
import general_translations from '../../texts/eng/website/general-translations'
import ArtistRegisterDrawer from '../ArtistRegisterDrawer/ArtistRegisterDrawer';
import ContactDrawer from '../ContactDrawer/ContactDrawer';
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer';
import ArtworkModal from '../../modals/ArtworkModal/ArtworkModal';
import PurchaseEnquiryDrawer from '../PurchaseEnquiryDrawer/PurchaseEnquiryDrawer';

const WebsiteLayout = ({ history, language, blur, arrowTranslate, onUpdateArrowPosition,
    onUpdateUserData, onUpdateAuthStatus, onUpdateLanguage, onUpdateConfirmDrawer, 
    onUpdateLogInDrawer, onUpdateProMode, onUpdateContactModal}) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
    const content = general_translations.arrow_navigate

    const { loading, sending, success, error, actions } = useWebsiteLayout({ history,
        onUpdateArrowPosition, onUpdateUserData, onUpdateAuthStatus, onUpdateProMode, 
        onUpdateLogInDrawer })

    const arrowUp = useScrollTrigger({ disableHysteresis: true, threshold: 200 })

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    let styles_arrow = {}
    if (arrowUp)
        styles_arrow = { transform: 'rotate(180deg) translate(-5px,-95px)'}


    useEffect(() => {
        const current_path = history.location.pathname
        if (current_path === '/confirmation')
            onUpdateConfirmDrawer(true)
        actions.onInitModule()
    }, [])

    useInterval(() => {
        actions.onCheckTokenExpirationDate()
    }, 3000)

    return (
        <LoadingContainer loading={loading} sending={sending} success={success} error={error} onCloseSuccess={actions.onCloseError} onCloseSuccess={actions.onCloseSuccess}>
            <div className={cx({
                [classes.root]: true,
                [classes.root_blur]: blur
            })}>
                <div
                    className={cx({
                        [classes.arrow]: true,
                        [classes.arrowUp]: arrowTranslate
                    })}
                    onClick={scrollToTop}
                >
                    <Typography className={classes.vertical} variant='body2'>{content.label}</Typography>
                    <SVGIcon style={styles_arrow} src={content.icon} size={'100%'} color='white' />
                </div>
                <SignUpDrawer />
                <LogInDrawer />
                <ProfilePictureModal />
                <ArtworkModal />
                <ConfirmationDrawer history={history} />
                <ContactDrawer history={history} />
                <PurchaseEnquiryDrawer />
                <ArtistRegisterDrawer />
                <main className={classes.main} >
                    <Topbar history={history} onItemSelected={actions.onItemSelected} />
                    <WebsiteRouter />
                    <InView as="div" onChange={actions.onUpdateNavArrow}>
                        <Footer language={language} history={history} onUpdateLanguage={onUpdateLanguage} onUpdateContactModal={onUpdateContactModal}/>
                    </InView>
                </main>

            </div>
        </LoadingContainer>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        //background:'#edf2f8',
        minHeight: '100vh',
        overflowY: 'auto',
        filter: 'none'

    },
    root_blur: {
        filter: 'blur(3px)'
    },
    main: {
        //marginLeft:150,
        //padding:16,
        overflowY: 'auto'

    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
    arrowUp: {
        transform: 'translate(40px,40vh)',
    }
}))

const mapStateToProps = state => {
    return {
        is_auth: state.is_auth,
        blur: state.blur,
        user: state.user,
        arrowTranslate: state.arrow_translate,
        language: state.language,
        pro:state.pro_mode
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateProMode: (pro_mode) => dispatch({ type: actionTypes.SYS_PRO_MODE, pro_mode }),
        onUpdateLogInDrawer: (login_drawer) => dispatch({ type: actionTypes.DRAWER_UPDATE_LOGIN, login_drawer }),
        onUpdateLanguage: (language) => dispatch({ type: actionTypes.SYS_UPDATE_LANGUAGE, language }),
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
        onUpdateArrowPosition: (arrow_translate) => dispatch({ type: actionTypes.ARROW_TRANSLATE, arrow_translate }),
        onUpdateConfirmDrawer: (confirmation_drawer) => dispatch({ type: actionTypes.DRAWER_CONFIRMATION, confirmation_drawer }),
        onUpdateContactModal: (contact_drawer) => dispatch({ type: actionTypes.DRAWER_CONTACT, contact_drawer }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteLayout);

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}