import exhibition1 from '../../../assets/galleries/exhibition/exhibition1.png'
import exhibition2 from '../../../assets/galleries/exhibition/exhibition2.png'
import exhibition3 from '../../../assets/galleries/exhibition/exhibition3.png'

import noImage from '../../../assets/artist/noImage.png'
import arrowIcon from '../../../assets/icons/arrow_down.svg'

import shareIcon from '../../../assets/icons/share.svg'
import likeIcon from '../../../assets/icons/heart.svg'
import fullHeart from '../../../assets/icons/full_heart.svg'

import magazineBanner from '../../../assets/banners/magazine_banner.png'

export const translations = {
    title: 'Artwork',
    filter: [
        { id: 'all', value: 0, label: 'All' }
    ],
    noImage: noImage,
    noContent: 'There are not coincidences with the filters selected.',
}