import React from 'react';
import { translations as translations_esp } from '../../../texts/eng/website/magazines-translations'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Header from './sections/Header'
import NewReleases from './sections/NewReleases'
import Individuals from './sections/Individuals'
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer'
import useMagazinesView from './useMagazinesView'
import Banner from './sections/Banner'
import { actionTypes } from '../../../store/actions'
import PreviousEditions from './sections/PreviousEditions/PreviousEditions'
import ComingSoon from '../ComingSoon';

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const MagazinesView = ({ history, is_auth, onUpdateContactDrawerStatus, onUpdateMagazineReference }) => {

    const classes = styles()

    const { loading, sending, success, error, actions,
        magazines, new_releases, individuals } = useMagazinesView({
            is_auth, history,
            onUpdateContactDrawerStatus, onUpdateMagazineReference
        })

    const content = translations_esp

    /*    return (
            <ComingSoon content={content.coming_soon} />
        )
    */
    return (
        <LoadingContainer
            loading={loading}
            sending={sending}
            success={success}
            error={error}
            onCloseSuccess={actions.onCloseSuccess}
            onCloseError={actions.onCloseError}
        >
            <div className={classes.content}>
                <Header history={history} content={content.main_info_section} />
                <NewReleases magazines={new_releases} history={history} content={content.new_releases_section} onSave={is_auth ? actions.onSaveMagazine : null} onClick={actions.onGoToMagazineDetails} />
                <Individuals magazines={individuals} history={history} onGetIndividuals={actions.onGetIndividuals} content={content.individuals_section} onClick={actions.onGoToMagazineDetails}/>
                <Banner history={history} content={content.banner_section} onOpenContactDrawer={actions.onOpenContactDrawer} onShowCommunityPage={actions.onShowCommunityPage} />
                <PreviousEditions magazines={magazines} history={history} content={content.previous_editions_section} onGetPreviousEditions={actions.onGetPreviousEditions} onClick={actions.onGoToMagazineDetails} />
            </div>
        </LoadingContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        is_auth: state.is_auth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateContactDrawerStatus: (contact_drawer) => dispatch({ type: actionTypes.DRAWER_CONTACT, contact_drawer }),
        onUpdateMagazineReference: (magazine_reference) => dispatch({ type: actionTypes.REFERENCE_MAGAZINE, magazine_reference }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MagazinesView)