import React from "react"
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import PageContainer from "../../../../components/Structure/Layouts/PageContainer"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80,
        marginBottom: 80,
        boxSizing: 'border-box',
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em',
    },
    divider: {
        backgroundColor: grey[600]
    },
    titleS: {
        fontSize: 32,
        fontWeight: 600,
        textTransform: 'uppercase',
        '& span': {
            color: theme.palette.secondary.main
        }
    },
    card: {
        width: '100%',
        height: 'auto',
        borderRadius: 5,
        position: 'relative',
    },
    cardTitle: {
        fontSize: 32,
        fontWeight: 500,
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        [theme.breakpoints.up('lg')]: {
            letterSpacing: '0.2em'
        },
        [theme.breakpoints.only('xs')]: {
            letterSpacing: 'inherit'
        }
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        filter: 'brightness(0.5)',
        [theme.breakpoints.only('sm')]: {
            maxHeight: 300
        }
    },
}))


const Alliances = ({ history, content }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title}>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent='center' spacing={4}>
                            <Grid item xs={12}>
                                <Typography className={classes.titleS} align='center'>
                                    {content.titleS[0]} <span>{content.art}</span> {content.titleS[1]}
                                </Typography>
                            </Grid>
                            {content.options.map(option => (
                                <Grid item xs={12} md={4}>
                                    <div className={classes.card}>
                                        <img src={option.image} className={classes.image} />
                                        <Typography className={classes.cardTitle} align='center'>
                                            {option.label}
                                        </Typography>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>
    )
}

export default Alliances