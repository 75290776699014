import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import InputFormW from "../../../components/Forms/InputFormW"
import RoundedButton from "../../../components/Actions/RoundedButton"

const styles = makeStyles(theme => ({
    title: {
        fontSize: 27,
        fontWeight: 700
    },
    description: {
        fontSize: 24,
        fontWeight: 400,
        '& span': {
            fontWeight: 600
        }
    }
}))

const Completed = ({ form, content, actions }) => {
    const classes = styles()

    return (
        <Grid container justifyContent='center' alignItems='center' spacing={5}>
            <Grid item xs={12}>
                <Typography className={classes.title} align='center'>{content.title}</Typography>
            </Grid>
            <Grid item>
                <img src={content.image} />
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.description} align='center'>
                    {content.description[0]}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.description} align='center'>
                    {content.description[1]} <span>{form.email.value}</span>
                </Typography>
            </Grid>
            <Grid item>
                <RoundedButton onClick={actions.onClose}>
                    {content.button}
                </RoundedButton>
            </Grid>
        </Grid>
    )
}

export default Completed