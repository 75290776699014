import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { catalogs } from '../../../../texts/eng/catalogs'
import { CONFIG_SETTINGS, nodata } from '../../../../config'
import { isValidDocument } from '../../../../shared/utility'
import cx from 'classnames'

const styles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100vh',
        background: 'black',
        position: 'relative',
    },
    image: {
        width: '100%',
        height: '100vh',
        objectFit: 'cover',
        filter: 'brightness(50%)',
    },
    content: {
        width: '100%',
        height: '100%',
        color: 'white',
        position: 'relative',
        top: 0,
        transform: 'translateY(-100%)'
    },
    center: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    title: {
        fontSize: 42,
        fontWeight: 500,
        textTransform: 'capitalize',
        '& span': {
            fontSize: 48,
            fontWeight: 600
        }
    },
    description: {
        fontSize: 20,
        fontWeight: 500,
        letterSpacing: '0.25em',
        textTransform: 'uppercase'
    },
    noImage:{
        width:'100%',
        height: '100vh',
    }
}))

const MainBanner = ({ artist, content }) => {

    const classes = styles()
    let nationality = catalogs.countries.find(c => c.id === artist.country_id)
    nationality = nationality ? nationality.abbreviation : '-'

    let isValidImage = false
    if (artist) {
        isValidImage = isValidDocument(artist.banner_image)
    }
    let current_image = isValidImage ? `${CONFIG_SETTINGS.S3_SERVER_URL}${artist.banner_image}` : content.banner

    return (
        <div className={classes.root}>
            {isValidImage ?
                <img src={current_image} className={classes.image} />
                :
                <div className={classes.noImage}></div>
            }
            <div className={classes.content}>
                <div className={classes.center}>
                    <Typography className={classes.title} align='center'>
                        {artist.professional_name ? artist.professional_name : nodata}
                    </Typography>
                    <Typography className={classes.description} align='center'>
                        {artist.country ? artist.country : nodata}
                    </Typography>
                </div>
            </div>
        </div >
    )
}

export default MainBanner