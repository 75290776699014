import React, { useState, useEffect } from 'react';
import { translations as translations_esp } from '../../../texts/eng/website/artist-translations'
import { makeStyles } from '@material-ui/styles'
import { onGetErrorMessage } from '../../../shared/utility'
import queryString from 'query-string'
import Header from './sections/Header'
import NewArtists from './sections/NewArtists'
import { public_server } from '../../../config'
import Artists from './sections/Artists'
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer'
import useArtistView from './useArtistView';

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        marginTop:140, 
        //minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const ArtistView = ({ user, history }) => {

    const classes = styles()
    const content = translations_esp

    const {system, view_data, actions} = useArtistView({user, history})

    return(
        <LoadingContainer loading={system.loading} error={system.error} onCloseError={actions.onCloseError}>
            <div className={classes.content}>
                <Header history={history} content={content.main_info_section} />
                <NewArtists artists={view_data.new_artists} content={content.new_artists_section} onArtistSelected={actions.onArtistSelected}/>
                <Artists artists={view_data.artists} history={history} onGetArtists={actions.onGetArtists} content={content.artists_section}
                 onArtistSelected={actions.onArtistSelected}/>
            </div>
        </LoadingContainer>
    )
}

export default ArtistView