import { FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core"
import React from "react"
import { ruleValidation } from "./customFunctions"
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'transparent',
    },
    icon: {
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: 'transparent',
        border: '2px solid white',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.secondary.dark,
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        color:'white',
        'input:hover ~ &': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
}))


const StyledRadio = props => {
    const classes = useStyles()

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<CheckIcon className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon} />}
            {...props}
        />
    )
}

const RadioForm = ({ data, onChange }) => {

    const { value, options, config } = data
    const { label } = config

    const onInnerChange = event => {
        const response = event.target.value
        let temp = { ...data }
        temp.value = parseInt(response)
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2)
        temp.isVisited = true
        onChange(temp)
    }
    console.log(value)
    return (
        <FormControl>
            {label && <FormLabel component="legend">{label}</FormLabel>}
            <RadioGroup aria-label="radioform" name="radiobutton" value={value} onChange={onInnerChange} style={{display:'block'}}>
                {options.map(option => (
                    <FormControlLabel value={option.value} control={<StyledRadio />} label={option.label} />
                ))}
            </RadioGroup>
        </FormControl>
    )
}

export default RadioForm