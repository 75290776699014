import React, { useState } from "react"
import { Card, CardActionArea, CardContent, CardMedia, CardActions, Divider, Grid, Icon, IconButton, makeStyles, Typography, useMediaQuery, Switch, Radio } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import { useTheme, withStyles } from "@material-ui/styles"
import { CONFIG_SETTINGS } from "../../../../config"
import { isValidDocument, onGetCurrencyValue } from "../../../../shared/utility"
import { catalogs } from "../../../../texts/eng/catalogs"
import PageContainer from "../../../../components/Structure/Layouts/PageContainer"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import cx from 'classnames'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import IconSwitch from "../../../../components/Actions/IconSwitch"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import GenericImage from "../../../../components/Structure/DisplayData/GenericImage"
import ArtworkCard from "../../../../components/Structure/Cards/ArtworkCard"

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80,
        //paddingLeft: 100,
        //paddingRight: 100,
        [theme.breakpoints.down('sm')]: {
            //padding: 32
        },
    },
    content: {
        padding: 0,
        paddingTop: 80
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    noArtwork: {
        fontSize: 14,
        fontWeight: 300
    },
    carouselItem: {
        height: 650,
        marginTop: -100,
        '& li': {
            margin: 10,
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                padding: 10,
                boxSizing: 'border-box'
            }
        },
        '& ul.react-multi-carousel-dot-list': {
            top: 0,
            marginTop: 600,
            height: 'fit-content',
        }
    },
    lineButton: {
        width: 120,
        height: 1.5,
        borderRadius: 0,
        //marginLeft: 10,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    button: {
        display: 'block',
        margin: 'auto',
        borderRadius: 12
    },
    more: {
        color: theme.palette.secondary.main,
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: '0.525em'
    },
    filterButton: {
        background: 'black',
        padding: 5,
        borderRadius: '100%',
        '&:hover': {
            background: theme.palette.secondary.main
        }
    },
    filterLabel: {
        fontSize: 16,
        fontWeight: 600
    }
}))

const Catalog = ({ history, artwork, content, onSelectFilter, onBuy, onOpenArtWork, onOpenNFT }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))
    const tablet = useMediaQuery(theme.breakpoints.down('md'))

    const [seeMore, setSeeMore] = useState(false)
    const [filter, setFilter] = useState(1)
    const [nft, setNFT] = useState(false)
    const [color, setColor] = useState(1)

    const showN = (mobile ? 3 : (tablet ? 6 : 8))
    const more = artwork.length > showN
    const _artwork = (!seeMore && more) ? artwork.slice(0, showN) : artwork
    const selectedFilter = catalogs.art_filter.find(e => e.value === filter)

    const handleChange = (event) => {
        setColor(parseInt(event.target.value))
        onSelectFilter(filter, nft, event.target.value)
    }

    const handleSwitchChange = (event) => {
        setNFT(event.target.checked)
        onSelectFilter(filter, event.target.checked, color)
    }

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container justifyContent='center' spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title} align='right'>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
            <MainContainer>
                <div className={classes.content}>
                    <Grid container justifyContent='flex-start' spacing={4}>
                        {/*<Grid item xs={12}>
                        <div className={classes.content}>
                            <Grid container alignItems='center'>
                                <Grid item xs>
                                    <Grid container alignItems='center' spacing={2}>
                                        <Grid item>
                                            <IconButton className={classes.filterButton}>
                                                <SVGIcon src={selectedFilter.icon} />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.filterLabel}>
                                                {selectedFilter.label}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconSwitch
                                                icon={content.nft}
                                                value={nft}
                                                onChange={handleSwitchChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems='center' spacing={2}>
                                        <Grid item>
                                            <Typography className={classes.filterLabel}>
                                                {content.colors}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            {catalogs.color_filter.map(_color => (
                                                <StyledRadio
                                                    {..._color}
                                                    onChange={handleChange}
                                                    checked={color === _color.value}
                                                />
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                                            </Grid>*/}
                        {artwork.length > 0 ?
                            <React.Fragment>
                                {_artwork.map(artwork => (
                                    <Grid item xs={12} md={4} lg={3}>
                                        <ArtworkCard {...artwork} content={content} noImage={content.noImage}
                                            onBuy={() => onBuy(artwork)} onOpenArtWork={() => onOpenArtWork(artwork)}/>
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    <IconButton onClick={() => setSeeMore(prev => !prev)} className={classes.button}>
                                        <div className={classes.button}>
                                            {seeMore ?
                                                <SVGIcon
                                                    size={54}
                                                    src={content.moreIcon}
                                                    colorStroke={theme.palette.secondary.main}
                                                    style={{ margin: 'auto', transform: 'rotate(180deg)' }}
                                                />
                                                :
                                                <SVGIcon
                                                    size={54}
                                                    src={content.moreIcon}
                                                    colorStroke={theme.palette.secondary.main}
                                                    style={{ margin: 'auto' }}
                                                />
                                            }
                                            <Typography className={classes.more} align='center'>
                                                {seeMore ? content.see.less : content.see.more}
                                            </Typography>
                                        </div>
                                    </IconButton>
                                </Grid>
                            </React.Fragment>
                            :
                            <Grid item xs={12}>
                                <Typography className={classes.noArtwork} align='center'>
                                    {content.noContent}
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </div>
            </MainContainer>
        </div>
    )
}

export default Catalog

const StyledRadio = props => (
    <Radio
        icon={<FiberManualRecordIcon style={{ color: props.color }} />}
        checkedIcon={<FiberManualRecordIcon style={{
            color: props.color,
            boxShadow: `0px 0px 4px 0px ${props.color}`,
            borderRadius: '100%'
        }} />}
        {...props}
    />
)