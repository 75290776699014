import React, { useState, useEffect } from 'react'
import { translations as translations_esp } from '../../../texts/eng/website/masterclasses-translations'
import { onGetErrorMessage, onValidateURL } from '../../../shared/utility'
import queryString from 'query-string'
import { public_server } from '../../../config'

const useMasterclassesDetailsView = ({ match, history, onUpdateDrawerLogIn, onUpdateDrawerSignUp }) => {

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [top_classes, setTopClasses] = useState([])
    const [masterclass, setMasterclass] = useState([])
    const [masterclasses, setClasses] = useState([])

    const masterclassURL = match.params.url

    useEffect(() => {
        actions.initModule()
    }, [])

    const actions = {
        initModule: async () => {
            window.scrollTo(0, 0)
            try {
                setLoading(true)

                //NEW CLASSES
                const _masterclass = await public_server.get(`/masterclass/url/ns?url=${masterclassURL}`)
                //const _top_classes = await request_artist_info(params)
                setMasterclass(_masterclass.data.data.masterclass)

                //TOP CLASSES
                let filterArgs = {
                    "AND": [{ "field": "top", "operator": ">", "value": 0 },]
                }

                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                let params2send = { offset: 0, order: 1, order_by: 'id_masterclass' }
                let params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
                const _top_classes = await public_server.get(`/masterclass/all/ns${params}`)
                setTopClasses([..._top_classes.data.data.masterclasses])

                //MASTER CLASSES
                params2send = { offset: 0, order: 1, order_by: 'id_masterclass' }
                params = `?${queryString.stringify(params2send)}`
                const _masterclasses = await public_server.get(`/masterclass/all/ns${params}`)
                setClasses(_masterclasses.data.data.masterclass)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseError: () => setError(null),
        onCloseSuccess: () => setSuccess(false),
        onOpenSignUp: async () => await onUpdateDrawerSignUp(true),
        onOpenLogIn: async () => await onUpdateDrawerLogIn(true),
        onGoToUrl: (url) => {
            if (onValidateURL(url))
                history.push(`masterclass/${url}`)
        }
    }

    const system = { loading, success, error }
    const view_data = { top_classes, masterclass, masterclasses }

    return { system, view_data, actions }
}

export default useMasterclassesDetailsView