import noImage from '../../../assets/artist/noImage.png'

import freeImage from '../../../assets/masterclassImage.png'

export const translations = {
    coming_soon:{
        top_title:'Coming soon',
        title: 'May 1st, 2022',
        caption: 'We commit to something bigger than ourselves'
    },
    main_info_section: {
        title: 'Master classes',
        caption: 'THE BEAUTY OF SHARING ART KNOWLEDGE '
    },
    new_classes_section: {
        title: 'NEW CLASSES',
        noImage: noImage,
        noContent: 'Sorry! There are not new classes yet.',
        free: 'Free'
    },
    top_classes_section: {
        title: 'Top classes',
        noImage: noImage,
        noContent: 'Sorry! There are not top classes yet.',
        free: 'Free'
    },
    free_classes_section: {
        title: ['Get free classes from', 'experts'],
        list: ['On demand', 'Over 20 categories', 'More than 50 videos'],
        button: 'Sign up',
        image: freeImage
    },
    find_classes_section: {
        title: ['We will find classes', 'for you'],
        subtitle: 'Pick any categories you are interested in',
        results: 'results',
        noContent: 'Sorry! There are not classes on those categories yet.',
        form: {
            painting: { label: 'Collecting Art' },
            sculpture: { label: 'Exhibitions' },
            art_news: { label: 'Art News' },
            knowledge: { label: 'Knowledge' }
        }
    }
}