import exhibition1 from '../../../assets/galleries/exhibition/exhibition1.png'
import exhibition2 from '../../../assets/galleries/exhibition/exhibition2.png'
import exhibition3 from '../../../assets/galleries/exhibition/exhibition3.png'

import noImage from '../../../assets/artist/noImage.png'
import arrowIcon from '../../../assets/icons/arrow_down.svg'


export const translations = {
    vk_gallery_section: {
        title: 'KUVIK',
        subtitle:'LATINOS AROUND THE WORLD',
        noImage: noImage,
        noContent: 'Sorry! There are not content here yet.',
    },
    artists_section: {
        title: 'ARTISTS',
        subtitle:'in magazine',
        noImage: noImage,
        noContent: 'Sorry! There are not artists yet.',
    }
}