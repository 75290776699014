import { makeStyles, Grid, Typography, Slide } from '@material-ui/core'
import React from 'react'
import RoundedButton from '../../../../../../components/Actions/RoundedButton'
import InputForm from '../../../../../../components/Forms/InputFormW'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckboxForm from '../../../../../../components/Forms/CheckboxForm';
import { grey, red } from '@material-ui/core/colors';
import { useRef } from 'react';
import Step2 from './Step2';
import Step1 from './Step1';

const styles = makeStyles(theme => ({
    root: {
        height: '100%',
        padding: 150,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 32,
            paddingRight: 32,
        }
    },
    title: {
        //fontSize: 48,
        fontWeight: 600,
        '@global span': {
            color: theme.palette.secondary.main,
            fontWeight: 700,
        }
    },
    subtitle: {
        fontSize: 22,
        fontWeight: 600
    },
    input: {
        backgroundColor: '#F4F4F4',
        //padding: '0px 24px 10px 24px',
        padding: '10px 24px 10px 24px',
        borderRadius: 10
    },
    errorTag: {
        fontSize: '0.75rem',
        color: red[500]
    },
    variableContent: {
        position: 'relative',
        minHeight: 100,
        [theme.breakpoints.between(1280, 1328)]: {
            minHeight: 135
        },
        [theme.breakpoints.down('md')]: {
            minHeight: 165
        },
        [theme.breakpoints.only('xs')]: {
            minHeight: 0,
        },
        width: '100%',
        overflow: 'hidden'
    }
}))

const FormStepper = ({ history, step, content, form, onChange, handleStepper }) => {

    const classes = styles()
    const refContent = useRef()

    let formContent = null

    switch (step) {
        case 0:
            formContent = <Step1 history={history} checked form={form} onChange={onChange} content={content} reference={refContent.current} />
            break;
        case 1:
            formContent = <Step2 history={history} checked form={form} onChange={onChange} content={content} reference={refContent.current} />
            break
    }

    return (
        <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12}>
                <div className={classes.variableContent} ref={refContent}>
                    {formContent}
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.input}>
                    {/*<InputForm data={form.email} variant='standard' onChange={onChange} />*/}
                    <Grid container justifyContent='center' alignItems='center' spacing={2}>
                        <Grid item xs={12} sm>
                            {step === 0 ?
                                <InputForm data={form.email} variant='standard' onChange={onChange} />
                                :
                                <div>
                                    <InputForm data={form.last_name} variant='standard' onChange={onChange} />
                                </div>
                            }
                        </Grid>
                        <Grid item>
                            <RoundedButton
                                size='large'
                                endIcon={<ArrowForwardIosIcon color='white' />}
                                onClick={() => handleStepper(true)}
                            >
                                {content.button}
                            </RoundedButton>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    )
}

export default FormStepper