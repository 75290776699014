import React, { useEffect, useState } from "react"
import { Avatar, Box, Card, Divider, Grid, IconButton, makeStyles, SvgIcon, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { CONFIG_SETTINGS } from "../../../../../config";
import { isValidDocument } from "../../../../../shared/utility";
import { grey } from "@material-ui/core/colors";
import SVGIcon from "../../../../../components/Structure/DisplayData/SVGIcon";
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./BiographyCarousel.css"
import classNames from "classnames"
import PageContainer from "../../../../../components/Structure/Layouts/PageContainer";
import MainContainer from "../../../../../components/Structure/Layouts/MainContainer";
import RoundedButton from "../../../../../components/Actions/RoundedButton";

const styles = makeStyles(theme => ({
    root: {
        color: 'white',
        background: 'black',
        paddingTop: 80,
        paddingBottom: 80,
        //padding: 100,
        //[theme.breakpoints.only('xs')]: {
        //    paddingLeft: 32,
        //    paddingRight: 32,
        //}
    },
    title: {
        fontSize: 32,
        fontWeight: 700
    },
    discipline: {
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: '0.265em'
    },
    tweet: {
        fontSize: 24,
        fontWeight: 200,
    },
    biography: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '277.24%'
    },
    full: {
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: '0.525em'
    },
    image_container: {
        maxWidth: 500,
        margin: 'auto'
    },
    image: {
        width: '100%',
        //height: '100%',
        paddingTop: '133%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 10,
    },
    divider: {
        backgroundColor: grey[600]
    },
    avatarIcon: {
        boxSizing: 'border-box',
        width: 37.5,
        height: 50,
        borderRadius: 8,
        background: 'black'
    }
}))

const responsive = {
    mobile: {
        breakpoint: { max: 4000, min: 0 },
        items: 1
    }
};

const Biography = ({ artist, images, content }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('xs'))

    const selectedImages = images.map(image => {
        const isValid = isValidDocument(image.image)
        const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image.image}` : content.noImage
        return selectedImage
    })

    const _images = selectedImages.map((image, key) => <Avatar key={`imagebio${key}`} src={image} variant="rounded" className={classes.avatarIcon} />)

    const [nextSlide, setNextSlide] = useState(false)

    const CustomDot = ({ index, onClick, active }) => {

        const handleClick = async () => {
            setNextSlide(true)
            await onClick()
            setNextSlide(false)
        }

        return (
            <button
                onClick={(e) => {
                    handleClick()
                    e.preventDefault()
                }}
                className={classNames("custom-artistbio-dot", {
                    "custom-artistbio-dot--active": active
                })}
                style={{ cursor: 'pointer' }}
            >
                {React.Children.toArray(_images)[index]}
            </button>
        );
    };

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container justifyContent='center' spacing={4}>
                    <Grid item xs={12}>
                        <Grid container justifyContent='center' alignItems='center'>
                            <Grid item xs={12}>
                                <Typography className={classes.title}>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography className={classes.discipline}>
                                    {artist.discipline}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    {content.media.map(media => {
                                        const value = artist[media.key]

                                        if (value)
                                            return (
                                                <Grid item>
                                                    <IconButton onClick={() => window.open(`${value}`, media.target)}>
                                                        <SVGIcon src={media.icon} color='white' />
                                                    </IconButton>
                                                </Grid>
                                            )
                                    })}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container justifyContent='center' alignItems='stretch' spacing={8}>
                            <Grid item xs={12}>
                                <Typography className={classes.biography} align='justify'>
                                    {artist.biography && artist.biography.replace(/\r/gm, '').split("\n").map(text => (
                                        <p>{text}</p>
                                    ))}
                                </Typography>
                            </Grid>
                            {/*<Grid item xs>
                                {/*<SVGIcon src={content.arrow} size={42} style={{ margin: 'auto' }} colorStroke='white' />
                                <Typography className={classes.full} align='center'>
                                    {content.full}
                                </Typography>
                            <RoundedButton color='transparent_white_square'>
                                {content.readMore}
                            </RoundedButton>
                        </Grid>*/}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Carousel
                            responsive={responsive}
                            autoPlay={!nextSlide}
                            ssr
                            infinite
                            keyBoardControl
                            centerMode={false}
                            customTransition="transform 300ms ease-in-out"
                            showDots
                            arrows={false}
                            renderDotsOutside
                            dotListClass='carouselArtistBiographyDotList'
                            customDot={<CustomDot />}
                        >
                            {selectedImages.map(image =>
                                <div className={classes.image_container}>
                                    <div className={classes.image} style={{ backgroundImage: `url(${image})` }} />
                                </div>
                            )}
                        </Carousel>
                    </Grid>

                </Grid>
            </MainContainer>
        </div >
    )
}

export default Biography