import React, { useState, useEffect } from "react"
import moment from "moment";
import { CONFIG_SETTINGS, private_server, public_server } from "../../config";
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, onSetTokenSession, updateAuthorizationHeader } from "../../shared/utility";
import { generic_errors } from "../../texts/eng/genericErrors";
import countryCodes from 'country-codes-list'

const useContactDrawer = ({ language, open, content, onUpdateDrawerStatus, onUpdateDrawerLogIn }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [stepTab, setTab] = useState(1)
    const [step, setStep] = useState(0)

    useEffect(() => {
        if (open) actions.onInitModule()
    }, [open])

    const actions = {
        onInitModule: () => {
            setLoading(true)
            let _form = { ...JSON.parse(JSON.stringify(formData)) }
            
            _form = onInitForm(_form, null, content.steps[0].form.general)
            const country_codes = countryCodes.customList('countryCallingCode', '+{countryCallingCode}')
            _form.country_code.options = Object.values(country_codes)
            console.log(_form.country_code.options)
            _form.country_code.value = "+49";
            _form.country_code.isValid = true;
            setLoading(false)
            setError(null)
            setForm(_form)
            setStep(0)
            setTab(1)
        },
        onClose: async () => await onUpdateDrawerStatus(false),
        onCloseError: () => setError(null),
        onUpdateStepTab: (step) => setTab(step),
        onOpenLogIn: async () => {
            await onUpdateDrawerStatus(false)
            await onUpdateDrawerLogIn(true)
        },
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSubmit: async () => {
            const errors = isFormValid(form)

            // Error handler
            if (errors && errors.length) {
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            data2send.device = "web"
            data2send.date = moment().format('YYYY-MM-DD')
            data2send.country_code = "+52"

            try {
                setSending(true)

                await public_server.post(`/contactrequest/ns`, data2send)
                //const token_data = response.data.data
                //await onSetTokenSession(token_data)
                //updateAuthorizationHeader(private_server)
                //const user_request = await private_server.get('/user/me')
                //console.log(user_request.data.data.user)
                //const response = await public_server.post(`/login`, data2send)
                setError(null)
                setSending(false)
                setStep(1)

            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        }
    }

    return { loading, sending, error, form, actions, step, stepTab }
}

export default useContactDrawer

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'email',
        }
    },
    country_code: {
        value: '+49',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options: [],
        md:3,
        config: {
            id: 'country_code',
            type: 'select',
            fullWidth: true,
        },
        rules: { type: 'select' }
    },
    phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        md:9,
        config: {
            id: 'phone',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 8,
            max: 10
        }
    },
    message: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'message',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 6
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
}