import React, { useState, useEffect } from 'react';
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import './ArtworkCarousel.css'
import classNames from "classnames"
import { CONFIG_SETTINGS } from '../../../config';
import { isValidDocument } from '../../../shared/utility';
import { Avatar, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import GenericImage from '../../../components/Structure/DisplayData/GenericImage';
import noimage from '../../../assets/noimage.png'

const responsive = {
    mobile: {
        breakpoint: { max: 4000, min: 0 },
        items: 1
    }
};

const ArtworkCarousel = ({ images }) => {

    const classes = useStyles()
    const [nextSlide, setNextSlide] = useState(false)
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('xs'))

    const selectedImages = images.map(image => {
        const isValid = isValidDocument(image.web_image)
        const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image.web_image}` : noimage
        return selectedImage
    })
    
    let _images = selectedImages.map((image, key) => <Avatar key={`image${key}`} src={image} variant="rounded" className={classes.avatarIcon} />)



    const CustomDot = ({ index, onClick, active }) => {

        const handleClick = async () => {
            setNextSlide(true)
            await onClick()
            setNextSlide(false)
        }

        return (
            <button
                onClick={(e) => {
                    handleClick()
                    e.preventDefault()
                }}
                className={classNames("custom-artworkmodal-dot", {
                    "custom-artworkmodal-dot--active": active
                })}
                style={{ cursor: 'pointer' }}
            >
                {React.Children.toArray(_images)[index]}
            </button>
        );
    };

    return (
        <div>
            <Carousel
                responsive={responsive}
                autoPlay={!nextSlide}
                ssr
                infinite
                keyBoardControl
                centerMode={false}
                customTransition="transform 300ms ease-in-out"
                showDots
                arrows={false}
                renderDotsOutside
                dotListClass='carouselArtworkModalDotList'
                customDot={<CustomDot />}
            >
                {selectedImages.map(image =>
                    <div className={classes.image_container}>
                        <GenericImage src={image} fullpath ratio='3/4' />
                    </div>
                )}
            </Carousel>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        color: 'white',
        background: 'black',
        paddingTop: 80,
        paddingBottom: 80,
        //padding: 100,
        //[theme.breakpoints.only('xs')]: {
        //    paddingLeft: 32,
        //    paddingRight: 32,
        //}
    },
    title: {
        fontSize: 32,
        fontWeight: 700
    },
    discipline: {
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: '0.265em'
    },
    tweet: {
        fontSize: 24,
        fontWeight: 200,
    },
    biography: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '277.24%'
    },
    full: {
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: '0.525em'
    },
    image_container: {
        maxWidth: 500,
        margin: 'auto'
    },
    image: {
        width: '100%',
        //height: '100%',
        paddingTop: '133%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 10,
    },
    divider: {
        backgroundColor: grey[600]
    },
    avatarIcon: {
        boxSizing: 'border-box',
        width: 37.5,
        height: 50,
        borderRadius: 8,
        background: 'black'
    }
}))

export default ArtworkCarousel;