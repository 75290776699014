import React, { useState, useEffect, useRef } from "react"
import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import { useTheme, withStyles } from "@material-ui/styles"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import StopIcon from '@material-ui/icons/Stop'
import StopOutlinedIcon from '@material-ui/icons/StopOutlined'
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import { CONFIG_SETTINGS } from "../../../../config"
import { isValidDocument } from "../../../../shared/utility"
import ReactPlayer from 'react-player'


const styles = makeStyles(theme => ({
    root: {
        padding: 80,
        background: '#FCFCFC',
        [theme.breakpoints.down('sm')]: {
            padding: 32
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
    },
    divider: {
        backgroundColor: grey[600]
    },
    noClasses: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 16,
        fontWeight: 300
    },
    level: {
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: '0.165em',
        color: theme.palette.secondary.main,
        [theme.breakpoints.only('xs')]: {
            fontSize: 14,
        }
    },
    levelInd: {
        color: theme.palette.secondary.main,
        fontSize: 12
    },
    master_content: {
        fontSize: 16,
        fontWeight: 700
    },
    heading: {
        fontSize: 14,
        fontWeight: 600
    },
    secondaryHeading: {
        fontSize: 12,
        fontWeight: 600,
        color: grey[600],
    },
    details: {
        fontSize: 12,
        fontWeight: 400,
        color: grey[600],
    },
    media_message: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        width: '100%',
        transform: 'translateY(-50%)',
        '& p': {
            fontSize: 18,
            fontWeight: 400,
        },
        '& span': {
            fontWeight: 600,
            textDecoration: 'underline',
            '&:hover': {
                cursor: 'pointer'
            }
        }
    },
    media: {
        background: 'rgb(0 0 0 / 42%)',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        border: 'none',
        '& video': {
            width: '100%',
            height: '100%',
            objectFit: 'fill',
        },
    },
    media_container: {
        position: 'relative',
        width: '100%',
        height: 0,
        paddingTop: '56%',
    },
    video_menu: {
        background: 'white',
        maxHeight: 300,
        overflowY: 'auto',
        border: '1px solid #DFDFDF',
        borderRadius: 5,
    },
    video_duration: {
        fontSize: 14,
    }
}))

const Masterclass = ({ is_auth, history, masterclass, content, onOpenLogIn, onOpenSignUp }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))
    const videoRef = useRef()

    const [play, setPlay] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [menuHeight, setMenuHeight] = useState(false)

    const { video, title, masterclass_level, masterclass_level_id,
        web_image, mobile_image, duration } = masterclass

    const image = mobile ? mobile_image : web_image
    let isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : content.noImage

    isValid = isValidDocument(video)
    const _video = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${video}` : null

    let level = []
    for (let i = 0; i < 5; i++) {
        level.push(i < masterclass_level_id ?
            <StopIcon className={classes.levelInd} /> :
            <StopOutlinedIcon className={classes.levelInd} />)
    }

    const arrowIcon = <SVGIcon src={content.arrow} size={15} colorStroke={'black'} />
    const expandedIcon = <SVGIcon src={content.arrow} size={15} colorStroke={theme.palette.secondary.main} />

    useEffect(() => {
        if (videoRef && videoRef.current)
            setMenuHeight(videoRef.current.offsetHeight)
    }, [videoRef])
    /*
        const onFileChange = async (event) => {
            try {
                let selectedFile = event.target.files[0]
    
                console.log(selectedFile)
    
                const options = { headers: { 'Content-Type': selectedFile.type } }
                let url = "https://kuvik-dev-public.s3.amazonaws.com/masterclass/2/video-ddb05aa8-a87d-4623-a0bd-9d52e9f26108.mp4?Content-Type=mp4&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAT2QYXQN2BRRCLBQS%2F20220201%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220201T170630Z&X-Amz-Expires=900&X-Amz-Signature=2936f002230bc7c95a165cb969ac7983d40fd16f22532cbd7cba9d1ab5bd666b&X-Amz-SignedHeaders=host"
    
                await axios.put(url, selectedFile, options);
    
            } catch (error) { }
    
        }
    */
    return (
        <MainContainer className={classes.root}>
            {/*<input type="file" onChange={onFileChange} />*/}
            <Grid container alignItems='flex-end' spacing={2}>
                <Grid item xs={12} sm={8}>
                    <Grid container alignItems='center' justifyContent="space-between">
                        <Grid item>
                            <Typography className={classes.title}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.level}>
                                {masterclass_level}
                            </Typography>
                            {level}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography className={classes.master_content}>
                        {content.master_content}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <div className={classes.media_container} ref={videoRef}>
                        {is_auth ?
                            (_video ?
                                <ReactPlayer
                                    url={_video}
                                    playing={play}
                                    className={classes.media}
                                    light
                                    controls
                                    width='100%' height='100%'
                                    onClickPreview={() => setPlay(true)}
                                />
                                :
                                <Typography className={classes.noClasses} align='center'>
                                    {content.noContent}
                                </Typography>
                            )
                            :
                            <>
                                <img src={selectedImage} className={classes.media} style={{ filter: 'brightness(0.3)' }} />
                                <div className={classes.media_message}>
                                    <Typography align='center'>
                                        <span onClick={onOpenSignUp}>{content.signUp}</span> {content.or} <span onClick={onOpenLogIn}>{content.logIn}</span>
                                    </Typography>
                                    <Typography align='center'>
                                        {content.message}
                                    </Typography>
                                </div>
                            </>
                        }
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className={classes.video_menu} style={!mobile && _video ? { maxHeight: menuHeight } : {}}>
                        <Accordion expanded={expanded} onChange={() => setExpanded(value => !value)}>
                            <AccordionSummary
                                expandIcon={expanded ? expandedIcon : arrowIcon}
                            >
                                <Grid container alignItems='center' spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.heading}>1. Introduction</Typography>
                                    </Grid>
                                    <Grid item>
                                        <SVGIcon src={content.clock} size={10} color={grey[600]} />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography className={classes.secondaryHeading}>
                                            {duration} {content.minutes}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                AAAAAAAAA
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={arrowIcon}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.heading}>Location</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.secondaryHeading}>Select trip destination</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                EEEEEEEEEEEEEE
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={arrowIcon}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.heading}>Location</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.secondaryHeading}>Select trip destination</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                EEEEEEEEEEEEEE
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={arrowIcon}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.heading}>Location</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.secondaryHeading}>Select trip destination</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                EEEEEEEEEEEEEE
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Grid container alignItems='center' justifyContent="space-between">
                        <Grid item>
                            BOTONES
                        </Grid>
                        <Grid item>
                            <Grid container alignItems='center' spacing={1}>
                                <Grid item>
                                    <Typography className={classes.video_duration}>
                                        {duration} {content.minutes}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <SVGIcon src={content.clock} size={16} color={grey[900]} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainContainer>
    )
}

export default Masterclass

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {
        background: 'rgba(189, 139, 64, 0.07)',
    },
})(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
    root: {
        borderBottom: 'none',
        minHeight: 56,
        paddingLeft: 20,
        paddingRight: 20,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
            background: 'rgba(189, 139, 64, 0.07)',
        },
    },
}))(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        paddingTop: 0,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 32,
    },
}))(MuiAccordionDetails);