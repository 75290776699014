import React, { useEffect, useState, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { makeStyles, Typography, useMediaQuery, useScrollTrigger, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import ProRouter from '../../routes/pro-router';
import { actionTypes } from '../../store/actions';
import Topbar from './components/Topbar';
import cx from 'classnames'
import useKuvikProLayout from './useKuvikProLayout';
import ProfilePictureModal from '../../modals/ProfilePictureModal/ProfilePictureModal'
import general_translations from '../../texts/eng/pro/general-translations'
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer';
import Sidebar from './components/Sidebar';
import ArtistRegisterDrawer from '../ArtistRegisterDrawer/ArtistRegisterDrawer';
import LogInDrawer from '../LogInDrawer/LogInDrawer';
import ArtworkModal from '../../modals/ArtworkModal/ArtworkModal';
import ConfirmationDrawer from '../ConfirmationDrawer/ConfirmationDrawer';
import ContactDrawer from '../ContactDrawer/ContactDrawer';
import PurchaseEnquiryDrawer from '../PurchaseEnquiryDrawer/PurchaseEnquiryDrawer';

const KuvikProLayout = ({ history, language, blur, arrowTranslate, is_auth,
    onUpdateUserData, onUpdateAuthStatus, onUpdateLanguage, onUpdateProMode }) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    const { loading, sending, success, error, actions } = useKuvikProLayout({
        history, onUpdateUserData, onUpdateAuthStatus, onUpdateProMode, is_auth
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    useInterval(() => {
        actions.onCheckTokenExpirationDate()
    }, 3000)

    return (
        <LoadingContainer loading={loading} sending={sending} success={success} error={error} onCloseSuccess={actions.onCloseError} onCloseSuccess={actions.onCloseSuccess}>
            <div className={cx({
                [classes.root]: true,
                [classes.root_blur]: blur
            })}>
                <LogInDrawer />
                <ProfilePictureModal />
                <ArtworkModal />
                <ConfirmationDrawer history={history} />
                <ContactDrawer history={history} />
                <PurchaseEnquiryDrawer />
                <ArtistRegisterDrawer />
                <main className={classes.main} >
                    <Topbar history={history} ismobile={ismobile} onItemSelected={actions.onItemSelected} />
                    <Sidebar history={history} ismobile={ismobile} />
                    <ProRouter />
                </main>

            </div>
        </LoadingContainer>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        //background:'#edf2f8',
        minHeight: '100vh',
        // overflowY: 'auto',
        filter: 'none'

    },
    root_blur: {
        filter: 'blur(3px)'
    },
    main: {
        marginLeft: 125,
        // width:'100%',
        boxSizing: 'border-box',
        //padding:16,
        //overflowY: 'auto'

    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const mapStateToProps = state => {
    return {
        is_auth: state.is_auth,
        blur: state.blur,
        user: state.user,
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateLanguage: (language) => dispatch({ type: actionTypes.SYS_UPDATE_LANGUAGE, language }),
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
        onUpdateProMode: (pro_mode) => dispatch({ type: actionTypes.SYS_PRO_MODE, pro_mode }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KuvikProLayout);

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}