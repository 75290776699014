import React from "react"
import { Button, Divider, Grid, Icon, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from "@material-ui/styles"
import { grey } from "@material-ui/core/colors"
import StopIcon from '@material-ui/icons/Stop';
import RoundedButton from "../../../../components/Actions/RoundedButton"
import PageContainer from "../../../../components/Structure/Layouts/PageContainer";
import MainContainer from "../../../../components/Structure/Layouts/MainContainer";

const styles = makeStyles(theme => ({
    root: {
        marginTop:80, marginBottom:80,
        //paddingLeft: 100,
        //paddingRight: 100,
        //[theme.breakpoints.down('sm')]: {
        //    padding: 32
        //},
        boxSizing:'border-box',
        position: 'relative',
        width: '100%',
        //height: '100%',
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em',
    },
    contentTitle: {
        fontSize: 32,
        fontWeight: 600,
        '& span': {
            color: theme.palette.primary.main,
        }
    },
    divider: {
        backgroundColor: grey[600]
    },
    description: {
        color: '#797979',
        fontSize: 18,
        fontWeight: 600,
    },
    listItem: {
        color: '#5F5F5F',
        fontSize: 18,
        fontWeight: 400,
    },
    listItemIcon: {
        width: 13,
        height: 13,
        marginRight: 12,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
    },
    data: {
        minHeight: '60vh',
        paddingTop: 50,
        [theme.breakpoints.down('sm')]: {
            minHeight: 'inherit'
        }
    },
    imageContainer: {
        position: 'relative',
        top: 0,
        width: '100%',
        height: '100%',
        maxHeight: 600,
        boxSizing: 'border-box',
        borderRadius:10,
        [theme.breakpoints.down('sm')]: {
        },
    },
    image: {
        position:'absolute',
        top:0, left:0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition:'center',
        borderRadius:10,
    },
    tech: {
        width: 120,
        justifyContent: 'center'
    },
    icon: {
        width: 77,
        height: 77,
        margin: 'auto',
        backgroundColor: 'rgba(21, 21, 21, 0.94)',
        borderRadius: '100%',
        position: 'relative'
    },
    techLabel: {
        fontSize: 18,
        fontWeight: 600,
        color: theme.palette.secondary.main
    }
}))

const icon_styles = { margin: 0, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }

const ShowYourTalent = ({ history, content, openSignUpDrawer }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title}>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.imageContainer}>
                            <img src={content.image} className={classes.image} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.data}>
                            <Grid container justifyContent='center' alignItems='stretch' spacing={6}>
                                <Grid item xs={12}>
                                    <Typography className={classes.contentTitle}>
                                        {content.titleS[0]} <span>{content.titleS[1]}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.description}>
                                        {content.description}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={4}>
                                        {content.list.map(item => (
                                            <Grid item>
                                                <Typography className={classes.listItem}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <StopIcon className={classes.listItemIcon} />
                                                        </Grid>
                                                        <Grid item xs>
                                                            {item}
                                                        </Grid>
                                                    </Grid>
                                                </Typography>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item xs sm={5}>
                                    <RoundedButton fullWidth onClick={openSignUpDrawer}>
                                        {content.button}
                                    </RoundedButton>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </MainContainer>
        </div >
    )
}

export default ShowYourTalent