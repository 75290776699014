import React from "react"
import { Card, CardActionArea, CardContent, Divider, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import StopIcon from '@material-ui/icons/Stop';
import PageContainer from "../../../../components/Structure/Layouts/PageContainer";
import MainContainer from "../../../../components/Structure/Layouts/MainContainer";

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 100,
        paddingBottom: 100,
        [theme.breakpoints.down('sm')]: {
            //padding: 32
        },
        boxSizing:'border-box',
        background: 'black',
        color: 'white',
        position: 'relative',
        //width: '100%',
        //height: '100%',
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em',
    },
    conditions: {
        color: grey[500],
        fontSize: 16,
        fontWeight: 500
    },
    divider: {
        backgroundColor: grey[600]
    },
    cardsContainer: {
        [theme.breakpoints.up('sm')]: {
            padding: 64
        }
    },
    card: {
        width: '100%',
        height: 'auto',
        borderRadius: 5,
        position: 'relative',
    }
}))

const MemberPlans = ({ history, content }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title}>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.cardsContainer}>
                            <Grid container justifyContent='center' spacing={4}>
                                {content.payment_plans.map(plan => (
                                    <Grid item xs={12} sm>
                                        <PaymentPlanCard {...plan} />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.conditions} align='center'>
                            {content.conditions}
                        </Typography>
                    </Grid>
                </Grid>
            </MainContainer>
        </div >
    )
}

export default MemberPlans


const stylesCard = makeStyles(theme => ({
    root: {
        margin: 'auto',
        width: '100%',
        height: 'auto',
        maxWidth: 450,
    },
    card: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        padding: 12,
        color: 'white',
        background: 'black',
        border: '1px solid white'
    },
    divider: {
        width: 50,
        margin: 'auto',
        backgroundColor: 'white'
    },
    price: {
        fontSize: 42,
        fontWeight: 700
    },
    title: {
        color: theme.palette.secondary.main,
        fontSize: 32,
        fontWeight: 600
    },
    time: {
        fontSize: 16,
        fontWeight: 600
    },
    listItem: {
        fontSize: 16,
        fontWeight: 600,
    },
    listItemIcon: {
        width: 13,
        height: 13,
        marginRight: 12,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
    },
    listItemIconD: {
        width: 13,
        height: 13,
        marginRight: 12,
        color: '#484848',
        backgroundColor: '#484848',
    },
}))

const PaymentPlanCard = ({ title, price, time, benefits }) => {

    const classes = stylesCard()

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardActionArea>
                    <CardContent>
                        <Grid container justifyContent='center' spacing={4}>
                            <Grid item xs={12}>
                                <Typography gutterBottom className={classes.price} align='center'>
                                    {price}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider className={classes.divider} variant='middle' />
                                <Typography gutterBottom className={classes.title} align='center'>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {benefits.active.map(item => (
                                        <Grid item xs={12}>
                                            <Typography className={classes.listItem}>
                                                <Grid container>
                                                    <Grid item>
                                                        <StopIcon className={classes.listItemIcon} />
                                                    </Grid>
                                                    <Grid item xs>
                                                        {item}
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                        </Grid>
                                    ))}
                                    {benefits.unactive.map(item => (
                                        <Grid item xs={12}>
                                            <Typography className={classes.listItemD}>
                                                <Grid container>
                                                    <Grid item>
                                                        <StopIcon className={classes.listItemIconD} />
                                                    </Grid>
                                                    <Grid item xs>
                                                        {item}
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography gutterBottom className={classes.time} align='center'>
                                    {time}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    )
}
