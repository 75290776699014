import React, { useEffect, useRef, useState } from "react"
import { alpha, Fade, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import Carousel from 'react-material-ui-carousel'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PageContainer from "../../../../components/Structure/Layouts/PageContainer";
import MainContainer from "../../../../components/Structure/Layouts/MainContainer";
import cx from 'classnames'
import { grey } from "@material-ui/core/colors";
import { CONFIG_SETTINGS } from "../../../../config";
import { isValidDocument } from "../../../../shared/utility";

const styles = makeStyles(theme => ({
    root: {
        padding: '80px 0px',
        //paddingLeft: 100,
        //paddingRight: 100,
        //[theme.breakpoints.down('sm')]: {
        //    padding: 32
        //},
        backgroundColor: 'black',
        color: 'white',
    },
    container: {
        padding: 100,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 32,
            paddingRight: 32,
        }
    },
    text: {
        //fontSize: 24,
        fontWeight: 300
    },
    text2: {
        //fontSize: 18,
        fontWeight: 300
    },
    title: {
        fontWeight: 500,
    },
    subtitle: {
        //fontSize: 30,
        fontWeight: 500,
        '@global span': {
            color: theme.palette.secondary.main
        }
    },
    carouselContainer: {
        margin: 10
    },
    noContent: {
        fontSize: 16,
        fontWeight: 300
    },
}))

const VirtualExhibitions = ({ history, content, exhibitions, openSignUpDrawer }) => {

    const classes = styles()
    const theme = useTheme()

    return (
        <div className={classes.root}>
            <MainContainer>
                <>
                    <Grid container spacing={6} justifyContent='center'>
                        <Grid item xs={12}>
                            <Typography variant='h4' className={classes.title}>
                                {content.title}
                            </Typography>
                            <Typography variant='h6' className={classes.subtitle}>
                                {content.subtitle[0]} <span>{content.subtitle[1]}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2' align='justify' className={classes.text}>
                                {content.description}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div>
                        {exhibitions && exhibitions.length > 0 ?
                            <ExhibitionCarousel banners={exhibitions} noImage={content.noImage} />
                            :
                            <Grid item xs={12}>
                                <Typography className={classes.noContent} align='center'>
                                    {content.noContent}
                                </Typography>
                            </Grid>
                        }
                    </div>
                    <div style={{ marginTop: 40 }}>
                        <Grid container justifyContent='space-between' alignItems='center' spacing={4}>
                            <Grid item>
                                <Typography variant='body1' className={classes.text2}>
                                    {content.bottom_text}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <RoundedButton
                                    size='large'
                                    endIcon={<ArrowForwardIosIcon color='white' />}
                                    onClick={openSignUpDrawer}
                                >
                                    {content.apply_button.title}
                                </RoundedButton>
                            </Grid>
                        </Grid>
                    </div>
                </>
            </MainContainer>
        </div>
    )
}

export default VirtualExhibitions


const ExhibitionCarousel = ({ banners, noImage }) => {

    const classes = useExhibitionCarousel()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.only('xs'))

    const [selected, setSelected] = useState(0)

    useInterval(() => {
        let next = selected + 1
        if(next > banners.length - 1 ) next = 0
        setSelected(next)
    }, 4000)

    return (
        <div>
            <div className={classes.carrousel}>
                {banners.map((item, key) => {
                    let image = ismobile ? item.mobile_banner : item.web_banner
                    const isValid = isValidDocument(image)
                    image = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : noImage

                    return (
                        <Fade in={selected === key} timeout={1000} key={key.toString()}>
                            <div className={cx({ [classes.image_wrapper]: true, [classes.hidden]: selected !== key })}>
                                <img alt='' className={classes.image} src={image} />
                                <div className={classes.text_content}>
                                    <Typography variant='h6' className={classes.description}>
                                        {item.short_description}
                                    </Typography>
                                    <Typography variant='h5' className={classes.title}>
                                        {item.title}
                                    </Typography>
                                </div>
                                <div className={classes.overlay}>
                                    <Typography className={classes.date}>
                                        {item.date_text}
                                    </Typography>
                                </div>
                            </div>
                        </Fade>
                    )
                })}
            </div>
            <div style={{ marginTop: 40 }}>

                <Grid container justifyContent='center' spacing={4}>
                    {banners.map((item, key) => {
                        return (
                            <Grid item key={`dot-${key}`}>
                                <div className={cx({
                                    [classes.dot]: true,
                                    [classes.dot_active]: selected === key
                                })} onClick={() => setSelected(key)} />
                            </Grid>
                        )
                    })}
                </Grid>
            </div>

        </div>
    )
}

const useExhibitionCarousel = makeStyles(theme => ({
    carrousel: {
        marginTop: 40,
        width: '100%',
        //background:'red',
        position: 'relative',
        paddingTop: '40%',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '100%',

        }
    },
    image_wrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 10
        //background:'green'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        position: 'absolute',
        borderRadius: 10

    },
    hidden: {
        opacity: 0,

    },
    carrousel_button: {
        position: 'absolute',
        top: 0,
        left: 0,
        background: '#184EA2',
        width: 150,
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        zIndex: 5,
        cursor: 'pointer'
    },
    dot: {
        background: '#5F5F5F',
        borderRadius: '50%',
        width: 10,
        height: 10,
        marginBottom: 8,
        marginRight: 8,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    dot: {
        width: 80,
        height: 4,
        background: grey[300],
        cursor: 'pointer'
    },
    dot_active: {
        background: theme.palette.secondary.main
    },
    overlay: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        background: alpha("#000", 0.0),
        zIndex: 2
    },
    description: {
        [theme.breakpoints.only('xs')]: {
            fontSize: 18
        }
    },
    title: {
        fontSize: 32,
        [theme.breakpoints.only('xs')]: {
            fontSize: 24
        }
    },
    date: {
        fontSize: 18,
        fontWeight: 500,
        '& span': {
            fontSize: 24,
            fontWeight: 400,
            [theme.breakpoints.only('xs')]: {
                fontSize: 20
            }
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 16
        }
    },
    text_content: {
        width: '100%',
        position: 'absolute',
        top: 0, left: 0,
        color: 'white',
        zIndex: 3,
        padding: 40,
        boxSizing: 'border-box'
    }
}))


function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}