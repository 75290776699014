import React, { useEffect, useState } from "react"
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, Grid, Icon, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./MasterclassesCarousel.css"
import { grey } from "@material-ui/core/colors"
import { useTheme } from "@material-ui/styles"
import { CONFIG_SETTINGS } from "../../../../../config"
import { isValidDocument, onGetDateFormat } from "../../../../../shared/utility"
import { catalogs } from "../../../../../texts/eng/catalogs"
import PageContainer from "../../../../../components/Structure/Layouts/PageContainer"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import cx from 'classnames'

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1200 },
        items: 3,
        partialVisibilityGutter: 80
    },
    largeDesktop: {
        breakpoint: { max: 1200, min: 1000 },
        items: 3,
        partialVisibilityGutter: 50
    },
    desktop: {
        breakpoint: { max: 1000, min: 700 },
        items: 3,
    },
    largeTablet: {
        breakpoint: { max: 700, min: 450 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 450, min: 350 },
        items: 1,
        partialVisibilityGutter: 100
    },
    mobile: {
        breakpoint: { max: 350, min: 0 },
        items: 1,
    }
}

const styles = makeStyles(theme => ({
    root: {
        paddingBottom: 100,
        paddingLeft: 150,
        paddingRight:100,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 0,
            paddingRight:0,
        }
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    noMasterclasses: {
        fontSize: 16,
        fontWeight: 300
    },
    carouselItem: {
        height: 650,
        marginTop: -100,
        '& li': {
            margin: 10,
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                padding: 10,
                boxSizing: 'border-box'
            }
        },
        '& ul.react-multi-carousel-dot-list': {
            top: 0,
            marginTop: 600,
            height: 'fit-content',
        }
    },
    lineButton: {
        width: 120,
        height: 1.5,
        borderRadius: 0,
        //marginLeft: 10,
        '&:hover': {
            cursor: 'pointer'
        }
    },
}))

const Masterclasses = ({ history, masterclasses, content }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const tablet = useMediaQuery(theme.breakpoints.only('md'))
    const deviceType = mobile ? 'mobile' : (tablet ? 'tablet' : 'desktop')

    return (
        <div className={classes.root}>
            <PageContainer>
                <Grid container alignItems='center' spacing={3}>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} align='right'>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>
            </PageContainer>
            {/*{masterclasses.length > 0 ?*/}
                <Carousel
                    responsive={responsive}
                    ssr
                    infinite
                    autoPlay
                    partialVisible
                    keyBoardControl
                    centerMode={false}
                    customTransition="transform 300ms ease-in-out"
                    transitionDuration={500}
                    deviceType={deviceType}
                    arrows={false}
                    itemClass="masterclasses-carouselItem"
                >
                    {masterclasses && masterclasses.map(masterclass =>
                        <MasterclassCard
                            {...masterclass}
                            history={history}
                            noImage={content.noImage}
                            content={content}
                        />
                    )}
                </Carousel>
                {/*:
                <Typography className={classes.noMasterclasses} align='center'>
                    {content.noContent}
                </Typography>
                }*/}
        </div >
    )
}

export default Masterclasses

const stylesCard = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        boxShadow: 'none',
        margin: 'auto',
    },
    cardActionArea: {
        //pointerEvents: 'none',
        position: 'relative',
        borderRadius: 25,
        '&:hover':{
            cursor:'initial'
        }
    },
    cardContent: {
        display: 'none',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        padding: 32,
        boxSizing: 'border-box'
    },
    showCardContent: {
        display: 'block'
    },
    media: {
        width: '100%',
        height: '100%',
        background: 'grey',
        paddingTop: "calc(100% * (4 / 3))",
        borderRadius: 25,
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    mediaH: {
        filter: 'brightness(0.5)'
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        color: theme.palette.secondary.main
    },
    description: {
        fontSize: 28,
        fontWeight: 500,
        color: grey[700],
        letterSpacing: '0.265em'
    },
    nftIcon: {
        background: theme.palette.secondary.main,
        borderRadius: '100%',
        padding: 10
    },
    documentButton: {
        textTransform: 'capitalize'
    },
    buyButton: {
        textTransform: 'capitalize'
    },
    public:{
        fontSize: '1.125rem',
        color: 'white',
        padding: '12px 28px',
        background: 'transparent',
        border: '1px solid white',
    }
}))

const MasterclassCard = ({ content, masterclass_title, masterclass_artist, 
    masterclass_web_image, masterclass_mobile_image, noImage }) => {

    const classes = stylesCard()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [hover, setHover] = useState(false)

    const _image = mobile ? masterclass_mobile_image : masterclass_web_image
    const isValid = isValidDocument(_image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : noImage


    return (
        <Card className={classes.root}>
            <CardActionArea
                className={classes.cardActionArea}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <CardMedia
                    className={cx({
                        [classes.media]: true,
                        [classes.mediaH]: hover
                    })}
                    image={selectedImage}
                    title={"Artist Masterclasses"}
                />
                <CardContent className={cx({
                    [classes.cardContent]: true,
                    [classes.showCardContent]: hover
                })}>
                    <Grid container justifyContent='flex-end' alignItems='center'>
                        <Grid item>
                            <div className={classes.public}>
                                {content.public}
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ background: 'transparent', padding: 0, paddingTop: 10 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            {masterclass_title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.description}>
                            {masterclass_artist}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}