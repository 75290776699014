import React, { useRef, useEffect, useState } from "react"
//import { Document, Page } from 'react-pdf'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { Divider, Grid, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import "react-multi-carousel/lib/styles.css"
import { grey } from "@material-ui/core/colors"
import { useTheme } from "@material-ui/styles"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import HTMLFlipBook from 'react-pageflip';
import FileSaver from 'file-saver'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser';

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 150,
        paddingBottom: 80,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 100,
            //padding: 32
        },
    },
    content: {
        paddingTop: 80,
        paddingLeft: 80,
        paddingRight: 80,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    subtitle: {
        fontSize: 18,
        fontWeight: 300,
        textTransform: 'uppercase',
        letterSpacing: '0.025em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    document: {
        height: '100%',
    },
    page: {
        width: '100%',
        '& canvas': {
            margin: 'auto',
        }
    },
    leftPage: {
        '& canvas': {
            marginLeft: 'auto',
            paddingRight: 5
        }
    },
    rightPage: {
        '& canvas': {
            marginRight: 'auto',
            paddingLeft: 5
        }
    },
    button: {
        background: theme.palette.secondary.main,
        color: 'white',
        '&:hover': {
            background: theme.palette.secondary.dark,
        }
    },
    noPdf: {
        fontSize: 16,
        fontWeight: 300
    },
}))

const VKGallery = ({ history, subtitle, iframe, pdfGallery, content }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('xs'))

    const [page, setPage] = useState(1)
    const [numPages, setNumPages] = useState(null)
    const [documentSize, setDocumentSize] = useState({ width: 0, height: 0 })

    const documentRef = useRef(null)
    const bookRef = useRef(null)

    useEffect(() => {
        let _documentSize = { ...documentSize }

        if (documentRef && documentRef.current) {
            let _width = ismobile ? documentRef.current.offsetWidth : Math.ceil(documentRef.current.offsetWidth / 2)
            _documentSize.width = _width
            _documentSize.height = _width * 1.41
        }

        setDocumentSize(_documentSize)
    }, [pdfGallery, documentRef, ismobile])


    const onDocumentLoadSuccess = ({ numPages }) => {
        //let _pages = ismobile ? numPages : Math.ceil(numPages / 2)
        let _pages = numPages
        setNumPages(_pages)
    }

    const onChangePage = (factor) => {
        if (ismobile)
            setPage(prevPage => prevPage + factor)
        else
            setPage(prevPage => prevPage + factor * 2)

        if (factor > 0)
            bookRef.current.pageFlip().flipNext()
        else
            bookRef.current.pageFlip().flipPrev()

    }

    const onFileChange = async (event) => {
        try {
            let selectedFile = event.target.files[0]

            console.log(selectedFile)

            const options = { headers: { 'Content-Type': selectedFile.type } }
            let url = "https://kuvik-dev-public.s3.amazonaws.com/magazine/4/file-599047b9-4989-4701-b147-34016a574c95.pdf?Content-Type=pdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAT2QYXQN2BRRCLBQS%2F20220128%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220128T200816Z&X-Amz-Expires=900&X-Amz-Signature=42ca0ace84dd83948732c99733455f8e9ba301b7805f491e109af21e87eae2ca&X-Amz-SignedHeaders=host"

            await axios.put(url, selectedFile, options);

        } catch (error) { }

    }


    return (
        <div className={classes.root}>
            {/* <input type="file" onChange={onFileChange} />*/}
            <MainContainer>
                <Grid container alignItems='center' spacing={3}>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} align='center'>
                            {content.title}
                        </Typography>
                        <Typography className={classes.subtitle} align='center'>
                            {subtitle ? subtitle : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>
            </MainContainer>
            {iframe ?
                <div style={{ marginTop: 50 }}>
                    {ReactHtmlParser(iframe)}
                </div>
                :
                <Typography className={classes.noPdf} align='center'>
                    {content.noContent}
                </Typography>
            }
            {/*<div className={classes.content}>
                <Grid container justifyContent="center" alignItems='center' spacing={2}>
                    pdfGallery ?
                        <>
                            <Grid item xs={12}>
                                <div ref={documentRef} style={{ height: ismobile ? '100%' : documentSize.height, width: '100%' }}>
                                    <Document
                                        file={pdfGallery}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        className={classes.document}
                                    >
                                        {ismobile ?
                                            <HTMLFlipBook width={documentSize.width} height={documentSize.width * 1.41} ref={bookRef}>
                                                {([...Array(numPages).keys()]).map(index => (
                                                    <div>
                                                        <Page pageNumber={index + 1} className={classes.page} width={documentSize.width} />
                                                    </div>
                                                ))}
                                            </HTMLFlipBook>
                                            :
                                            <HTMLFlipBook width={documentSize.width} height={documentSize.width * 1.41} ref={bookRef}>
                                                {([...Array(numPages).keys()]).map(index => (
                                                    <div>
                                                        <Page pageNumber={index + 1} className={(index + 1) % 2 !== 0 ? classes.leftPage : classes.rightPage} width={documentSize.width} />
                                                    </div>
                                                ))}
                                            </HTMLFlipBook>
                                        }
                                    </Document>
                                </div>
                            </Grid>
                            <Grid item>
                                <IconButton disabled={page <= 1} onClick={() => onChangePage(-1)} className={classes.button}>
                                    <NavigateBeforeIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography color='secondary'>
                                    {page} - {numPages}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton disabled={page === numPages} onClick={() => onChangePage(+1)} className={classes.button}>
                                    <NavigateNextIcon />
                                </IconButton>
                            </Grid>
                        </>
                        :
                        <Grid item xs={12}>
                            <Typography className={classes.noPdf} align='center'>
                                {content.noContent}
                            </Typography>
                        </Grid>
                </Grid>
            </div>
                    */}
        </div >
    )
}

export default VKGallery