import logo from '../../../assets/logo.png'
import logo_white from '../../../assets/logo-white.png'
import logo_dark from '../../../assets/logo_dark.png'
import search_icon from '../../../assets/icons/search.svg'

import fileIcon from '../../../assets/icons/file.svg'
import kuvikDoc from '../../../assets/KUVIKBrochure.pdf'
import app_store_button from '../../../assets/apple_button.png'
import play_store_button from '../../../assets/google_button.png'

// Social media icons
import FB from '../../../assets/icons/facebookWhite.svg'
import IG from '../../../assets/icons/instagramWhite.svg'
import TW from '../../../assets/icons/twitterWhite.svg'
import TT from '../../../assets/icons/tiktok.svg'
import PT from '../../../assets/icons/pinterest.svg'
import YT from '../../../assets/icons/youtube.svg'
import WE from '../../../assets/icons/emblemweb.svg'
import OP from '../../../assets/icons/opeansea.svg'
import VM from '../../../assets/icons/vimeo.svg'

import mini_world from '../../../assets/icons/net.svg'
import mini_lock from '../../../assets/icons/mini_lock.svg'
import vertical_arrow from '../../../assets/icons/vertical_arrow.svg'

import goldLogo from '../../../assets/logo_gold.svg'
import footerBG1 from '../../../assets/bgform.png'
import footerBG2 from '../../../assets/exhibitions/artist1.png'
import footerBG3 from '../../../assets/exhibitions/artist4.png'

import contactImage from '../../../assets/contact.png'

import home from '../../../assets/icons/home.svg'
import profile from '../../../assets/icons/profile.svg'
import applications from '../../../assets/icons/applications.svg'
import artists from '../../../assets/icons/artist.svg'
import artworks from '../../../assets/icons/artworks.svg'
import exhibitions from '../../../assets/icons/exhibitions.svg'
import magazines from '../../../assets/icons/magazines.svg'
import masterclasses from '../../../assets/icons/master_classes.svg'
import logout from '../../../assets/icons/logout.svg'

import uploadIcon from '../../../assets/upload.svg'
import emailSent from '../../../assets/icons/check_step.svg'
import noImage from '../../../assets/artist/noImage.png'

const general_translations = {
    sidebar: {
        logo: logo,
        list: [
            {
                name: 'Home',
                path: '/',
                icon: home,
            },
            {
                name: 'Profile',
                path: '/profile',
                icon: profile,
            },
            {
                name: 'Applications',
                path: '/applications',
                icon: applications,
            },
            {
                name: 'Artists',
                path: '/artist',
                icon: artists,
            },
            {
                name: 'Artworks',
                path: '/artworks',
                icon: artworks,
            },
            {
                name: 'Exhibitions',
                path: '/exhibitions',
                icon: exhibitions,
            },
            {
                name: 'Magazines',
                path: '/magazines',
                icon: magazines,
            },
            {
                name: 'Masterclasses',
                path: '/masterclasses',
                icon: masterclasses,
            },
        ],
        pro_button: {
            label: 'PRO',
            url: ''
        },
        logout: {
            name: 'Log out',
            path: '/logout',
            icon: logout,
        },
    },
    contact_drawer: {
        logo: logo,
        title: 'Contact us',
        subtitle: 'We want to help you',
        image: contactImage,
        menu: [
            { id: 'general', value: 1, label: 'General' },
            { id: 'sales', value: 2, label: 'Sales' },
            { id: 'evennt', value: 3, label: 'Event' },
            { id: 'submission', value: 4, label: 'Submission' },
        ],
        form: {
            general: {
                name: {
                    placeholder: 'Name',
                    helperText: 'Introduce your name'
                },
                email: {
                    placeholder: 'Email Address',
                    helperText: 'Introduce your email'
                },
                phone: {
                    placeholder: 'Phone',
                    helperText: 'Introduce your phone'
                },
                message: {
                    placeholder: 'Message',
                    helperText: 'Introduce a message'
                }
            },
            sales: {
                name: {
                    placeholder: 'Name',
                    helperText: 'Introduce your name'
                },
                email: {
                    placeholder: 'Email Address',
                    helperText: 'Introduce your email'
                },
                phone: {
                    placeholder: 'Phone',
                    helperText: 'Introduce your phone'
                },
                message: {
                    placeholder: 'Message',
                    helperText: 'Introduce a message'
                }
            },
            evennt: {
                name: {
                    placeholder: 'Name',
                    helperText: 'Introduce your name'
                },
                email: {
                    placeholder: 'Email Address',
                    helperText: 'Introduce your email'
                },
                phone: {
                    placeholder: 'Phone',
                    helperText: 'Introduce your phone'
                },
                message: {
                    placeholder: 'Message',
                    helperText: 'Introduce a message'
                }
            },
            submission: {
                name: {
                    placeholder: 'Name',
                    helperText: 'Introduce your name'
                },
                email: {
                    placeholder: 'Email Address',
                    helperText: 'Introduce your email'
                },
                phone: {
                    placeholder: 'Phone',
                    helperText: 'Introduce your phone'
                },
                message: {
                    placeholder: 'Message',
                    helperText: 'Introduce a message'
                }
            }
        },
        button: 'Send enquiry',
        account: 'Do you have an account?',
        log_in: 'Log in',
        medias: [
            { id: 1, icon: FB, url: 'https://www.facebook.com/Kuvik-Galerie-106153435174820', target: '_blank' },
            { id: 2, icon: IG, url: 'https://instagram.com/kuvik.art/', target: '_blank' },
            // { id: 3, icon: TW, url: 'https://twitter.com', target: '_blank' },
        ],
        small_text: '* In order to respond to your enquiry Ut urna, vitae sed ut porta. Adipiscing quisque lectus tortor ullamcorper tempor mattis. Tincidunt et tellus porttitor arcu faucibus elementum tincidunt. '
    },
    topbar: {
        logo: logo,
        logo_alt: logo_dark,
        transparent: ['/', '/confirmation', '/about', '/artists/', '/community', '/exhibitions'],
        titles: [
            {
                url: '/artists',
                title: 'Artists'
            },
            {
                url: '/exhibitions',
                title: 'Exhibitions',
                transparent: true
            },
            {
                url: '/magazines',
                title: 'Magazines'
            },
            {
                url: '/masterclass',
                title: 'Masterclass'
            },
            {
                url: '/marketplace',
                title: 'Marketplace'
            },
            {
                url: null,
                title: 'About',
                subtitles: [
                    {
                        url: '/community',
                        title: 'Community',
                    },
                    {
                        url: '/about',
                        title: 'Us',
                    },
                ]
            },
            {
                url: '/contact',
                title: 'Contact',
            },
        ],
        login_button: {
            url: '/login',
            label: 'Log in'
        },
        profile_menu: [
            {
                url: '/kuvik-website',
                label: 'Kuvik Website'
            },
            {
                url: '/logout',
                label: 'Log out'
            }
        ],
        search: search_icon,
    },
    footer: {
        welcome: {
            success_message: [
                'You are already suscribed to our newsletter!',
                'Thank you for join us.'
            ],
            stepper: {
                ctrl_button: vertical_arrow,
                steps: [
                    {
                        title: ['Welcome to', 'kuvik'],
                        subtitle: 'Sign up for updates in exhibitions, artists and events.',
                        button: {
                            url: '/contact',
                            title: 'Request Info'
                        },
                        titleS: 'Looking for:',
                        form: {
                            collecting_art: { label: 'Collecting Art' },
                            exhibitions: { label: 'Exhibitions' },
                            art_news: { label: 'Art News' },
                            knowledge: { label: 'Knowledge' }
                        },

                    },
                    {
                        title: ['Welcome to', 'kuvik'],
                        subtitle: 'Sign up for updates in exhibitions, artists and events.',
                        form: {
                            first_name: {
                                placeholder: 'Enter your name'
                            },
                            last_name: {
                                placeholder: 'Enter your last name'
                            }
                        }
                    },
                    {
                        white: true,
                        title: ['Thank you for joining', 'our community!'],
                        subtitle: 'You have been added to our newsletter, check out your mailbox.',
                        logo: goldLogo,
                        imagesBG: [footerBG1, footerBG2, footerBG3]
                    }
                ]
            }
        },
        explore: {
            title: 'Explore',
            links: [
                { id: 1, label: 'Artist', url: '/community' },
                { id: 2, label: 'Exhibitions', url: '/exhibitions' },
                { id: 5, label: 'Magazines', url: '/magazines' },
                { id: 3, label: 'Marketplace', url: '/marketplace' },
                { id: 4, label: 'Masterclasses', url: '/masterclass' },
            ]
        },
        about: {
            title: 'About us',
            links: [
                { id: 1, label: 'Contact', url: '/contact' },
                //{ id: 2, label: 'Privacy', url: '/privacy' },
                //{ id: 3, label: 'Terms', url: '/terms' },
                //{ id: 4, label: 'FAQ', url: '/faq' },
                { id: 5, label: 'For artist', url: '/for-artist' },
            ]
        },
        app: {
            arrow: vertical_arrow,
            title: 'Download Kuvik catalog and get inspired by our vibrant art-tech offering',
            icon: fileIcon,
            document: kuvikDoc,
            ios: app_store_button,
            android: play_store_button
        },
        social_media: {
            title: 'Stay in touch',
            media: [
                { id: 1, icon: FB, url: 'https://www.facebook.com/Kuvik-Galerie-106153435174820', target: '_blank' },
                { id: 2, icon: IG, url: 'https://instagram.com/kuvik.art/', target: '_blank' },
                //{ id: 3, icon: TW, url: 'https://twitter.com', target: '_blank' },
                //{ id: 4, icon: LI, url: 'https://linkedin.com', target: '_blank' },
                //{ id: 5, icon: YT, url: 'https://youtube.com', target: '_blank' },
            ],
        },
        logo: logo_white,
        secure: {
            icon: mini_lock,
            title: 'Secured with SSL'
        },
        brand: '© 2022 kuvik',
        language_button: {
            icon: mini_world,
            options: [
                { id: 1, value: 'en', label: 'EN' },
                //{ id: 2, value: 'es', label: 'ES' }
            ]
        }
    },
    artist_register_drawer: {
        logo: logo_white,
        steps: [
            {
                title: 'Personal Info',
                form: {
                    optional: {
                        first_name: {
                            label: 'First Name',
                            placeholder: 'First Name'
                        },
                        last_name: {
                            label: 'Last Name',
                            placeholder: 'Last Name'
                        },
                    },
                    fixed: {
                        country_id: {
                            //label: 'Country',
                            placeholder: 'Country'
                        },
                        birth_date: {
                            label: 'Date of Birth',
                            placeholder: 'Date of Birth'
                        },
                        /*email: {
                            label: 'Email Address',
                            placeholder: 'Email Address'
                        },*/
                        country_code: {
                            //label: 'Country code',
                            placeholder: 'Country code'
                        },
                        mobile: {
                            label: 'Mobile number',
                            placeholder: 'Mobile number'
                        },
                        url: {
                            label: 'Username',
                            placeholder: 'Username'
                        }
                    }
                },
                reference_code: {
                    title: 'Do you have an invitation code?',
                    form: {
                        label: 'REFERENCE CODE',
                        placeholder: 'REFERENCE CODE'
                    },
                },
                button: 'Continue'
            },
            {
                title: 'ArtWork',
                subtitle: 'Add samples of your artwork',
                max: '(max. 12 images)',
                button: 'Continue',
                noImage: noImage
            },
            {
                title: 'About you',
                words: 'words',
                characters: 'characters',
                form: {
                    motivational_tweet: {
                        label: 'Motivational Tweet',
                        placeholder: 'Motivational Tweet',
                        helperText: 'Write at least 1 character and less than 140 characters please'
                    },
                    biography: {
                        label: 'Biography',
                        placeholder: 'Biography',
                        words: true,
                        helperText: 'Write at least 1 word and less than 250 words please'
                    },
                    statement: {
                        label: 'Statement',
                        placeholder: 'Statement',
                        words: true,
                        helperText: 'Write at least 1 word and less than 200 words please'
                    }
                },
                button: 'Continue'
            },
            {
                title: 'Documentation',
                words: 'words',
                upload_icon: uploadIcon,
                terms: {
                    label: ['I accept VK Galerie', 'terms of service', ' and ', 'privacy policy.'],
                    termsLink: 'https://kuvik.art/',
                    privacityLink: 'https://kuvik.art/',
                },
                files: {
                    cv: { label: 'CV' },
                    portfolio: { label: 'Portfolio' },
                    other: { label: 'Other' }
                },
                subform: {
                    optional_documents: {
                        label: 'Optional documents',
                        helperText: 'You can include collective exhibitions, individuals, residences and awards received.',
                    },
                    sponsorship: {
                        label: 'Sponsorship',
                        helperText: [
                            'KUVIK offers limited number of economic grants ranging from 20% to fully sponsored scholarships to collaborate with talented artists regardless of their socio-economic status.',
                            'If you have the credentials to be part of kuvik and need a grant to fund your participation, please click on the “Sponsorship” tab.'
                        ]
                    },
                    choosing_reason: {
                        label: 'Why choosing you?',
                    }
                },
                form: {
                    choosing_reason: {
                        placeholder: 'Please state why you believe you are elegble to receive an scolarship to be part of KUVIK',
                    }
                },
                terms_privacity: 'I accept Kuvik Gallery terms of service and privacy policy.',
                button: 'Continue'
            },
            {
                social_media: 'Social Media',
                form: {
                    professional_name: {
                        label: 'Professional name',
                        placeholder: 'Professional name'
                    },
                    discipline_id: {
                        //label: 'Discipline',
                        placeholder: 'Discipline'
                    },
                    facebook: {
                        label: 'Facebook',
                        placeholder: 'Facebook',
                        icon: FB,
                    },
                    twitter: {
                        label: 'Twitter',
                        placeholder: 'Twitter',
                        icon: TW,
                    },
                    instagram: {
                        label: 'Instagram',
                        placeholder: 'Instagram',
                        icon: IG,
                    },
                    tiktok: {
                        label: 'Tiktok',
                        placeholder: 'Tiktok',
                        icon: TT,
                    },
                    pinterest: {
                        label: 'Pinterest',
                        placeholder: 'Pinterest',
                        icon: PT,
                    },
                    youtube: {
                        label: 'Youtube',
                        placeholder: 'Youtube',
                        icon: YT,
                    },
                    vimeo: {
                        label: 'Vimeo',
                        placeholder: 'Vimeo',
                        icon: VM,
                    },
                    opensea: {
                        label: 'Opensea',
                        placeholder: 'Opensea',
                        icon: OP,
                    },
                    web: {
                        label: 'Web',
                        placeholder: 'Web',
                        icon: WE,
                    },
                },
                button: 'Submit'
            },
        ],
        finish: {
            title: 'Thanks for applying!',
            image: emailSent,
            description: [
                'We’ll send an email to',
                'in response to your request.',
                'You can check the status of your application in',
                'kuvik pro'
            ],
            //email: 'psteven@gmail.com',
            button: 'Finish'
        },
    },
}

export default general_translations