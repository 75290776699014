import motivation1 from '../../../assets/about/gallery1.png'
import motivation2 from '../../../assets/about/gallery2.png'

import bannerImage from '../../../assets/about/banner.jpeg'
import whyUsImage from '../../../assets/about/whyus.png'

import arIcon from '../../../assets/icons/AR.svg'
import virtualIcon from '../../../assets/icons/virtual.svg'
import analyticsIcon from '../../../assets/icons/analytics.svg'
import nftIcon from '../../../assets/icons/NFT.svg'

import kuvikBanner from '../../../assets/about/kuvik_banner.gif'

import gameImage from '../../../assets/about/game_changers.png'

import museum from '../../../assets/about/museums.png'
import galleries from '../../../assets/about/galleries.png'
import artAmbassadors from '../../../assets/about/art_ambassadors.png'

export const translations = {
    main_info_section: {
        welcome: 'Welcome to',
        kuvik: 'kuvik galerie',
        description: 'We commit to something bigger than ourselves',
        banner: bannerImage
    },
    whyus_section: {
        title: 'Why us',
        image: whyUsImage,
        art: 'art',
        titleS: '& TECH',
        description: [
            [
                'In a world surrounded by digital technologies and the continuous development of cutting-edge solutions,',
                'cannot be left behind.'
            ],
            [
                'KUVIK is a digital platform intended to optimize the risks, costs, and efforts associated with the promotion, knowledge-share and sell of Art.',
                'With the support of novel digital technologies we simplify efforts and increase exposure for artists to take their career to the next level.'
            ]
        ],
        tech: [
            {
                label: 'Augmented Reality (AR)',
                icon: arIcon,
                color: 'white'
            },
            {
                label: '360° Virutal Spaces',
                icon: virtualIcon,
                color: 'white'
            },
            {
                label: 'Analytics',
                icon: analyticsIcon
            },
            {
                label: 'Non-fungible Token (NFT)',
                icon: nftIcon,
                color: 'white'
            }
        ]
    },
    our_motivation_section: {
        title: 'Our motivation',
        titleS: ["Kuvik's", 'Motto'],
        description: [
            '"Art, as part of human DNA, must evolve rapidly and sustainably by harnessing the wonders of technology to create vibrant, immersive and inspiring art expressions."'
        ],
        gallery: [motivation1, motivation2]
    },
    kuvik_banner_section: {
        title: "WE ARE KUVIK",
        description: "AN INSPIRING ART - TECH PLATFORM",
        banner: kuvikBanner,
    },
    game_section: {
        title: 'Game changers',
        image: gameImage,
        art: 'Art',
        titleS: 'for everyone',
        description: [
            "Democratising art to make it visible and accessible is one of our main motivations, as we understand the great value it has for inclusion and social transformation.",
            "Through art digitisation we seek to position kuvik art members within the current art market and promote their profiles and artworks in a broader scale . At the same time, we seek to spread art through different digital formats (virtual exhibitions, digital magazines, masterclasses) to sensitize and educate a large number of viewers around the globe."
        ],
        message: "IF PEOPLE DO NOT GO TO ART, LET'S ART GO TO PEOPLE"
    },
    our_alliances_section: {
        title: 'Our alliances',
        art: 'Art',
        titleS: [
            'Global',
            'Network'
        ],
        options: [
            {
                label: 'Museums',
                image: museum
            },
            {
                label: 'Galleries',
                image: galleries
            },
            {
                label: 'Art Ambassadors',
                image: artAmbassadors
            }
        ]
    }
}