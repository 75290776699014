import React from "react"
import { alpha, Grid, makeStyles, Typography } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 150,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        },
        paddingBottom: 0,
    },
    title: {
        //fontSize: 48,
        fontWeight: 600,
        textTransform: 'uppercase',
        color: alpha('#BD8B40',0.5),
        letterSpacing: '0.065em',
        [theme.breakpoints.only('xs')]: {
            fontSize: 40
        }
    },
    subtitle: {
        color: grey[900],
        fontSize: 16,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    }
}))

const Header = ({ history, content }) => {

    const classes = styles()

    return (
            <MainContainer>
                <Grid container spacing={6} justifyContent='center'>
                    <Grid item xs={12}>
                        <Typography variant='h4' className={classes.title} align='right'>
                            {content.title}
                        </Typography>
                        <Typography className={classes.subtitle} align='right'>
                            {content.caption}
                        </Typography>
                    </Grid>
                </Grid>
            </MainContainer>
    )
}

export default Header