import mainBanner from '../../../assets/banners/death_banner.jpg'
import bannerMobile from '../../../assets/banners/catrina.png'
import bannerMobile2 from '../../../assets/banners/catrina 2.png'
import culturaLogo from '../../../assets/exhibitions/cultura_logo.png'
import inbaLogo from '../../../assets/exhibitions/inba_logo.png'

import virtualIcon from '../../../assets/icons/virtual-reality.svg'
import virtualBanner from '../../../assets/exhibitions/virtual_bg.png'

import magazine1 from '../../../assets/exhibitions/magazine1.png'
import magazine2 from '../../../assets/exhibitions/magazine2.png'
import magazine3 from '../../../assets/exhibitions/magazine3.png'

import noImage from '../../../assets/artist/noImage.png'

export const translations = {
    main_banner_section: {
        top_title: 'KUVIK GALERIE PRESENTS',
        title: 'DEATH AND TRANSCENDENCE',
        date: {
            startDate: '11-02-2021 00:00:00',
            endDate: '12-31-2021 00:00:00'
        },
        description: 'In Mexican culture, there is an emblematic date dedicated to the commemoration of Death. The memory of the deceased loved ones is honored through offerings and altars filled with food, photos, and bright colors. The celebration and its pre-Hispanic origin have taught people to recognize death as a natural process of the human experience. Welcome to the Virtual exhibition "Death and Transcendence" a tribute to the Mexican culture.',
        image: mainBanner,
        bannerMobile: bannerMobile,
        bannerMobile2: bannerMobile2,
        logos: {
            cultura: culturaLogo,
            inba: inbaLogo
        }
    },
    artwork_section: {
        title: 'Artworks',
        noImage: noImage,
    },
    virtual_section: {
        title: 'VIRTUAL EXHIBITION',
        banner: virtualBanner,
        icon: virtualIcon,
        button: {
            label: 'Enter',
            url: 'https://art.kunstmatrix.com/apps/artspaces/?external=true&splashscreen=true&language=en&uid=46461&exhibition=6042137'
        }
    },
    virtual_maganize_section: {
        title: 'VIRTUAL MAGAZINE',
        banner: virtualBanner,
        icon: virtualIcon,
        button: {
            label: 'Enter',
            url: 'https://art.kunstmatrix.com/apps/artspaces/?external=true&splashscreen=true&language=en&uid=46461&exhibition=6042137'
        }
    },
    magazines_section: {
        title: 'Magazines',
        gallery: [
            {
                title: 'HI-FRUCTOSE',
                subtitle: 'LATINOS AROUND THE WORLD',
                image: magazine1,
                url: ''
            },
            {
                title: 'HI-FRUCTOSE',
                subtitle: 'LATINOS AROUND THE WORLD',
                image: magazine2,
                url: 'https://cdn.flipsnack.com/widget/v2/widget.html?hash=zt5gji5tk5'
            },
            {
                title: 'HI-FRUCTOSE',
                subtitle: 'LATINOS AROUND THE WORLD',
                image: magazine3,
                url: ''
            }
        ]
    },
    artists_section: {
        title: 'Artists',
        noArtist:'Sorry! There are not artists yet.',
    },
}