import { makeStyles, Grid, Typography, Slide } from '@material-ui/core'
import React from 'react'
import RoundedButton from '../../../../../../components/Actions/RoundedButton'
import InputForm from '../../../../../../components/Forms/InputFormW'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckboxForm from '../../../../../../components/Forms/CheckboxForm';
import { grey, red } from '@material-ui/core/colors';

const styles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        /* padding: 150,
         [theme.breakpoints.only('xs')]: {
             paddingLeft: 32,
             paddingRight: 32,
         }*/
    },
    title: {
        //fontSize: 48,
        fontWeight: 600,
        '@global span': {
            color: theme.palette.secondary.main,
            fontWeight: 700,
        }
    },
    subtitle: {
        fontSize: 22,
        fontWeight: 600
    },
    input: {
        backgroundColor: '#F4F4F4',
        //padding: '0px 24px 10px 24px',
        padding: '10px 24px 10px 24px',
        borderRadius: 10
    },
    errorTag: {
        fontSize: '0.75rem',
        color: red[500]
    }
}))

const Step1 = ({ history, checked, content, form, onChange, reference }) => {

    const classes = styles()

    return (
        <Slide direction={'left'} in={checked} mountOnEnter unmountOnExit container={reference}>
            <div className={classes.root}>
                <Grid container justifyContent='space-between' spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.subtitle}>
                            {content.titleS}
                        </Typography>
                    </Grid>
                    {content.checkbox.map((id, key) => (
                        <Grid item key={key} xs={12} sm={6} lg={3}>
                            <CheckboxForm data={form[id]} onChange={onChange} />
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        {form.tags.isVisited && !form.tags.isValid &&
                            <Typography className={classes.errorTag}>
                                {form.tags.config.helperText}</Typography>
                        }
                    </Grid>
                </Grid>
            </div>
        </Slide >
    )
}

export default Step1