import React from "react"
import { Grid, IconButton, makeStyles, useMediaQuery } from '@material-ui/core'
import { useTheme } from "@material-ui/styles"
import { grey } from "@material-ui/core/colors"
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import Pagination from '@material-ui/lab/Pagination'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import ArtworkCard from "../Cards/ArtworkCard"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const styles = makeStyles(theme => ({
    root: {
        //paddingLeft: 100,
        //paddingRight: 100,
        paddingTop: 40,
        paddingBottom: 80,
        [theme.breakpoints.down('sm')]: {
            //padding: 32
        },
    },
    content: {
        padding: '60px 0px'
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    button: {
        background: theme.palette.secondary.main,
        color: 'white',
        '&:hover': {
            background: theme.palette.secondary.dark,
        }
    }
}))

const SwipableArtworks = ({ artwork, content, page, onChangePage, onBuy, onOpenArtWork, onOpenNFT }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('xs'))

    return (

        <Grid container justifyContent='center' alignItems='center' spacing={8}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems='center' spacing={1}>
                    {!mobile &&
                        <Grid item>
                            <IconButton disabled={page === 1} onClick={() => onChangePage(null, page - 1)} className={classes.button}>
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Grid>
                    }
                    <Grid item xs>
                        <AutoPlaySwipeableViews
                            slideStyle={{ overflow: 'hidden' }}
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={page - 1}
                            onChangeIndex={(value) => onChangePage(null, value + 1)}
                            enableMouseEvents
                            interval={16000}
                        >
                            {artwork.map((batch, key) => (
                                <div key={key} style={{ padding: 5 }}>
                                    <Grid container spacing={8}>
                                        {batch.map(artwork => (
                                            <Grid item xs={12} sm={6} md={4}>
                                                <ArtworkCard {...artwork} content={content} noImage={content.noImage}
                                                    onBuy={() => onBuy(artwork)} onOpenArtWork={() => onOpenArtWork(artwork)} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            ))}
                        </AutoPlaySwipeableViews>
                    </Grid>
                    {mobile &&
                        <Grid item>
                            <IconButton disabled={page === 1} onClick={() => onChangePage(null, page - 1)} className={classes.button}>
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Grid>
                    }
                    <Grid item>
                        <IconButton disabled={page === artwork.length} onClick={() => onChangePage(null, page + 1)} className={classes.button}>
                            <NavigateNextIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Pagination size="large" color='secondary' page={page} count={artwork.length} onChange={onChangePage} />
            </Grid>
        </Grid>
    )
}

export default SwipableArtworks

