import React, { useState, useEffect, useRef } from 'react';
import { translations as translations_esp } from '../../../texts/eng/website/community-translations'
import { connect } from 'react-redux'
import { actionTypes } from "../../../store/actions"
import { makeStyles } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'
import { onGetErrorMessage } from '../../../shared/utility'
import MainBanner from './sections/MainBanner';
import ShowYourTalent from './sections/ShowYourTalent'
import MemberBenefits from './sections/MemberBenefits'
import MemberPlans from './sections/MemberPlans'
import Services from './sections/Services'
import Application from './sections/Application/Application'
import RegisterBanner from './sections/RegisterBanner'

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const CommunityView = ({ user, history, goToMagazine, onUpdateDrawerStatus, onUpdateMagazineRef }) => {

    const classes = styles()

    const [loading, setLoading] = useState(false)
    const [homeData, setHomeData] = useState(null)

    const content = translations_esp

    const servicesRef = useRef(null)
    
    useEffect(() => {
        const initModule = async () => {
            window.scrollTo(0, 0)
            if (goToMagazine) {
                servicesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                await onUpdateMagazineRef(false)
            }

            try {
                setLoading(true)
               // const _homeData = await request_home_info()
                //setHomeData({ ...homeData })
            } catch (error) {
                console.log(error)
                onGetErrorMessage(error)
            }
            setLoading(false)
        }

        initModule()
    }, [])

    const openSignUpDrawer = async () => {
        await onUpdateDrawerStatus(true)
    }

    return loading ? <div className={classes.loadingContainer}><div className={classes.loading}><CircularProgress style={{ color: 'white' }} /></div></div> :
        <React.Fragment>
            <div className={classes.content}>
                <MainBanner history={history} content={content.main_info_section} />
                <ShowYourTalent history={history} content={content.show_your_talent_section} openSignUpDrawer={openSignUpDrawer} />
                <MemberBenefits history={history} content={content.member_benefits_section} />
                {/*<MemberPlans history={history} content={content.member_costs_section} />*/}
                <div ref={servicesRef}>
                {/*<Services history={history} content={content.services_section} openSignUpDrawer={openSignUpDrawer} />*/}
                </div>
                <Application history={history} content={content.application_section} />
                <RegisterBanner openSignUpDrawer={openSignUpDrawer} history={history} content={content.register_banner_section} />
            </div>
        </React.Fragment>
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        goToMagazine: state.magazine_reference,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (signup_drawer) => dispatch({ type: actionTypes.DRAWER_UPDATE_SIGNUP, signup_drawer }),
        onUpdateMagazineRef: (magazine_reference) => dispatch({ type: actionTypes.REFERENCE_MAGAZINE, magazine_reference }),
        onUpdateContactModal: (contact_drawer) => dispatch({ type: actionTypes.DRAWER_CONTACT, contact_drawer }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityView);