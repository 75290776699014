import React from "react"
import { makeStyles } from "@material-ui/styles"
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer'
import { AppBar, Card, CardContent, Dialog, Grid, IconButton, Slide, Step, Stepper, Toolbar, Typography } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { actionTypes } from "../../store/actions";
import { connect } from "react-redux";
import general_translations from "../../texts/eng/website/general-translations";
import Form from "./Steps/Form"
import Completed from "./Steps/Completed"
import useSignUpDrawer from "./useSignUpDrawer";

const styles = makeStyles(theme => ({
    root: {
        color: 'white',
    },
    paper: {
        background: 'rgba(0,0,0,0.8)'
    },
    content: {
        color: 'white',
        width: '60%',
        height: 'auto',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 64,
            boxSizing: 'border-box'
        },
        [theme.breakpoints.only('xs')]: {
            padding: 32,
        }
    },
    appBar: {
        position: 'relative',
        background: 'transparent',
        padding: 32,
        boxSizing: 'border-box',
        boxShadow: 'none',
        borderBottom: '1px solid #5A5A5A'
    },
    stepperContainer: {
        width: 300,
        paddingLeft: 64,
        paddingTop: 24,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            boxSizing: 'border-box',
            padding: 32
        }
    },
    stepper: {
        background: 'transparent',
        padding: 0,
        paddingTop: 12,
        '& .MuiStepIcon-root': {
            width: 32,
            height: 32,
            fontSize: 26,
            fontWeight: 500,
            color: `${theme.palette.secondary.main}55`,
            '& .MuiStepIcon-text': {
                fill: 'darkgrey'
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            border: `solid 2px ${theme.palette.secondary.main}`,
            borderRadius: '100%',
            '& .MuiStepIcon-text': {
                fill: 'white'
            }
        }
    },
    stepperLabel: {
        color: theme.palette.secondary.main,
        textTransform: 'uppercase',
        letterSpacing: '0.37em',
        fontSize: 14,
        paddingLeft: 18
    },
    title: {
        fontSize: 30,
        fontWeight: 600,
        '& span': {
            color: theme.palette.secondary.main
        }
    },
    subtitle: {
        fontSize: 18,
        fontWeight: 600,
        '& span': {
            fontSize: 24,
            fontWeight: 500,
            color: theme.palette.secondary.main
        }
    },
    link: {
        fontSize: 18,
        fontWeight: 700,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    iconPassword: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    areaCard: {
        margin: 'auto',
        width: 200,
        height: 200,
        background: 'transparent',
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.secondary.dark,
            background: '#ffffffbb',
            border: '5px solid'
        }
    },
    areaTitle: {
        fontSize: 24,
        fontWeight: 600,
        marginTop: '40%',
        textTransform: 'uppercase',
    },
    areaLabel: {
        color: theme.palette.secondary.main,
        fontSize: 24,
        fontWeight: 600,
        textTransform: 'uppercase',
        padding: '32px 64px 32px 64px'
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SignUpDrawer = ({ open, language, onUpdateToken,
    onUpdateProfilePicture, onUpdateSessionStatus, onUpdateDrawerStatus,
    onUpdateAuthStatus, onUpdateUserData, onUpdateModalStatus }) => {

    const classes = styles()
    const content = general_translations.signup_drawer

    const { loading, sending, error, document_modal, form, files, selected_document,
        step, gallery, area, actions } = useSignUpDrawer({
            open, language, onUpdateToken, onUpdateSessionStatus, content, onUpdateModalStatus,
            onUpdateDrawerStatus, onUpdateAuthStatus, onUpdateUserData, onUpdateProfilePicture
        })

    const areaInfo = content.categories.find(e => e.id === area)

    const areasView = (
        <Grid container justifyContent='center' alignItems='stretch' spacing={5}>
            <Grid item xs={12}>
                <Typography variant='body2' align='center' className={classes.subtitle}>
                    <span>{content.i_am}</span> {area && areaInfo.description}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent='center' spacing={4}>
                    {content.categories.map(category => (
                        <Grid item xs={12} sm={6} lg={3} key={`category-${category.id}`}>
                            <Card
                                variant="outlined"
                                className={classes.areaCard}
                                onMouseEnter={() => actions.onUpdateArea(category.id)}
                                onClick={() => actions.onSelectArea(category.id)}
                            >
                                <CardContent>
                                    <Typography variant='body2' align='center' className={classes.areaTitle}>
                                        {category.label}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )

    let contentView = null

    switch (step) {
        case 0:
            contentView = areasView
            break;
        case 1:
            contentView = <Form area={area} actions={actions} form={form} content={content.form} />
            break;
        case 2:
            contentView = <Completed actions={actions} form={form} files={files} content={content.finish} />
            break;
    }

    return (
        <LoadingContainer loading={loading} sending={sending} error={error} onCloseError={actions.onCloseError}>
            <div className={classes.root}>
                <Dialog fullScreen classes={{ paperFullScreen: classes.paper }} open={open} onClose={actions.onClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    <img src={content.logo} style={{ marginLeft: 10, width: 45 }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant='body2' align='center' className={classes.title}>
                                        {content.title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton edge="end" color="inherit" onClick={actions.onClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    {step > 0 &&
                        <Typography className={classes.areaLabel}>
                            {areaInfo && areaInfo.label}
                        </Typography>
                    }
                    <div className={classes.content}>
                        {contentView}
                    </div>
                </Dialog>
            </div>
        </LoadingContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        open: state.signup_drawer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (signup_drawer) => dispatch({ type: actionTypes.DRAWER_UPDATE_SIGNUP, signup_drawer }),
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
        onUpdateToken: (token) => dispatch({ type: actionTypes.SYS_UPDATE_TOKEN, token }),
        onUpdateSessionStatus: (session_status) => dispatch({ type: actionTypes.SYS_UPDATE_SESSION_STATUS, session_status }),
        onUpdateModalStatus: (profile_picture_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_PROFILE_PICTURE, profile_picture_modal }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpDrawer)