import React from "react"
import { Card, CardActionArea, CardContent, Divider, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import RoundedButton from "../../../../components/Actions/RoundedButton";
import PageContainer from "../../../../components/Structure/Layouts/PageContainer";
import MainContainer from "../../../../components/Structure/Layouts/MainContainer";

const styles = makeStyles(theme => ({
    root: {
        margin:'80px 0px',
        width: '100%',
        height: '100%',
        //paddingLeft: 100,
        //paddingRight: 100,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        },
        boxSizing:'border-box'
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em',
    },
    divider: {
        backgroundColor: grey[600]
    },
    cardsContainer: {
        [theme.breakpoints.up('sm')]: {
            padding: 64
        }
    },
}))

const Services = ({ history, content, openSignUpDrawer }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container justifyContent='center' spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title}>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.cardsContainer}>
                            <Grid container justifyContent='space-around' spacing={4}>
                                {content.services.map(service => (
                                    <Grid item xs={12} sm>
                                        <ServiceCard {...service} />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item>
                        <RoundedButton onClick={openSignUpDrawer}>
                            {content.button}
                        </RoundedButton>
                    </Grid>
                </Grid>
            </MainContainer>
        </div >
    )
}

export default Services


const stylesCard = makeStyles(theme => ({
    root: {
        margin: 'auto',
        width: '100%',
        height: 450,
        maxWidth: 450,
        position: 'relative',
        [theme.breakpoints.between(0, 413)]: {
            height: 500,
        }
    },
    card: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        background: 'rgba(5, 27, 54, 0.02)',
    },
    divider: {
        width: 50,
        margin: 'auto',
        backgroundColor: 'white'
    },
    priceContainer: {
        background: 'rgba(89, 89, 89, 0.08)',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        width: '100%',
        transform: 'translate(-50%,-100%)'
    },
    price: {
        fontSize: 24,
        fontWeight: 600,
        padding: 0
    },
    priceFrom: {
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'capitalize'
    },
    priceCurrency: {
        fontSize: 16,
        fontWeight: 500,
        textTransform: 'uppercase'
    },
    title: {
        padding: 12,
        fontSize: 24,
        fontWeight: 700,
        color: theme.palette.secondary.main,
    },
    description: {
        padding: 12,
        fontSize: 16,
        fontWeight: 400,
        color: '#535353'
    },
    listItem: {
        fontSize: 16,
        fontWeight: 600,
    },
    listItemIcon: {
        width: 13,
        height: 13,
        marginRight: 12,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
    },
    listItemIconD: {
        width: 13,
        height: 13,
        marginRight: 12,
        color: '#484848',
        backgroundColor: '#484848',
    },
}))

const ServiceCard = ({ title, description, price }) => {

    const classes = stylesCard()

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardActionArea>
                    <CardContent style={{ padding: 32 }}>
                        <Grid container justifyContent='center' spacing={4}>
                            <Grid item xs={12}>
                                <Typography className={classes.title} align='center'>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.description} align='center'>
                                    {description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
                <div className={classes.priceContainer}>
                    <Grid container justifyContent='center' alignItems='center' spacing={1}>
                        <Grid item>
                            <Typography gutterBottom className={classes.priceFrom} align='center'>
                                {price.from}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom className={classes.price} align='center'>
                                {price.cost}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom className={classes.priceCurrency} align='center'>
                                {price.currency}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div>
    )
}
