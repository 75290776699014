
import heart from '../../../assets/icons/heart.svg'
import fullHeart from '../../../assets/icons/full_heart.svg'

import bannerImage from '../../../assets/exhibitions/banner.png'
import noImage from '../../../assets/artist/noImage.png'
import arrowIcon from '../../../assets/icons/arrow_down.svg'

import featuredIcon from '../../../assets/icons/featured.svg'

export const translations = {
    main_info_section: {
        title: 'Exhibitions',
        caption: 'Virtual and inspiring art spaces'
    },
    current_section: {
        title: 'Current',
        noImage: noImage,
        featuredIcon: featuredIcon,
        noContent: 'Sorry! There are not exhibitions to show here yet.',
        moreIcon: arrowIcon,
        see: {
            more: 'See more',
            less: 'See less'
        },
        buttons: {
            download: 'Brochure',
            visit: 'Visit',
            save: {
                full: fullHeart,
                empty: heart
            }
        }
    },
    individuals_section: {
        title: 'Individuals',
        featuredIcon: featuredIcon,
        icons: {
            full: fullHeart,
            empty: heart
        },
        noImage: noImage,
        noContent: 'Sorry! There are not exhibitions to show here yet.',
    },
    interest_banner_section: {
        title: 'Interested in sharing your art profile?',
        description: 'Join our Art community and become a Kuvik member to show your art in a global scale.',
        banner: bannerImage,
        buttons: {
            getin_touch: {
                label: 'Get in touch',
                url: '/'
            },
            know_more: {
                label: 'Know more',
                url: '/community'
            }
        }
    },
    coming_section: {
        title: 'Coming',
        noContent: 'Sorry! There are not exhibitions to show here yet.',
        featuredIcon: featuredIcon,
        moreIcon: arrowIcon,
        see: {
            more: 'See more',
            less: 'See less'
        },
        buttons: {
            download: 'Brochure',
            apply: 'Apply'
        },
        timer: {
            days: 'days',
            hours: 'hours',
            minutes: 'minutes'
        }
    },
    archived_section: {
        title: 'Archived',
        noImage: noImage,
        noContent: 'Sorry! There are not exhibitions to show here yet.',
        featured: {
            label: 'Featured',
            icon: featuredIcon
        }
    }
}