import React, { useEffect, useState } from "react"
import { Button, Divider, Grid, Icon, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import { useTheme } from "@material-ui/styles"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import { grey } from "@material-ui/core/colors"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import ReactHtmlParser from 'react-html-parser';

const styles = makeStyles(theme => ({
    root: {
        //minHeight: '80vh',
        background:'#212121',
        color:'white',
        paddingBottom:80,
        [theme.breakpoints.only('xs')]: {
            minHeight: 'auto',
            height: '100%'
        }
    },
    content: {
        padding:'80px 0px',
        paddingBottom:40,
        //padding: 100,
        //position: 'relative',
        //[theme.breakpoints.only('xs')]: {
        //    paddingLeft: 32,
        //    paddingRight: 32,
        //}
    },
    center: {
        minWidth: 220,
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%,-50%)'
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em',
        //color:'white'
    },
    divider: {
        backgroundColor: grey[600]
    },
    image: {
        width: '100%',
        height: 'auto',
        filter: 'brightness(50%)'
    },
    imageContainer: {
        position: 'relative'
    },

}))

const VirtualMagazine = ({ history, content, exhibition }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <div className={classes.root}>
            <MainContainer>
                <div className={classes.content}>
                    <Grid container justifyContent='center' spacing={8}>
                        <Grid item xs={12}>
                            <Grid container alignItems='center' spacing={3}>
                                <Grid item xs>
                                    <Divider className={classes.divider} />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.title} align='right'>
                                        {content.title}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Divider className={classes.divider} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </MainContainer>
            
            <div className={classes.imageContainer} style={{marginTop:30}}>
                {ReactHtmlParser(exhibition ? exhibition.magazine_iframe : '-')}
            </div>
        </div>
    )
}

export default VirtualMagazine