import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import RoundedButton from '../Actions/RoundedButton'
import cx from 'classnames'
import { ruleValidation } from './customFunctions'

const styles = makeStyles(theme => ({
    smallTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#607D8B'
    },
}))

const ButtonsSelect = ({ data, onChange, color, unactive_color, disabled }) => {

    const classes = styles()

    const { value, error, isVisited, isRequired, isValid, options, config, rules } = data
    const { id, label, helper, fullWidth } = config

    const active_color = color ? color : 'primary'
    const _color = unactive_color ? unactive_color : 'transparent'
    const onInnerChange = value => {
        let temp = { ...data }
        temp.value = value
        temp.isValid = ruleValidation(temp.value, temp.rules);
        temp.isVisited = true;
        console.log(temp)
        onChange(temp)
    }

    return (
        <Grid container justify='center' spacing={4}>
            {label &&
                <Grid item xs={12}>
                    <Typography className={classes.smallTitle} >{label}</Typography>
                </Grid>
            }
            {options.map(item => (
                <Grid item>
                    <RoundedButton disabled={disabled} color={item.value === value ? active_color : _color} onClick={() => onInnerChange(item.value)}>
                        {item.label}
                    </RoundedButton>
                </Grid>
            ))}
        </Grid>
    )
}

export default ButtonsSelect